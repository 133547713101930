import { withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import classNames from 'classnames';
import styles from './KioskeSeek.styles';
import Button from '@material-ui/core/Button';
import { injectIntl, defineMessages } from 'react-intl';
import { KioskFooter, KioskHeader, KioskIdelScreen } from '..';
import eSeekImg from '../../assets/images/eSeekImg.png';
import ArrowImg from '../../assets/images/arrowImg.webp';
import { readKioskSettings } from '../Common/skipSteps';
import { connect } from 'react-redux';

const messages = defineMessages({
  CancelPickup: {
    id: 'Welcome.CancelPickup',
    defaultMessage: 'Cancel pickup'
  },
  Skip: {
    id: 'ConfirmRX.AllowsYouToPayLater.Skip',
    defineMessages: 'Skip'
  },
  IdRequired: {
    id: 'ESeek.Title',
    defineMessages: 'ID Required'
  },
  ValidId: {
    id: 'ESeek.ValidId',
    defineMessages:
      'Your order requires that we capture a valid ID before we can continue.'
  },
  ScanMsg: {
    id: 'ESeek.ScanMsg',
    defineMessages:
      "Please insert your driver's license or valid state ID to continue.The device will return your ID after scanning."
  },
  PassportBtn: {
    id: 'ESeek.PassportBtn',
    defineMessages: 'I have a passport'
  },
  LicenseMsg: {
    id: 'ESeek.LicenseMsg',
    defineMessages:
      "If you do not have a driver's license or state ID. Choose skip and the pharmacy staff will confirm your identity"
  }
});

export class KioskeSeek extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedLanguage: ''
    };
  }

  componentDidMount() {
    let selectedLanguage = localStorage.getItem('language');
    this.setState({ selectedLanguage });
    // Call the ID scan API
    this.idScan();
  }

  handleCancelPickup = () => {
    this.props.history.replace('/');
  };

  handleSkip = () => {};

  idScan = async () => {
    let idScanResponseObj = null;
    let idScanData = null;
    try {
      idScanResponseObj = await fetch(
        `${process.env.REACT_APP_IDSCANBASEURL}/v1/capture`,
        {
          method: 'GET',
          headers: {
            Authorization: 'Bearer ' + process.env.REACT_APP_IDSCANTOKEN, // TODO: Need to reuse the token or get it with an API call
            'Content-Type': 'application/json'
          }
        }
      );
    } catch (error) {
      console.log('Error in idScan');
      console.log(error);
    }
    if (idScanResponseObj.status !== 200) {
      console.log('Error in idScan');
      console.log(idScanResponseObj);
      return;
    }
    idScanData = await idScanResponseObj.json();
    try {
      await this.handleScan(idScanData);
      await this.saveIdData(idScanData);
      this.handleNavigation();
    } catch (error) {
      console.log('Error in handleScan');
      console.log(error);
    }
  };

  handleScan = async (idScanData) => {
    const currentCode = localStorage.getItem('currentcode').toUpperCase();
    console.log('idScanData', idScanData);
    const detailObj = [
      {
        image_url: idScanData.CardData.m_RtBmp,
        // image_url: 'iVBORw0KGgoAAAANSUhEUgAAAAgAAAAIAQMAAAD+wSzIAAAABlBMVEX///+/v7+jQ3Y5AAAADklEQVQI12P4AIX8EAgALgAD/aNpbtEAAAAASUVORK5CYII',
        pickup_code: currentCode,
        type: 2,
        selected_reason: '630ea51e-ca12-437e-a749-e7bc7897e96f'
      },
      {
        image_url: idScanData.CardData.m_LtBmp,
        // image_url: 'iVBORw0KGgoAAAANSUhEUgAAAAgAAAAIAQMAAAD+wSzIAAAABlBMVEX///+/v7+jQ3Y5AAAADklEQVQI12P4AIX8EAgALgAD/aNpbtEAAAAASUVORK5CYII',
        pickup_code: currentCode,
        type: 2,
        selected_reason: '630ea51e-ca12-437e-a749-e7bc7897e96f'
      }
    ];
    await fetch(
      `${process.env.REACT_APP_BASEURL}consultation/id-capture/accept-reject`,
      {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + this.actualToken,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(detailObj)
      }
    )
      .then((response) => response.json())
      .then((res) => {
        console.log(res, 'res');
      });
  };

  saveIdData = async (idScanData) => {
    let _localPatientObj = this.props.localPatientObj;
    const parsedPdf417Data =
      idScanData && idScanData.CardData && idScanData.CardData.parsedPdf417Data;
    const currentOrder = JSON.parse(sessionStorage.getItem('multipleOrders'));
    let order_id = currentOrder && currentOrder[0].id;

    let orderIds = [];
    orderIds.push({
      id: order_id
    });

    let IdObject = {
      customer_id: currentOrder && currentOrder[0].customer_id,
      order_id: order_id,
      picture_url: null,
      signature_url: null,
      drivers_license_no: parsedPdf417Data.LicenseNumber,
      first_name: parsedPdf417Data.FirstName,
      middle_name: null,
      last_name: parsedPdf417Data.LastName,
      dob: parsedPdf417Data.DateOfBirth,
      relationship: [undefined, 'undefined', null, 'null', ''].includes(
        parsedPdf417Data
      )
        ? ''
        : 'Patient',
      box_id: this.props && this.props.box && this.props.box.id,
      account_id:
        this.props && this.props.box && this.props.box.parent_account_id,
      orderIds: JSON.stringify(orderIds),
      selectedStateCode: parsedPdf417Data.State,
      dlrawdata:
        idScanData && idScanData.CardData && idScanData.CardData.pdf417,
      kioskBoxAccountConnection:
        this.props &&
        this.props.box &&
        this.props.box.account &&
        this.props.box.account.account_connections_id,
      is_consult_requested: _localPatientObj
        ? _localPatientObj.is_consult_requested
        : false,
      call_third_party_API: false,
      exp_date: parsedPdf417Data.ExpirationDate,
      card_type: [undefined, 'undefined', null, 'null', ''].includes(
        parsedPdf417Data
      )
        ? null
        : '34eb3711-40ae-42c5-99f4-16970ba9467b'
    };
    const token = localStorage.getItem('authTokens');
    const actualToken = atob(token);
    fetch(process.env.REACT_APP_BASEURL + 'saveKioskCustomerCollectedDetail', {
      method: 'post',
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + actualToken
      },
      body: JSON.stringify(IdObject)
    })
      .then((response) => {
        try {
          return response.text();
        } catch (error) {
          return null;
        }
      })
      .then(() => {});
  };

  handleNavigation = async () => {
    let fileObj = readKioskSettings(2);
    const filterNextScreenURLs =
      fileObj && fileObj.steps && fileObj.steps.filter((x) => x.step === 4);
    if (
      filterNextScreenURLs &&
      filterNextScreenURLs.length &&
      filterNextScreenURLs.length > 0
    ) {
      if (
        filterNextScreenURLs[0].name === 'Consult' &&
        filterNextScreenURLs[0].value === true
      ) {
        this.props.history.push('/KioskConsult');
      } else {
        this.props.history.push('/KioskCollectMedicines');
      }
    }
  };

  render() {
    const { classes, intl } = this.props;
    return (
      <>
        <KioskHeader />
        <div style={{ width: '100%', marginTop: '5rem', padding: '0 2rem' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column'
            }}
          >
            <p
              style={{ fontSize: '30px', color: 'inherit', fontWeight: 'bold' }}
            >
              {intl.formatMessage(messages.IdRequired)}
            </p>
            <p
              style={{
                fontSize: '22px',
                color: 'inherit',
                textAlign: 'center'
              }}
            >
              {intl.formatMessage(messages.ValidId)}
            </p>
            <div>
              <img style={{ width: '420px' }} src={eSeekImg} alt="Scan Me" />
            </div>
            <div>
              <img
                src={ArrowImg}
                alt="Scan Me"
                style={{
                  width: '250px',
                  transform: 'rotate(180deg)',
                  mixBlendMode: 'multiply'
                }}
              />
            </div>
            <p
              style={{
                fontSize: '22px',
                color: 'inherit',
                textAlign: 'center'
              }}
            >
              {intl.formatMessage(messages.ScanMsg)}
            </p>
          </div>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '1fr 1fr',
              gap: '7rem',
              height: '90px',
              marginTop: '5rem'
            }}
          >
            <div
              style={{
                display: 'grid',
                gap: '0.5rem'
              }}
            >
              <Button
                type="button"
                color="primary"
                variant="contained"
                className={classNames(classes.fproBackButton)}
                onClick={this.handleCancelPickup}
              >
                {intl.formatMessage(messages.CancelPickup)}
              </Button>
            </div>
            <div style={{ display: 'grid' }}>
              <Button
                type="button"
                color="primary"
                variant="contained"
                className={classNames(classes.primaryButton)}
                onClick={this.handleNavigation}
              >
                {intl.formatMessage(messages.PassportBtn)}
              </Button>
            </div>
          </div>
        </div>
        <KioskFooter isLanguageBarAllowd={true} />
        <KioskIdelScreen />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const auth =
    state.get('auth') && state.get('auth').toJS && state.get('auth').toJS();
  const kiosk = state.get('kiosk');
  return {
    ...auth,
    ...kiosk
  };
};

export default connect(mapStateToProps)(
  withStyles(styles, { withTheme: true })(injectIntl(KioskeSeek))
);
