import { withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './KioskPatientViewRxLayout.styles';
// import { Paper } from '@material-ui/core';
import {
  KioskWelcomeFooter,
  KioskWelcomeHeader,
  KioskPatientViewRxMain,
  KioskIdelScreen
} from '..';
export class KioskPatientViewRxLayout extends Component {
  static propTypes = {
    test: PropTypes.string
  };

  render() {
    const { classes } = this.props;
    var customFooterBottom;
    if (window.screen.width === 1440 || window.screen.width === 1920) {
      customFooterBottom = '-25%';
    } else {
      customFooterBottom = '0%';
    }
    return (
      <div className={classNames(classes.container)}>
        <KioskWelcomeHeader />
        <KioskPatientViewRxMain />
        <KioskWelcomeFooter customFooterBottom={customFooterBottom} />
        <KioskIdelScreen />
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  KioskPatientViewRxLayout
);
