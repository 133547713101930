import { withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './AdminRemoveLayout.styles';
import {
  AdminHeader,
  AdminFooter,
  AdminSideSlides,
  AdminRemoveLayoutMain,
  KioskIdelScreen
} from '..';
import { injectIntl, defineMessages } from 'react-intl';
import AdminVideo from '../AdminImgCapture/AdminVideo';
import { connect } from 'react-redux';

const messages = defineMessages({
  ScanStockCode: {
    id: 'Admin.Stock.ScanStockCode',
    defineMessages: 'Scan the stock code to begin.'
  },
  RemoveOrder: {
    id: 'Admin.Stock.RemoveOrder',
    defineMessages: 'Remove Order'
  }
});
export class AdminRemoveLayout extends Component {
  static propTypes = {
    test: PropTypes.string
  };
  state = {
    stepTitle: '',
    pageTitle:
      this.props && this.props.intl.formatMessage(messages.RemoveOrder),
    subTitle:
      this.props && this.props.intl.formatMessage(messages.ScanStockCode),
    height: 300
  };

  render() {
    const { classes } = this.props;
    const isRemoveIdelTimerEnabled = localStorage.getItem('removeIdelTimer');
    return (
      <div className={classNames(classes.container)}>
        <AdminVideo />
        <AdminHeader myLogoheight={this.state.height} />
        <AdminSideSlides
          pageTitle={this.state.pageTitle}
          subTitle={this.state.subTitle}
          customTop={this.state.customTop}
          customHeight={120}
        />
        <AdminRemoveLayoutMain />
        <AdminFooter customFooterBottom="0%" />
        {isRemoveIdelTimerEnabled === 'false' ? <KioskIdelScreen /> : null}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  const kiosk = state.get('kiosk');
  return { ...kiosk };
};

export default connect(mapStateToProps)(
  withStyles(styles, { withTheme: true })(injectIntl(AdminRemoveLayout))
);
