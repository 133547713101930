const styles = (theme) => {
  return {
    container: {
      width: '100vw',
      height: '100vh',
      gridGap: '1rem',
      gridTemplateColumns: 'auto',
      gridTemplateRows: '12% auto 3em',
      backgroundImage: 'linear-gradient(135deg, white, #dedede)',
      justifyContent: 'center',
      alignItems: 'center'
    },
    imgProperties: {
      height: '100vh',
      width: '100vw'
    }
  };
};

export default styles;
