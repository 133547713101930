import { withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './AdminPanelLayout.styles';
import {
  AdminHeader,
  AdminFooter,
  AdminSideSlides,
  AdminPanelMain,
  KioskIdelScreen
} from '..';
import { injectIntl, defineMessages } from 'react-intl';
import AdminImgCapture from '../AdminImgCapture/AdminImgCapture';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import AdminVideo from '../AdminImgCapture/AdminVideo';

const messages = defineMessages({
  WhatToDo: {
    id: 'Admin.Panel.WhatToDo',
    defineMessages: 'What are you here to do?'
  },
  subTitle: {
    id: 'Admin.Panel.SubTitle',
    defineMessages: 'Touch an option to begin.'
  }
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export class AdminPanelLayout extends Component {
  static propTypes = {
    test: PropTypes.string
  };
  state = {
    stepTitle: '',
    pageTitle: this.props && this.props.intl.formatMessage(messages.WhatToDo),
    subTitle: this.props && this.props.intl.formatMessage(messages.subTitle),
    height: 300,
    open: true
  };

  componentDidMount() {
    const timer = setTimeout(() => {
      this.setState({ open: false });
    }, 3000);
    return () => clearTimeout(timer);
  }

  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classNames(classes.container)}>
        <AdminVideo />
        <AdminHeader myLogoheight={this.state.height} />
        <AdminSideSlides
          pageTitle={this.state.pageTitle}
          subTitle={this.state.subTitle}
          customTop={this.state.customTop}
          customHeight={120}
        />
        <AdminPanelMain />
        <AdminFooter customFooterBottom="0%" />
        <KioskIdelScreen />
        <Dialog
          open={
            ['', null, 'null'].includes(localStorage.getItem('AdminImg'))
              ? this.state.open
              : null
          }
          onClose={this.handleClose}
          TransitionComponent={Transition}
        >
          <DialogContent>
            <AdminImgCapture />
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  const kiosk = state.get('kiosk');
  return { ...kiosk };
};

export default connect(mapStateToProps)(
  withStyles(styles, { withTheme: true })(injectIntl(AdminPanelLayout))
);
