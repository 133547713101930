// snippet: rcep
import {
  withStyles,
  MuiThemeProvider,
  createTheme
} from '@material-ui/core/styles';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './AdminSettingFormControls.styles';
import TextField from '@material-ui/core/TextField';
import { injectIntl } from 'react-intl';
import Button from '@material-ui/core/Button';
import 'react-simple-keyboard/build/css/index.css';
import Keyboard from 'react-simple-keyboard';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import Switch from '@material-ui/core/Switch';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
//import keyboardImg from '../AdminLayout/keyboard.png';
import { readSettings } from '../../../helpers/board';

const ExpansionPanel = withStyles({
  root: {
    border: '1px solid rgba(0,0,0,.125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0
    },
    '&:before': {
      display: 'none'
    }
  },
  expanded: {
    margin: 'auto'
  }
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0,0,0,.03)',
    borderBottom: '1px solid rgba(0,0,0,.125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56
    }
  },
  content: {
    '&$expanded': {
      margin: '12px 0'
    }
  },
  expanded: {}
})((props) => <MuiExpansionPanelSummary {...props} />);

ExpansionPanelSummary.muiName = 'ExpansionPanelSummary';

const ExpansionPanelDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiExpansionPanelDetails);

export class AdminPanelFormControls extends Component {
  static propTypes = {
    test: PropTypes.string,
    error: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.state = {
      boxId: '',
      showKeyboard: false,
      isEnabled: false,
      displayMsg: '',
      maxlength: 6,
      layoutName: 'default',
      SelectedInput: 'boxid',
      isPollingEnabled: false,
      IsActive: false,
      expanded: 'panel1',
      selectedAddonUnit: ''
    };
    this.textInput = React.createRef();
    this.keyboardHandler = this.keyboardHandler.bind(this);
  }
  handlePanelChange = (panel) => (expanded) => {
    this.setState({
      expanded: expanded ? panel : false
    });
  };
  componentWillMount() {
    let boxId, sourceEnabled;
    if (window.electron) {
      const fileData = JSON.parse(readSettings());
      sourceEnabled = fileData ? fileData.dynamicDataSource : false;
      boxId = fileData ? fileData.boxId : '';
    } else {
      sourceEnabled = JSON.parse(localStorage.getItem('dynamicDataSource'));
      boxId = localStorage.getItem('boxId');
    }
    this.setState({ isPollingEnabled: sourceEnabled, boxId: boxId });
  }

  handleClose = () => {
    document.querySelector('#' + this.state.SelectedInput).value = '';
    this.setState({ open: false, patientDetails: [], displayMsg: '' });
    setTimeout(() => {
      this.textInput.current.focus();
    }, 650);
  };

  onChange = (input) => {
    if (this.state.SelectedInput) {
      document.querySelector('#' + this.state.SelectedInput).value = input;
      var event = new Event('change', { bubbles: true });
      event.simulated = true;
      document
        .querySelector('#' + this.state.SelectedInput)
        .dispatchEvent(event);
      this.change(event);
    }
  };

  onKeyPress = (button) => {
    if (['{capslock}', '{shiftleft}', '{shiftright}'].includes(button))
      this.handleShiftButton();
  };

  handleShiftButton = () => {
    const {
      state: { layoutName }
    } = this;
    const shiftToggle = layoutName === 'default' ? 'shift' : 'default';
    this.setState({ layoutName: shiftToggle });
  };

  change = (event) => {
    const boxId = event.target.value;
    this.setState({ boxId: boxId });
  };

  Focus = (obj) => {
    this.setState({ showKeyboard: true });
    let ref = obj.target.name;
    this.setState({ SelectedInput: ref });
  };

  editBoxId = () => {
    this.setState({ isEnabled: true });
    this.setState({ showKeyboard: true });
  };

  saveBoxId = () => {
    this.setState({ showKeyboard: false });
    const boxId = this.state && this.state.boxId;
    if (boxId) {
      localStorage.setItem('boxId', boxId.toString().toUpperCase());
    } else {
      localStorage.setItem('boxId', null);
    }
    this.setState({ isEnabled: false });

    let obj = {
      IsActive: this.state.IsActive,
      kiosk_box_id: boxId
    };

    fetch(`${process.env.REACT_APP_BASEURL}boxes/${boxId}/token`, {
      method: 'GET',
      withCredentials: true,
      headers: {
        Authorization:
          'Bearer ' +
          JSON.parse(sessionStorage.getItem('user')).signInUserSession.idToken
            .jwtToken,
        'Content-Type': 'application/json'
      }
    })
      .then((response) => {
        try {
          return response.json();
        } catch (error) {
          return null;
        }
      })
      .then((res) => {
        localStorage.setItem('authTokens', btoa(res.token));
      });

    fetch(process.env.REACT_APP_BASEURL + 'updateBoxIsActive', {
      method: 'post',
      withCredentials: true,
      headers: {
        Authorization:
          'Bearer ' +
          JSON.parse(sessionStorage.getItem('user')).signInUserSession.idToken
            .jwtToken,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(obj)
    }).then((response) => {
      try {
        return response.text();
      } catch (error) {
        return null;
      }
    });
  };

  handleChangeActive = () => {
    this.setState({ IsActive: !this.state.IsActive });
  };

  handleChange = (name) => (event) => {
    const dataStatus = event.target.checked;
    this.setState({ isPollingEnabled: dataStatus });
    this.props.onDataSourceChange(event, dataStatus);
    localStorage.setItem('dynamicDataSource', dataStatus);
  };

  onUnitChange = (event) => {
    let selectedUnit = event.target.value;
    this.setState({ selectedAddonUnit: selectedUnit });
  };
  keyboardHandler() {
    this.setState({
      showKeyboard: !this.state.showKeyboard
    });
  }
  render() {
    const { classes, handleBack } = this.props;
    const { isEnabled, expanded } = this.state;
    const theme = createTheme({
      palette: {
        primary: {
          main: '#005d67'
        }
      },
      typography: { useNextVariants: true }
    });
    return (
      <div className={classNames(classes.container)}>
        <div className={classNames(classes.flexContainer)}>
          <ExpansionPanel
            square
            expanded={expanded === 'panel1'}
            onChange={this.handlePanelChange('panel1')}
          >
            <ExpansionPanelSummary>
              <Typography className={classes.heading}>
                Box Id Configuration
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails
              className={classNames(classes.expansingDetails)}
            >
              <div className={classNames(classes.flexItemText)}>
                <MuiThemeProvider theme={theme}>
                  <TextField
                    style={{ textAlign: 'center' }}
                    id="boxid"
                    name="boxid"
                    className={classNames(classes.textBoxItem)}
                    defaultValue={this.state.boxId}
                    margin="normal"
                    variant="outlined"
                    placeholder="Enter box id"
                    inputProps={{ maxLength: 6 }}
                    autoComplete="off"
                    onChange={(e) => this.change(e)}
                    autoFocus
                    disabled={!isEnabled ? true : false}
                    inputRef={this.textInput}
                    onFocus={this.Focus.bind('boxid')}
                    onKeyPress={(ev) => {
                      if (ev.key === 'Enter') {
                        this.change(ev);
                      }
                    }}
                  />
                </MuiThemeProvider>
              </div>
              <div className={classNames(classes.flexItem)}>
                <IconButton
                  onClick={this.editBoxId}
                  className={classes.signOutButton}
                >
                  <EditIcon className={classes.iconStyle} />
                </IconButton>
              </div>
              <div className={classNames(classes.flexItem)}>
                <IconButton
                  onClick={this.saveBoxId}
                  className={classes.signOutButton}
                >
                  <SaveIcon className={classes.iconStyle} />
                </IconButton>
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <ExpansionPanel
            square
            expanded={expanded === 'panel2'}
            onChange={this.handlePanelChange('panel2')}
          >
            <ExpansionPanelSummary>
              <Typography className={classes.heading}>
                Data Polling Setting
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails
              className={classNames(classes.expansingDetails)}
            >
              <div className={classNames(classes.flexContainer)}>
                <div className={classNames(classes.flexItemToggle)}>
                  OFF
                  <Switch
                    classes={{
                      switchBase: classes.iOSSwitchBase,
                      bar: classes.iOSBar,
                      icon: classes.iOSIcon,
                      iconChecked: classes.iOSIconChecked,
                      checked: classes.iOSChecked
                    }}
                    disableRipple
                    checked={this.state.isPollingEnabled}
                    onChange={this.handleChange('dataSource')}
                    value={this.state.isPollingEnabled}
                    color="primary"
                  />
                  ON
                </div>
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <ExpansionPanel
            square
            expanded={expanded === 'panel3'}
            onChange={this.handlePanelChange('panel3')}
          >
            <ExpansionPanelSummary>
              <Typography className={classes.heading}>Box Status</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <div className={classNames(classes.flexItem)}>
                <FormControlLabel
                  control={
                    <Checkbox
                      // disabled={ !isEditable ? true : false }
                      checked={this.state.IsActive}
                      onChange={this.handleChangeActive}
                      value="Active"
                      color="primary"
                    />
                  }
                />
                <label style={{ color: '#000' }}>Active</label>
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <ExpansionPanel
            square
            expanded={expanded === 'panel4'}
            onChange={this.handlePanelChange('panel4')}
          >
            <ExpansionPanelSummary>
              <Typography className={classes.heading}>Addon Units</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <div className={classNames(classes.table)}>
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">Type</TableCell>
                      <TableCell align="left">Order</TableCell>
                      <TableCell align="left">Connected</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell align="left">
                        <Select
                          style={{ marginTop: '8px' }}
                          fullWidth={true}
                          // value={this.state.selectedAddonUnit | ''}
                          onChange={this.onUnitChange}
                          inputProps={{
                            name: 'box_id',
                            id: 'box_ids'
                          }}
                        >
                          <MenuItem
                            value="L60 (Locker Addon with 60 bins)"
                            selected
                          >
                            L60
                          </MenuItem>
                          <MenuItem value="HD36 (Locker Addon with 36 bins)">
                            HD36
                          </MenuItem>
                          <MenuItem value="Cool (Temp Controlled with 8 bins)">
                            Cool
                          </MenuItem>
                        </Select>
                      </TableCell>
                      <TableCell align="left">
                        <i class="material-icons md-36">chevron_left</i>&nbsp;
                        <i class="material-icons md-36">chevron_right</i>
                      </TableCell>
                      <TableCell align="left">
                        <Select
                          style={{ marginTop: '8px' }}
                          fullWidth={true}
                          value={'Locker (Main Unit with 46 bins)'}
                          // onChange={this.handleChange}
                          inputProps={{
                            name: 'box_id',
                            id: 'box_ids'
                          }}
                        >
                          <MenuItem
                            value="Locker (Main Unit with 46 bins)"
                            selected
                          >
                            Main Unit
                          </MenuItem>
                          <MenuItem
                            value="L60 (Locker Addon with 60 bins)"
                            selected
                          >
                            L60
                          </MenuItem>
                          <MenuItem value="HD36 (Locker Addon with 36 bins)">
                            HD36
                          </MenuItem>
                          <MenuItem value="Cool (Temp Controlled with 8 bins)">
                            Cool
                          </MenuItem>
                        </Select>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </div>
        <div className={classNames(classes.buttonContainer)}>
          <Button
            type="button"
            color="primary"
            variant="contained"
            className={classNames(classes.primaryButton)}
            onClick={handleBack}
          >
            Back
          </Button>

          {/* <img src={keyboardImg} style={{height:'55px',marginTop:'1em',cursor:'pointer'}} alt="" onClick={this.keyboardHandler}></img> */}

          {/* <div></div> */}
        </div>

        <div
          className={classNames(
            this.state.showKeyboard
              ? classes.simpleKeyboardShow
              : classes.simpleKeyboard
          )}
        >
          <Keyboard
            keyboardRef={(r) => (this.keyboard = r)}
            layoutName={this.state.layoutName}
            maxLength={{ default: this.state.maxlength }}
            layout={{
              default: [
                '` 1 2 3 4 5 6 7 8 9 0 - = {backspace}',
                'q w e r t y u i o p [ ] \\',
                "{capslock} a s d f g h j k l ; '",
                'z x c v b n m , . /',
                '.com @ {space}'
              ],
              shift: [
                '~ ! @ # $ % ^ & * ( ) _ + {backspace}',
                'Q W E R T Y U I O P { } |',
                '{capslock} A S D F G H J K L : "',
                'Z X C V B N M < > ?',
                '.com @ {space}'
              ]
            }}
            onChange={
              this.state.isEnabled ? (input) => this.onChange(input) : ''
            }
            onKeyPress={
              this.state.isEnabled ? (button) => this.onKeyPress(button) : ''
            }
          />
        </div>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  injectIntl(AdminPanelFormControls)
);
