// snippet: rcep
import { withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './KioskOptHrsMain.styles';
import { KioskHeader, KioskOptHrs, KioskFooter, KioskIdelScreen } from '..';

export class KioskOptHrsMain extends Component {
  static propTypes = {
    test: PropTypes.string
  };

  render() {
    const { classes } = this.props;

    var customFooterBottom;
    if (window.screen.width === 1440) {
      customFooterBottom = '-15%';
    } else {
      customFooterBottom = '0%';
    }
    return (
      <div className={classNames(classes.container)}>
        <KioskHeader />
        <KioskOptHrs />
        <KioskFooter customFooterBottom={customFooterBottom} />
        <KioskIdelScreen />
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(KioskOptHrsMain);
