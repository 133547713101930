import { connect } from 'react-redux';
import AdminSettingForm from './AdminSettingForm';

import * as AdminLoginActions from './AdminSettingActionCreator';

const mapStateToProps = (state) => {
  const auth =
    state.get('auth') && state.get('auth').toJS && state.get('auth').toJS();
  const kiosk = state.get('kiosk');
  return {
    ...auth,
    ...kiosk
  };
};

const mapDispatchToProps = {
  ...AdminLoginActions
};

const AdminSettingFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminSettingForm);

export default AdminSettingFormContainer;
