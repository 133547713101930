const styles = (theme) => {
  return {
    container: {
      width: '100vw',
      height: '100vh',
      gridGap: '1rem',
      backgroundImage: 'linear-gradient(135deg, white, #dedede)',
      alignItems: 'normal'
      // paddingTop: '105px'
    }
  };
};

export default styles;
