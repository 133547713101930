import { connect } from 'react-redux';
import AdminKioskVerifyForm from './AdminKioskVerifyForm';

import * as AdminLoginActions from './AdminKioskVerifyActionCreator';

const mapStateToProps = (state) => {
  const auth =
    state.get('auth') && state.get('auth').toJS && state.get('auth').toJS();
  const kiosk = state.get('kiosk');
  return {
    ...auth,
    ...kiosk
  };
};

const mapDispatchToProps = {
  ...AdminLoginActions
};

const AdminKioskVerifyFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminKioskVerifyForm);

export default AdminKioskVerifyFormContainer;
