import React from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import { Card, Grid, withStyles } from '@material-ui/core';
import {
  RetailVerifyCode,
  RetailConfirmOrder,
  RetailOpenBin
} from './components';
import Footer from './components/RetailUI/footer';
import Header from './components/RetailUI/header';

const styles = () => ({
  root__container: {
    width: '100vw',
    backgroundColor: '#fff',
    height: '99vh',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'column'
  },
  header__footer__container: {
    height: '6%',
    textAlign: 'center',
    alignItems: 'center'
  },
  main__container: {
    height: '80%'
  },
  main__card: {
    backgroundColor: '#FFFFFF',
    height: '100%',
    boxShadow: 'none'
  }
});

const RetailUI = ({ classes, box, history, match }) => {
  return (
    <div className={classes.root__container}>
      <Header box={box} />
      <Grid
        container
        className={classes.main__container}
        justifyContent="center"
      >
        <Grid item xs={10} style={{ height: '100%' }}>
          <Card className={classes.main__card}>
            <Route
              path={`${match.path}/verification`}
              component={RetailVerifyCode}
            />
            <Route
              path={`${match.path}/confirm/order`}
              component={RetailConfirmOrder}
            />
            <Route path={`${match.path}/open-bin`} component={RetailOpenBin} />
          </Card>
        </Grid>
      </Grid>
      <Footer box={box} history={history} />
    </div>
  );
};

const mapStateToProps = (state) => {
  const kiosk = state.get('kiosk');
  return { ...kiosk };
};

export default connect(mapStateToProps)(withStyles(styles)(RetailUI));
