import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import styles from './safemode.style';
import {
  SimpleStartKioskWelcomeFooter,
  KioskHeader,
  KioskIdelScreen
} from '..';

class SafeMode extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classNames(classes.root)}>
        <KioskHeader />
        <div className={classNames(classes.dialogContentColor)}>
          <div className={classes.headerTitle}>notice</div>
          <div className={classes.sorry}>sorry</div>
          <div className={classes.temporarily}>temporarily</div>
          <div className={classes.outofservice}>out of service</div>
        </div>
        <SimpleStartKioskWelcomeFooter customFooterBottom="0%" />
        <KioskIdelScreen />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const kiosk =
    state.get('kiosk') && state.get('kiosk').toJS && state.get('kiosk').toJS();
  return {
    ...kiosk
  };
};

const SafeModeContainer = connect(mapStateToProps)(SafeMode);

export default withStyles(styles, { withTheme: true })(
  withRouter(SafeModeContainer)
);
