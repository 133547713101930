export const REDUCE_IMAGE_SIZE = async (
  base64Str,
  MAX_WIDTH = 256,
  MAX_HEIGHT = 144
) => {
  let resized_base64 = await new Promise((resolve) => {
    let img = new Image();
    img.src = base64Str;
    img.onload = () => {
      let canvas = document.createElement('canvas');
      let width = img.width;
      let height = img.height;

      if (width > height) {
        if (width > MAX_WIDTH) {
          height *= MAX_WIDTH / width;
          width = MAX_WIDTH;
        }
      } else {
        if (height > MAX_HEIGHT) {
          width *= MAX_HEIGHT / height;
          height = MAX_HEIGHT;
        }
      }
      canvas.width = width;
      canvas.height = height;
      let ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0, width, height);
      resolve(canvas.toDataURL()); // this will return base64 image results after resize
    };
  });
  return resized_base64;
};

export const MASK_STRING = ({
  str = '',
  mask = '...',
  startIndex = 0,
  endIndex = 3
} = {}) => str.substring(startIndex, endIndex).concat(mask);

export const isValidData = (data) => {
  switch (typeof data) {
    case 'string':
      return !!data && ![',', 'null', 'undefined'].includes(data);
    case 'object':
      return (
        data &&
        (Array.isArray(data)
          ? data.length && data.length > 0
          : Object.keys(data).length > 0)
      );
    default:
      return !!data;
  }
};
