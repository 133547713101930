import { v4 as uuidv4 } from 'uuid';
import { DateTime } from 'luxon';

export const kioskConfirmPickup = (data, formActions) => ({
  id: uuidv4(),
  datetime: DateTime.local(),
  type: 'KIOSK/CONFIRM_USER',
  context: {
    formActions
    // kiosk: context
  },
  payload: {
    ...data
  }
});

export const kioskConfirmUser = (data, formActions) => ({
  id: uuidv4(),
  datetime: DateTime.local(),
  type: 'KIOSK_CONFIRM_USER',
  context: {
    formActions
  },
  payload: {
    ...data
  }
});

export const kioskLocalPatientObj = (data, formActions) => ({
  id: uuidv4(),
  datetime: DateTime.local(),
  type: 'KIOSK_PATIENT_DETAILS',
  context: {
    formActions
  },
  payload: {
    ...data
  }
});

export const multipleOrders = (data, formActions, context) => ({
  id: uuidv4(),
  datetime: DateTime.local(),
  type: 'MULTIPLE_PICKUP',
  context: {
    formActions,
    ...context
  },
  payload: {
    data
  }
});
