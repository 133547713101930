import { Component } from 'react';
import { writeKioskLog } from './SyncAPIs';

export class EventLogHandler extends Component {
  handleEventLog = (event_params) => {
    try {
      fetch(process.env.REACT_APP_BASEURL + 'events', {
        method: 'post',
        withCredentials: true,
        headers: {
          Authorization:
            'Bearer ' +
            JSON.parse(sessionStorage.getItem('user')).signInUserSession.idToken
              .jwtToken,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(event_params)
      })
        .then((response) => {
          try {
            return response.text();
          } catch (error) {
            return null;
          }
        })
        .then(() => {
          if (
            this.props &&
            this.props.box &&
            (this.props.box.id !== undefined ||
              this.props.box.id !== 'undefined' ||
              this.props.box.id !== null ||
              this.props.box.id !== 'null' ||
              this.props.box.id !== '')
          ) {
            const mode = JSON.parse(localStorage.getItem('dynamicDataSource'));
            if (mode) {
              const audit_obj = {
                action_name: 'Admin Verify',
                user_id: JSON.parse(sessionStorage.getItem('user')).username,
                admin_url: localStorage.getItem('AdminImg'),
                perscription_master_id: event_params.tofor_id,
                description: event_params.description,
                parent_account_id: this.props.box.parent_account_id,
                box_id: this.props.box.id
              };
              writeKioskLog(audit_obj);
            }
          }
        });
    } catch (Exception) {
      console.log(
        `Events : Error while logging events with details : ${JSON.stringify(
          Exception
        )}`
      );
    }
  };
}

export default EventLogHandler;
