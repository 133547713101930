import loadable from 'loadable-components';

// Export all the component from current directory here in this file.

//Login
export const LoginLayout = loadable(() => import('./Login/LoginLayout'));
export const LoginHeader = loadable(() => import('./Login/LoginHeader'));
export const LoginMain = loadable(() => import('./Login/LoginMain'));
export const LoginSlides = loadable(() => import('./Login/LoginSlides'));
export const LoginForm = loadable(() => import('./Login/LoginForm'));
export const LoginFormControls = loadable(() =>
  import('./Login/LoginFormControls')
);
export const LoginNewPassword = loadable(() =>
  import('./Login/LoginNewPassword')
);
export const LoginForgotPassword = loadable(() =>
  import('./Login/LoginForgotPassword')
);
export const LoginFooter = loadable(() => import('./Login/LoginFooter'));
export const LoginFormContainer = loadable(() =>
  import('./Login/LoginFormContainer')
);

//Home Layout
export const HomeLayout = loadable(() => import('./Home/HomeLayout'));
export const HomeHeader = loadable(() => import('./Home/HomeHeader'));
export const HomeHeaderContainer = loadable(() =>
  import('./Home/HomeHeaderContainer')
);
export const HomeMain = loadable(() => import('./Home/HomeMain'));
export const HomeMainContainer = loadable(() =>
  import('./Home/HomeMainContainer')
);
export const HomeNavigation = loadable(() => import('./Home/HomeNavigation'));
export const HomeNavigationContainer = loadable(() =>
  import('./Home/HomeNavigationContainer')
);

//Kiosk Layout
export const KioskHeader = loadable(() => import('./KioskLayout/KioskHeader'));
export const KioskFooter = loadable(() => import('./KioskLayout/KioskFooter'));
export const KioskIdelScreen = loadable(() =>
  import('./KioskLayout/KioskIdelScreen')
);
export const KioskMainHeader = loadable(() =>
  import('./KioskLayout/KioskMainHeader')
);
export const LanguageToggle = loadable(() => import('./Kiosk/LanguageToggle'));
export const LanguageToggleContainer = loadable(() =>
  import('./Kiosk/LanguageToggleContainer')
);
// Kiosk
export const KioskLayout = loadable(() => import('./Kiosk/KioskLayout'));
export const KioskContent = loadable(() => import('./Kiosk/KioskContent'));
export const KioskBackgroundSlider = loadable(() =>
  import('./Kiosk/KioskBackgroundSlider')
);
export const KioskBackgroundSliderContainer = loadable(() =>
  import('./Kiosk/KioskBackgroundSliderContainer')
);
export const KioskSlides = loadable(() => import('./Kiosk/KioskSlides'));

// Kiosk Slides
export const KioskSideSlides = loadable(() =>
  import('./KioskLayout/KioskSideSlides')
);

// KioskPickup
export const KioskPickupLayout = loadable(() =>
  import('./KioskPickup/KioskPickupLayout')
);
export const KioskPickupMain = loadable(() =>
  import('./KioskPickup/KioskPickupMain')
);
export const KioskEnroll = loadable(() => import('./KioskPickup/KioskEnroll'));

export const KioskPickupFormContainer = loadable(() =>
  import('./KioskPickup/KioskPickupFormContainer')
);
export const KioskPickupFormControls = loadable(() =>
  import('./KioskPickup/KioskPickupFormControls')
);

//KioskSimpleStartWelcomeScreen
export const SimpleStartKioskWelcomeScreenLayout = loadable(() =>
  import('./KioskSimpleStartWelcomeScreen/SimpleStartKioskWelcomeScreenLayout')
);
export const SimpleStartKioskWelcomeScreenMain = loadable(() =>
  import('./KioskSimpleStartWelcomeScreen/SimpleStartKioskWelcomeScreenMain')
);
export const SimpleStartKioskWelcomeScreenEnroll = loadable(() =>
  import('./KioskSimpleStartWelcomeScreen/SimpleStartKioskEnroll')
);

export const SimpleStartKioskWelcomeScreenFormContainer = loadable(() =>
  import('./KioskSimpleStartWelcomeScreen/SimpleStartKioskWelcomeFormContainer')
);
export const SimpleStartKioskWelcomeScreenFormControls = loadable(() =>
  import(
    './KioskSimpleStartWelcomeScreen/SimpleStartKioskWelcomeScreenFormControls'
  )
);
export const SimpleStartKioskWelcomeHeader = loadable(() =>
  import('./KioskSimpleStartWelcomeScreen/SimpleStartKioskWelcomeHeader')
);
export const SimpleStartKioskWelcomeFooter = loadable(() =>
  import('./KioskSimpleStartWelcomeScreen/SimpleStartKioskWelcomeFooter')
);

// KioskWelcomeScreen
export const KioskWelcomeScreenLayout = loadable(() =>
  import('./KioskWelcomeScreen/KioskWelcomeScreenLayout')
);
export const KioskWelcomeScreenMain = loadable(() =>
  import('./KioskWelcomeScreen/KioskWelcomeScreenMain')
);
export const KioskWelcomeScreenEnroll = loadable(() =>
  import('./KioskWelcomeScreen/KioskEnroll')
);

export const KioskWelcomeScreenFormContainer = loadable(() =>
  import('./KioskWelcomeScreen/KioskWelcomeScreenFormContainer')
);
export const KioskWelcomeScreenFormControls = loadable(() =>
  import('./KioskWelcomeScreen/KioskWelcomeScreenFormControls')
);
export const KioskWelcomeHeader = loadable(() =>
  import('./KioskWelcomeScreen/KioskWelcomeHeader')
);
export const KioskWelcomeFooter = loadable(() =>
  import('./KioskWelcomeScreen/KioskWelcomeFooter')
);

// KioskCheckin
export const KioskCheckinLayout = loadable(() =>
  import('./KioskCheckin/KioskCheckinLayout')
);
export const KioskCheckinMain = loadable(() =>
  import('./KioskCheckin/KioskCheckinMain')
);
export const KioskCheckinFormContainer = loadable(() =>
  import('./KioskCheckin/KioskCheckinFormContainer')
);
export const KioskCheckinFormControls = loadable(() =>
  import('./KioskCheckin/KioskCheckinFormControls')
);
// KioskCheckConfirmuser
export const KioskCheckConfirmuserLayout = loadable(() =>
  import('./KioskCheckConfirmuser/KioskCheckConfirmuserLayout')
);
export const KioskCheckConfirmuserMain = loadable(() =>
  import('./KioskCheckConfirmuser/KioskCheckConfirmuserMain')
);
export const KioskCheckConfirmuserFormContainer = loadable(() =>
  import('./KioskCheckConfirmuser/KioskCheckConfirmuserFormContainer')
);
export const KioskCheckConfirmuserFormControls = loadable(() =>
  import('./KioskCheckConfirmuser/KioskCheckConfirmuserFormControls')
);

// Kiosk Consult
export const KioskConsultLayout = loadable(() =>
  import('./KioskConsult/KioskConsultLayout')
);
export const KioskConsultMain = loadable(() =>
  import('./KioskConsult/KioskConsultMain')
);
export const KioskConsultFormContainer = loadable(() =>
  import('./KioskConsult/KioskConsultFormContainer')
);
export const KioskConsultForm = loadable(() =>
  import('./KioskConsult/KioskConsultForm')
);
export const KioskConsultFormControls = loadable(() =>
  import('./KioskConsult/KioskConsultFormControls')
);

// Kiosk signature
export const KioskSignatureMain = loadable(() =>
  import('./KioskSignature/KioskSignatureMain')
);
export const KioskSignature = loadable(() =>
  import('./KioskSignature/KioskSignature')
);
export const KioskSignatureContainer = loadable(() =>
  import('./KioskSignature/KioskSignatureContainer')
);

// Kiosk OptHrs
export const KioskOptHrsMain = loadable(() =>
  import('./KioskOptHrs/KioskOptHrsMain')
);
export const KioskOptHrs = loadable(() => import('./KioskOptHrs/KioskOptHrs'));

export const KioskHealthandinformationLayout = loadable(() =>
  import('./KioskHealthandinformation/KioskHealthandinformationLayout')
);
export const KioskHealthandinformationMain = loadable(() =>
  import('./KioskHealthandinformation/KioskHealthandinformationMain')
);
export const KioskHealthandinformationFormContainer = loadable(() =>
  import('./KioskHealthandinformation/KioskHealthandinformationFormContainer')
);
export const KioskHealthandinformationForm = loadable(() =>
  import('./KioskHealthandinformation/KioskHealthandinformationForm')
);
export const KioskHealthandinformationFormControls = loadable(() =>
  import('./KioskHealthandinformation/KioskHealthandinformationFormControls')
);

// Kiosk GetKit
export const KioskGetKitLayout = loadable(() =>
  import('./KioskGetKit/KioskGetKitLayout')
);
export const KioskGetKitMain = loadable(() =>
  import('./KioskGetKit/KioskGetKitMain')
);
export const KioskGetKitFormContainer = loadable(() =>
  import('./KioskGetKit/KioskGetKitFormContainer')
);
export const KioskGetKitForm = loadable(() =>
  import('./KioskGetKit/KioskGetKitForm')
);
export const KioskGetKitFormControls = loadable(() =>
  import('./KioskGetKit/KioskGetKitFormControls')
);
// Kiosk Verify Me
export const KioskVerifyMeLayout = loadable(() =>
  import('./KioskVerifyMe/KioskVerifyMeLayout')
);
export const KioskVerifyMeMain = loadable(() =>
  import('./KioskVerifyMe/KioskVerifyMeMain')
);
export const KioskVerifyMeFormContainer = loadable(() =>
  import('./KioskVerifyMe/KioskVerifyMeFormContainer')
);
export const KioskVerifyMeFormControls = loadable(() =>
  import('./KioskVerifyMe/KioskVerifyMeFormControls')
);

// Kiosk Confirm Rx
export const KioskConfimPickupLayout = loadable(() =>
  import('./KioskConfimPickup/KioskConfimPickupLayout')
);
export const KioskConfimPickupMain = loadable(() =>
  import('./KioskConfimPickup/KioskConfimPickupMain')
);
export const KioskConfimPickupFormContainer = loadable(() =>
  import('./KioskConfimPickup/KioskConfimPickupFormContainer')
);
export const KioskConfimPickupFormControls = loadable(() =>
  import('./KioskConfimPickup/KioskConfimPickupFormControls')
);
export const KioskConfirmPickupDetails = loadable(() =>
  import('./KioskConfimPickup/KioskConfirmPickupDetails')
);
export const KioskConfirmPickupPayment = loadable(() =>
  import('./KioskConfimPickup/KioskConfirmPickupPayment')
);

//Kiosk Feedback
export const KioskFeedbackLayout = loadable(() =>
  import('./KioskFeedback/KioskFeedbackLayout')
);
export const KioskFeedbackMain = loadable(() =>
  import('./KioskFeedback/KioskFeedbackMain')
);

//Kiosk Door
export const KioskDoorLayout = loadable(() =>
  import('./KioskDoor/KioskDoorLayout')
);
export const KioskDoorMain = loadable(() =>
  import('./KioskDoor/KioskDoorMain')
);
export const KioskDoorFormContainer = loadable(() =>
  import('./KioskDoor/KioskDoorFormContainer')
);
export const KioskDoorForm = loadable(() =>
  import('./KioskDoor/KioskDoorForm')
);
export const KioskDoorFormControls = loadable(() =>
  import('./KioskDoor/KioskDoorFormControls')
);

//Kiosk Confirm User
export const KioskCollectIDLayout = loadable(() =>
  import('./KioskCollenctID/KioskCollectIDLayout')
);
export const KioskCollectIDMain = loadable(() =>
  import('./KioskCollenctID/KioskCollectIDMain')
);
export const KioskCollectFormContainer = loadable(() =>
  import('./KioskCollenctID/KioskCollectFormContainer')
);
export const KioskCollectForm = loadable(() =>
  import('./KioskCollenctID/KioskCollectForm')
);
export const KioskCollectFormControls = loadable(() =>
  import('./KioskCollenctID/KioskCollectFormControls')
);

//Kiosk Secure Badge Login
export const SecureBadgeLogin = loadable(() =>
  import('./SecureBadgeLogin/SecureBadgeLoginForm')
);

//Kiosk Enroll New User
export const KioskEnrollmentLayout = loadable(() =>
  import('./KioskEnrollment/KioskEnrollmentLayout')
);
export const KioskEnrollmentMain = loadable(() =>
  import('./KioskEnrollment/KioskEnrollmentMain')
);
export const KioskEnrollmentFormContainer = loadable(() =>
  import('./KioskEnrollment/KioskEnrollmentFormContainer')
);
export const KioskEnrollmentForm = loadable(() =>
  import('./KioskEnrollment/KioskEnrollmentForm')
);
export const KioskEnrollmentFormControls = loadable(() =>
  import('./KioskEnrollment/KioskEnrollmentFormControls')
);
export const KioskEnrollConfirmation = loadable(() =>
  import('./KioskEnrollment/KioskEnrollConfirmation')
);

//Kiosk Enroll New Patient
export const KioskNewPatientEnrollmentLayout = loadable(() =>
  import('./KioskNewPateint/KioskNewPatientEnrollmentLayout')
);
export const KioskNewPatientEnrollmentMain = loadable(() =>
  import('./KioskNewPateint/KioskNewPatientEnrollmentMain')
);
export const KioskNewPatientEnrollmentFormContainer = loadable(() =>
  import('./KioskNewPateint/KioskNewPatientEnrollmentFormContainer')
);
export const KioskNewPatientEnrollmentForm = loadable(() =>
  import('./KioskNewPateint/KioskNewPatientEnrollmentForm')
);
export const KioskNewPatientEnrollmentFormControls = loadable(() =>
  import('./KioskNewPateint/KioskNewPatientEnrollmentFormControls')
);
export const KioskNewPatientEnrollConfirmation = loadable(() =>
  import('./KioskNewPateint/KioskNewPatientEnrollConfirmation')
);
//Kiosk New Patient Search Result
export const KioskNewPatientSearchResultLayout = loadable(() =>
  import('./KioskNewPatientSearchResult/KioskNewPatientSearchResultLayout')
);
export const KioskNewPatientSearchResultMain = loadable(() =>
  import('./KioskNewPatientSearchResult/KioskNewPatientSearchResultMain')
);
export const KioskNewPatientSearchResultFormContainer = loadable(() =>
  import(
    './KioskNewPatientSearchResult/KioskNewPatientSearchResultFormContainer'
  )
);
export const KioskNewPatientSearchResultForm = loadable(() =>
  import('./KioskNewPatientSearchResult/KioskNewPatientSearchResultForm')
);
export const KioskNewPatientSearchResultFormControls = loadable(() =>
  import(
    './KioskNewPatientSearchResult/KioskNewPatientSearchResultFormControls'
  )
);
export const KioskNewPatientSearchResultConfirmation = loadable(() =>
  import(
    './KioskNewPatientSearchResult/KioskNewPatientSearchResultConfirmation'
  )
);

//Kiosk QuizPass
export const KioskQuizPassLayout = loadable(() =>
  import('./KioskQuizPass/KioskQuizPassLayout')
);
export const KioskQuizPassMain = loadable(() =>
  import('./KioskQuizPass/KioskQuizPassMain')
);
export const KioskQuizPassFormContainer = loadable(() =>
  import('./KioskQuizPass/KioskQuizPassFormContainer')
);
export const KioskQuizPassForm = loadable(() =>
  import('./KioskQuizPass/KioskQuizPassForm')
);
export const KioskQuizPassFormControls = loadable(() =>
  import('./KioskQuizPass/KioskQuizPassFormControls')
);

//Kiosk Current Patient
export const KioskCurrentPatientSearchLayout = loadable(() =>
  import('./KioskCurrentPatient/KioskCurrentPatientSearchLayout')
);
export const KioskCurrentPatientSearchMain = loadable(() =>
  import('./KioskCurrentPatient/KioskCurrentPatientSearchMain')
);
export const KioskCurrentPatientSearchFormContainer = loadable(() =>
  import('./KioskCurrentPatient/KioskCurrentPatientSearchFormContainer')
);
export const KioskCurrentPatientSearchForm = loadable(() =>
  import('./KioskCurrentPatient/KioskCurrentPatientSearchForm')
);
export const KioskCurrentPatientSearchFormControls = loadable(() =>
  import('./KioskCurrentPatient/KioskCurrentPatientSearchFormControls')
);
export const KioskCurrentPatientSearchConfirmation = loadable(() =>
  import('./KioskCurrentPatient/KioskCurrentPatientSearchConfirmation')
);

//Kiosk Current Patient Search Result
export const KioskCurrentPatientSearchResultLayout = loadable(() =>
  import(
    './KioskCurrentPatientSearchResult/KioskCurrentPatientSearchResultLayout'
  )
);
export const KioskCurrentPatientSearchResultMain = loadable(() =>
  import(
    './KioskCurrentPatientSearchResult/KioskCurrentPatientSearchResultMain'
  )
);
export const KioskCurrentPatientSearchResultFormContainer = loadable(() =>
  import(
    './KioskCurrentPatientSearchResult/KioskCurrentPatientSearchResultFormContainer'
  )
);
export const KioskCurrentPatientSearchResultForm = loadable(() =>
  import(
    './KioskCurrentPatientSearchResult/KioskCurrentPatientSearchResultForm'
  )
);
export const KioskCurrentPatientSearchResultFormControls = loadable(() =>
  import(
    './KioskCurrentPatientSearchResult/KioskCurrentPatientSearchResultFormControls'
  )
);
export const KioskCurrentPatientSearchResultConfirmation = loadable(() =>
  import(
    './KioskCurrentPatientSearchResult/KioskCurrentPatientSearchResultConfirmation'
  )
);

//Kiosk Patient Login
export const KioskPatientLoginLayout = loadable(() =>
  import('./KioskPatientLogin/KioskPatientLoginLayout')
);
export const KioskPatientLoginMain = loadable(() =>
  import('./KioskPatientLogin/KioskPatientLoginMain')
);
export const KioskPatientLoginFormContainer = loadable(() =>
  import('./KioskPatientLogin/KioskPatientLoginFormContainer')
);
export const KioskPatientLoginForm = loadable(() =>
  import('./KioskPatientLogin/KioskPatientLoginForm')
);
export const KioskPatientLoginFormControls = loadable(() =>
  import('./KioskPatientLogin/KioskPatientLoginFormControls')
);

/*Patient Login Layout */
export const PatientLoginLayout = loadable(() =>
  import('./PatientLogin/PatientLoginLayout')
);
export const PatientLoginMain = loadable(() =>
  import('./PatientLogin/PatientLoginMain')
);
export const PatientLoginFormContainer = loadable(() =>
  import('./PatientLogin/PatientLoginFormContainer')
);
export const PatientLoginFormControls = loadable(() =>
  import('./PatientLogin/PatientLoginFormControls')
);
export const PatientLoginForm = loadable(() =>
  import('./PatientLogin/PatientLoginForm')
);
export const PatientLoginNewPassword = loadable(() =>
  import('./PatientLogin/PatientLoginNewPassword')
);
export const PatientLoginForgotPassword = loadable(() =>
  import('./PatientLogin/PatientLoginForgotPassword')
);

/*Patient Kiosk ViewRx */
export const KioskPatientViewRxLayout = loadable(() =>
  import('./KioskPatientViewRx/KioskPatientViewRxLayout')
);
export const KioskPatientViewRxMain = loadable(() =>
  import('./KioskPatientViewRx/KioskPatientViewRxMain')
);
export const KioskPatientViewRxFormContainer = loadable(() =>
  import('./KioskPatientViewRx/KioskPatientViewRxFormContainer')
);
export const KioskPatientViewRxFormControls = loadable(() =>
  import('./KioskPatientViewRx/KioskPatientViewRxFormControls')
);
export const KioskPatientViewRxDetails = loadable(() =>
  import('./KioskPatientViewRx/KioskPatientViewRxDetails')
);
export const KioskPatientViewRxPayment = loadable(() =>
  import('./KioskPatientViewRx/KioskPatientViewRxPayment')
);

/*Kiosk RefillRx */
export const KioskRefillRxLayout = loadable(() =>
  import('./KioskRefillRx/KioskRefillRxLayout')
);
export const KioskRefillRxMain = loadable(() =>
  import('./KioskRefillRx/KioskRefillRxMain')
);
export const KioskRefillRxFormContainer = loadable(() =>
  import('./KioskRefillRx/KioskRefillRxFormContainer')
);
export const KioskRefillRxForm = loadable(() =>
  import('./KioskRefillRx/KioskRefillRxForm')
);
export const KioskRefillRxFormControls = loadable(() =>
  import('./KioskRefillRx/KioskRefillRxFormControls')
);
export const KioskRefillRxConfirmation = loadable(() =>
  import('./KioskRefillRx/KioskRefillRxConfirmation')
);

/*Kiosk QuizTest */
export const KioskQuizTestLayout = loadable(() =>
  import('./KioskQuizTest/KioskQuizTestLayout')
);
export const KioskQuizTestMain = loadable(() =>
  import('./KioskQuizTest/KioskQuizTestMain')
);
export const KioskQuizTestFormContainer = loadable(() =>
  import('./KioskQuizTest/KioskQuizTestFormContainer')
);
export const KioskQuizTestForm = loadable(() =>
  import('./KioskQuizTest/KioskQuizTestForm')
);
export const KioskQuizTestFormControls = loadable(() =>
  import('./KioskQuizTest/KioskQuizTestFormControls')
);

//Kiosk Enroll New User for quiz
export const KioskQuizEnrollmentLayout = loadable(() =>
  import('./KioskQuizEnrollment/KioskQuizEnrollmentLayout')
);
export const KioskQuizEnrollmentMain = loadable(() =>
  import('./KioskQuizEnrollment/KioskQuizEnrollmentMain')
);
export const KioskQuizEnrollmentFormContainer = loadable(() =>
  import('./KioskQuizEnrollment/KioskQuizEnrollmentFormContainer')
);
export const KioskQuizEnrollmentForm = loadable(() =>
  import('./KioskQuizEnrollment/KioskQuizEnrollmentForm')
);
export const KioskQuizEnrollmentFormControls = loadable(() =>
  import('./KioskQuizEnrollment/KioskQuizEnrollmentFormControls')
);

/*Kiosk ShopNow */
export const KioskShopNowLayout = loadable(() =>
  import('./KioskShopNow/KioskShopNowLayout')
);
export const KioskShopNowMain = loadable(() =>
  import('./KioskShopNow/KioskShopNowMain')
);
export const KioskShopNowFormContainer = loadable(() =>
  import('./KioskShopNow/KioskShopNowFormContainer')
);
export const KioskShopNowForm = loadable(() =>
  import('./KioskShopNow/KioskShopNowForm')
);
export const KioskShopNowFormControls = loadable(() =>
  import('./KioskShopNow/KioskShopNowFormControls')
);
export const KioskShopNowConfirmation = loadable(() =>
  import('./KioskShopNow/KioskShopNowConfirmation')
);

/*Kiosk ProductList */
export const KioskProductListLayout = loadable(() =>
  import('./KioskProductList/KioskProductListLayout')
);
export const KioskProductListMain = loadable(() =>
  import('./KioskProductList/KioskProductListMain')
);
export const KioskProductListFormContainer = loadable(() =>
  import('./KioskProductList/KioskProductListFormContainer')
);
export const KioskProductListForm = loadable(() =>
  import('./KioskProductList/KioskProductListForm')
);
export const KioskProductListFormControls = loadable(() =>
  import('./KioskProductList/KioskProductListFormControls')
);

/*Patient Order Item */
export const KioskOrderItemLayout = loadable(() =>
  import('./KioskOrderItem/KioskOrderItemLayout')
);
export const KioskOrderItemMain = loadable(() =>
  import('./KioskOrderItem/KioskOrderItemMain')
);
export const KioskOrderItemFormContainer = loadable(() =>
  import('./KioskOrderItem/KioskOrderItemFormContainer')
);
export const KioskOrderItemFormControls = loadable(() =>
  import('./KioskOrderItem/KioskOrderItemFormControls')
);
export const KioskOrderItemForm = loadable(() =>
  import('./KioskOrderItem/KioskOrderItemForm')
);

// Kiosk information
export const KioskInformationMain = loadable(() =>
  import('./KioskInformation/KioskInformationMain')
);
export const KioskInformation = loadable(() =>
  import('./KioskInformation/KioskInformation')
);
export const KioskInformationContainer = loadable(() =>
  import('./KioskInformation/KioskInformationContainer')
);
/*====================================================Admin Layout================================================ */
/*Admin Login Layout */
export const AdminLoginLayout = loadable(() =>
  import('./AdminLogin/AdminLoginLayout')
);
export const AdminLoginMain = loadable(() =>
  import('./AdminLogin/AdminLoginMain')
);
export const AdminLoginFormContainer = loadable(() =>
  import('./AdminLogin/AdminLoginFormContainer')
);
export const AdminLoginFormControls = loadable(() =>
  import('./AdminLogin/AdminLoginFormControls')
);
export const AdminLoginForm = loadable(() =>
  import('./AdminLogin/AdminLoginForm')
);
export const AdminLoginNewPassword = loadable(() =>
  import('./AdminLogin/AdminLoginNewPassword')
);
export const AdminLoginForgotPassword = loadable(() =>
  import('./AdminLogin/AdminLoginForgotPassword')
);

/*Admin Layout */
export const AdminFooter = loadable(() => import('./AdminLayout/AdminFooter'));
export const AdminHeader = loadable(() => import('./AdminLayout/AdminHeader'));
export const AdminMainHeader = loadable(() =>
  import('./AdminLayout/AdminMainHeader')
);
export const AdminSideSlides = loadable(() =>
  import('./AdminLayout/AdminSideSlides')
);

/*Admin Panel Layout */
export const AdminPanelLayout = loadable(() =>
  import('./AdminPanel/AdminPanelLayout')
);
export const AdminPanelMain = loadable(() =>
  import('./AdminPanel/AdminPanelMain')
);
export const AdminPanelFormContainer = loadable(() =>
  import('./AdminPanel/AdminPanelFormContainer')
);
export const AdminPanelFormControls = loadable(() =>
  import('./AdminPanel/AdminPanelFormControls')
);

/*Admin Panel Stock */
export const AdminPanelStockLayout = loadable(() =>
  import('./AdminPanelStock/AdminPanelStockLayout')
);
export const AdminPanelStockMain = loadable(() =>
  import('./AdminPanelStock/AdminPanelStockMain')
);
export const AdminPanelStockFormContainer = loadable(() =>
  import('./AdminPanelStock/AdminPanelStockFormContainer')
);
export const AdminPanelStockFormControls = loadable(() =>
  import('./AdminPanelStock/AdminPanelStockFormControls')
);

/*Admin Panel Maintanence */
export const AdminMaintenanceLayout = loadable(() =>
  import('./AdminMaintenance/AdminMaintenanceLayout')
);
export const AdminMaintenanceMain = loadable(() =>
  import('./AdminMaintenance/AdminMaintenanceMain')
);
export const AdminMaintenanceFormContainer = loadable(() =>
  import('./AdminMaintenance/AdminMaintenanceFormContainer')
);
export const AdminMaintenanceFormControls = loadable(() =>
  import('./AdminMaintenance/AdminMaintenanceFormControls')
);

/*Admin Panel Settings */
export const AdminSettingLayout = loadable(() =>
  import('./AdminSetting/AdminSettingLayout')
);
export const AdminSettingMain = loadable(() =>
  import('./AdminSetting/AdminSettingMain')
);
export const AdminSettingFormContainer = loadable(() =>
  import('./AdminSetting/AdminSettingFormContainer')
);
export const AdminSettingFormControls = loadable(() =>
  import('./AdminSetting/AdminSettingFormControls')
);

/*Admin Kiosk Verify Settings*/
export const AdminKioskVerifyLayout = loadable(() =>
  import('./KioskVerify/AdminKioskVerifyLayout')
);
export const AdminKioskVerifyMain = loadable(() =>
  import('./KioskVerify/AdminKioskVerifyMain')
);
export const AdminKioskVerifyFormContainer = loadable(() =>
  import('./KioskVerify/AdminKioskVerifyFormContainer')
);
export const AdminKioskVerifyFormControls = loadable(() =>
  import('./KioskVerify/AdminKioskVerifyFormControls')
);

/*Admin Remove */
export const AdminRemoveLayout = loadable(() =>
  import('./AdminRemove/AdminRemoveLayout')
);
export const AdminRemoveLayoutMain = loadable(() =>
  import('./AdminRemove/AdminRemoveLayoutMain')
);
export const AdminRemoveFormContainer = loadable(() =>
  import('./AdminRemove/AdminRemoveFormContainer')
);
export const AdminRemoveFormControls = loadable(() =>
  import('./AdminRemove/AdminRemoveFormControls')
);

// Kiosk Verify Contactless Pickup
export const ContactlessPickupLayout = loadable(() =>
  import('./ContactlessCapturePickup/ContactlessPickupLayout')
);
export const ContactlessPickupFormContainer = loadable(() =>
  import('./ContactlessCapturePickup/ContactlessPickupFormContainer')
);

/** Kiosk Forgot PUCode Screen  */
export const KioskForgotPUCodeLayout = loadable(() =>
  import('./KioskForgotPickUPCode/KioskForgotPUCodeLayout')
);
export const KioskForgotPUCodeMain = loadable(() =>
  import('./KioskForgotPickUPCode/KioskForgotPUCodeMain')
);
export const KioskForgotPUCodeFormContainer = loadable(() =>
  import('./KioskForgotPickUPCode/KioskForgotPUCodeFormContainer')
);
export const KioskForgotPickUPCodeFormControls = loadable(() =>
  import('./KioskForgotPickUPCode/KioskForgotPUCodeFormControls')
);

// Express UI
export const ExpressWelcome = loadable(() => import('./ExpressUI/Welcome'));
export const ExpressVerifyDOB = loadable(() => import('./ExpressUI/VerifyDOB'));
export const ExpressConfirmLegalDocs = loadable(() =>
  import('./ExpressUI/ConfirmLegalDocs')
);
export const ExpressConfirmPickup = loadable(() =>
  import('./ExpressUI/ConfirmPickup')
);
export const ExpressConsultation = loadable(() =>
  import('./ExpressUI/Consultation')
);
export const ExpressCaptureDL = loadable(() => import('./ExpressUI/CaptureDL'));
export const ExpressOpenBin = loadable(() => import('./ExpressUI/OpenBin'));
export const ExpressThankYou = loadable(() => import('./ExpressUI/ThankYou'));

// RetailUI
export const RetailVerifyCode = loadable(() => import('./RetailUI/vefifyCode'));
export const RetailConfirmOrder = loadable(() =>
  import('./RetailUI/confirmOrder')
);
export const RetailOpenBin = loadable(() => import('./RetailUI/openBin'));
export const FlpKioskIdelScreen = loadable(() =>
  import('./Common/FlpKioskIdelScreen')
);
