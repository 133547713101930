import React, { memo } from 'react';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';

const Header = memo(({ box, height = '65px' }) => {
  const brand = JSON.parse(localStorage.getItem('brands'));
  const logo =
    (brand && brand.length > 0 && brand[0].brand_logo_url) ||
    (box && box.account && box.account.brand && box.account.brand.logo_url);

  return (
    <Grid
      container
      style={{
        height: '6%',
        textAlign: 'center',
        alignItems: 'center'
      }}
    >
      <Grid item xs={12}>
        {logo ? (
          <img alt="logo" height={height} src={logo} />
        ) : (
          <div style={{ height }} />
        )}
      </Grid>
    </Grid>
  );
});

const mapStateToProps = (state) => {
  const kiosk = state.get('kiosk');
  return { ...kiosk };
};

export default connect(mapStateToProps)(Header);
