import React from 'react';
import { injectIntl } from 'react-intl';
import { Grid, withStyles } from '@material-ui/core';
import { LanguageToggleContainer } from '..';
import Powered_By_Localbox from '../../assets/images/Powered_By_Localbox.png';

const styles = () => ({
  footer__container: {
    height: '10%',
    textAlign: 'center',
    alignItems: 'center'
  },
  needHelp: {
    color: '#939598',
    fontWeight: '500'
  },
  needHelpPhone: {
    color: '#939598',
    fontWeight: '100'
  },
  dialogContent: {
    color: '#77818D',
    textAlign: 'center',
    fontSize: '1.9em',
    fontWeight: '500'
  },
  dialogActions: {
    justifyContent: 'center',
    alignItems: 'center'
  },
  button: {
    color: 'black',
    fontSize: '1.9em'
  }
});

const Footer = ({ classes, history }) => {
  return (
    <>
      <Grid
        container
        className={classes.footer__container}
        justifyContent="center"
        alignItems="center"
      >
        <Grid
          item
          xs={2}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <LanguageToggleContainer />
        </Grid>
        <Grid item xs={8}>
          &nbsp;
        </Grid>
        <Grid
          item
          xs={2}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <img
            src={Powered_By_Localbox}
            alt="Powered_By_Localbox"
            width="90px"
            onClick={() => history.push('/SecureBadgeLogin')}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default withStyles(styles)(injectIntl(Footer));
