const styles = (theme) => {
  return {
    container: {
      display: 'grid',
      width: '100vw',
      height: '100vh',
      gridGap: '1rem',
      gridTemplateColumns: 'auto',
      justifyContent: 'center',
      //alignItems: 'center',
      // gridTemplateRows: '12% auto 3em',
      // backgroundImage: 'linear-gradient(135deg, white, #dedede)',
      alignItems: 'normal',
      paddingTop: '90px',
      backgroundColor: 'white'
    }
  };
};

export default styles;
