const styles = (theme) => {
    return {
        fproBackButton: {
            fontSize: '22px',
            background: '#fff',
            color: '#aaa',
            border: '1px solid  #2F5597',
            borderRadius: '10px',
            lineHeight: '20px',
            '&:hover': {
                backgroundColor: '#fff',
                color: '#aaa'
            }
        },
        primaryButton: {
            color: '#fff',
            fontSize: '22px',
            width: '100%',
            background: ' #2F5597',
            border: '1px solid  #2F5597',
            borderRadius: '10px',
            '&:hover': {
                backgroundColor: ' #2F5597'
            }
        },
    };
};

export default styles;