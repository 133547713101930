import { withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './AdminHeader.styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import { withRouter } from 'react-router';
import Logo from './../MainLogo';
import { readSettings } from '../../helpers/board';
import { injectIntl, FormattedMessage } from 'react-intl';
export class AdminHeaderContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      phoneno: ''
    };
  }
  static propTypes = {
    test: PropTypes.string
  };

  handleClickOpen = () => {
    this.setState({ open: true });
    let mode;
    if (window.electron) {
      const fileData = JSON.parse(readSettings());
      mode = fileData ? fileData.dynamicDataSource : false;
    } else {
      mode = JSON.parse(localStorage.getItem('dynamicDataSource'));
    }
    if (mode === true || mode === 'true') {
      if (
        this.props.box &&
        this.props.box.account &&
        this.props.box.account.brand.phone
      ) {
        this.setState({ phoneno: this.props.box.account.brand.phone });
      }
    } else {
      if (
        this.props.box &&
        this.props.box.account &&
        this.props.box.account.contact.phone
      ) {
        this.setState({ phoneno: this.props.box.account.contact.phone });
      }
    }
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleBack = () => {
    this.props.history.push('/');
  };

  render() {
    const { classes, myLogoheight, box } = this.props;

    // let logoUrl, dynamicHeight;
    //     if(this.props.box.account.brand.name === "Wallmart")
    //     {
    //       logoUrl =this.props.box.account.brand.logo_url;
    //       dynamicHeight = "80px";
    //     }

    return (
      <div className={classNames(classes.root)}>
        <div className={classNames(classes.NeedHelp)}>
          {/* <label className={classNames(classes.labelClass)}>
            <span
              onClick={this.handleClickOpen}
              className={classNames(classes.spanLink)}
            >
              <FormattedMessage
                id="Welcome.NeedHelp"
                defaultMessage="Need help?"
              />
            </span>
          </label> */}
        </div>
        <div className={classNames(classes.logo)}>
          <Logo box={box} myLogoheight={myLogoheight} />
        </div>
        <div className={classNames(classes.exit)}>
          {/* <label className={classNames(classes.ExitlabelClass)}>
            <span
              onClick={this.handleBack}
              className={classNames(classes.spanLink)}
            >
              <FormattedMessage id="Welcome.Exit" defaultMessage="Exit" />
            </span>
          </label> */}
        </div>
        <div>
          <Dialog
            maxWidth={'lg'}
            fullWidth={true}
            open={this.state.open}
            keepMounted
            onClose={this.handleClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            PaperProps={{
              style: { border: '2px solid #0C7CBA', borderRadius: '30px' }
            }}
          >
            <div className={classNames(classes.dialogBackground)}>
              <DialogContent className={classNames(classes.dialogContentColor)}>
                <FormattedMessage
                  id="Welcome.CallUs"
                  defaultMessage="If you need help, call us at "
                />
                {this.state.phoneno}
              </DialogContent>
              <DialogActions className={classNames(classes.alignButton)}>
                <Button
                  onClick={this.handleClose}
                  className={classNames(classes.buttonColor)}
                >
                  <FormattedMessage id="Welcome.OK" defaultMessage="OK" />
                </Button>
              </DialogActions>
            </div>
          </Dialog>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const kiosk =
    // state.get('kiosk') && state.get('kiosk').toJS && state.get('kiosk').toJS();
    state.get('kiosk');
  return {
    ...kiosk
  };
};

const AdminHeader = connect(
  mapStateToProps
  // mapDispatchToProps
)(AdminHeaderContent);

export default withStyles(styles, { withTheme: true })(
  injectIntl(withRouter(AdminHeader))
);
