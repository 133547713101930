import { withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './KioskEnrollmentLayout.styles';
import {
  KioskWelcomeFooter,
  KioskWelcomeHeader,
  KioskEnrollmentMain,
  KioskIdelScreen
} from '..';
import { injectIntl, defineMessages } from 'react-intl';

const messages = defineMessages({
  Enrollment: {
    id: 'Enrollment.Enrollment',
    defineMessages: 'Enrollment'
  }
});

export class KioskEnrollmentLayout extends Component {
  static propTypes = {
    test: PropTypes.string
  };
  state = {
    stepTitle: '',
    pageTitle: this.props && this.props.intl.formatMessage(messages.Enrollment)
  };

  render() {
    const { classes } = this.props;
    var customFooterBottom;
    if (window.screen.width >= 1440) {
      customFooterBottom = '-25%';
    } else {
      customFooterBottom = '0%';
    }

    return (
      <div className={classNames(classes.container)}>
        <KioskWelcomeHeader />
        <KioskEnrollmentMain />
        <KioskWelcomeFooter customFooterBottom={customFooterBottom} />
        <KioskIdelScreen />
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  injectIntl(KioskEnrollmentLayout)
);
