const styles = (theme) => {
  return {
    container: {
      display: 'grid',
      width: '100vw',
      height: '100vh',
      gridGap: '1rem',
      // gridTemplateColumns: 'auto',
      gridTemplateRows: '19% auto 3em',
      justifyContent: 'center',
      //alignItems:'center',
      // backgroundImage: 'linear-gradient(135deg, white, #dedede)',
      alignItems: 'normal',
      paddingTop: '105px',
      backgroundColor: 'white'
    },
    '@media screen and (max-width: 1080px)': {
      container: {
        display: 'grid',
        width: '100vw',
        height: '100vh',
        gridGap: '1rem',
        gridTemplateColumns: 'auto',
        gridTemplateRows: '10% auto 3em',
        // backgroundImage: 'linear-gradient(135deg, white, #dedede)',
        alignItems: 'normal',
        paddingTop: '105px',
        backgroundColor: 'white'
      }
    },
    mainContainer: {
      display: 'grid',
      // minHeight: '680px',
      textAlign: 'center',
      gridAutoFlow: 'dense',
      // gridTemplateRows: '200px 2.25em 2em auto',
      justifyContent: 'center',
      marginTop: '19%'
    },
    appLogoContainer: {
      // display: 'grid',
      // gridTemplateColumns: 'auto',
      // height: '80px',
      // alignItems: 'flex-end',
      // justifyContent: 'center'
      marginTop: '-50px'
    },
    appLogo: {
      width: '200px'
    },
    appName: {
      fontSize: '2.25em',
      fontWeight: '100',
      color: '#005d67'
    },
    title: {
      fontSize: '1.5em',
      fontWeight: '100',
      color: '#005d67'
    },
    form: {
      //width: '500px',
      width: '550px',
      alignItems: 'center',
      justifyContent: 'center'
    },
    linkContainer: {
      marginTop: '0.75em',
      fontSize: '0.75em'
    },
    buttonLink: {
      marginTop: '5px',
      background: 'none!important',
      color: 'inherit',
      border: 'none',
      padding: '0!important',
      font: 'inherit',
      /*border is optional*/
      borderBottom: '1px solid #444',
      cursor: 'pointer'
    },
    backButton: {
      marginTop: '-12em',
      fontSize: '20px',
      width: '45%',
      height: '50px',
      lineHeight: '20px',
      background: '#fff',
      color: '#aaa',
      border: '1px solid  #2F5597',
      borderRadius: '10px',
      '&:hover': {
        backgroundColor: '#fff',
        color: '#aaa'
      }
    },
    buttonContainer: {
      flexGrow: '1',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      // justifyContent: 'space-between',
      justifyContent: 'space-evenly',
      position: 'absolute',
      width: '90%',
      bottom: '3%',
      left: '5%'
    },
    Scantitle: {
      // fontSize: '1.46em',
      // fontWeight: '500',
      // color: '#82C2AC'
      fontSize: '1em',
      color: ' #2F5597'
    },
    scanTitleError: {
      fontSize: '1em',
      color: 'red'
    },
    spinner: {
      position: 'absolute',
      background: 'rgba(255,255,255,.4)',
      width: '100%',
      height: '100%',
      zIndex: '9999',
      top: 0,
      left: 0
    },
    spinnerImage: {
      top: 'calc(50% - 32.5px)',
      left: 'calc(50% - 65px)',
      position: 'absolute',
      width: '160px',
      userSelect: 'none'
    },
    ButtonAlign: {
      justifyContent: 'center'
    },
    disabledbackButton: {
      marginTop: '3em',
      width: '40%',
      height: '50px',
      fontSize: '20px',
      // background:'#fff',
      // color:'#aaa',
      border: '1px solid  #2F5597',
      borderRadius: '10px',
      lineHeight: '20px',
      backgroundColor: 'rgba(0, 0, 0, 0.12)'
    },
    '@media screen and (min-width: 380px) and (max-width: 1440px) and (orientation:landscape)':
      {
        appLogoContainer: {
          display: 'grid',
          gridTemplateColumns: 'auto',
          height: '100px',
          alignItems: 'flex-end',
          justifyContent: 'center',
          marginTop: '-50px'
        },
        appLogo: {
          width: '200px'
        },
        mainContainer: {
          display: 'grid',
          minHeight: '800px',
          textAlign: 'center',
          gridAutoFlow: 'dense',
          gridTemplateRows: '140px 2.25em 2em auto',
          justifyContent: 'center'
        }
      },
    oktaLoginContainer: {
      position: 'absolute',
      bottom: '230px',
      left: '30%',
      right: '30%'
    },
    oktaLoginButton: {
      background: '#007dc1',
      height: '54px',
      width: '230px',
      fontWeight: 'bolder',
      fontSize: 'medium',
      borderRadius: '10px',
      '&:hover': {
        background: '#007dc1'
      }
    },
    oktaCircle: {
      border: '6px solid white',
      borderRadius: '50%',
      height: '25px',
      width: '25px',
      marginLeft: '-10px',
      marginRight: '10px'
    },
    simpleKeyboard: {
      position: 'absolute',
      zIndex: 999,
      width: '90vw'
    },
    otpContainer: {
      width: '40vh',
      '& .formContainer': {
        display: 'grid',
        gridGap: '1em',
        alignItems: 'center'
      },
      '& .container': {
        margin: '10px 0 0 0'
      }
    },
    primaryButton: {
      margin: '1em',
      color: '#fff',
      fontSize: '20px',
      width: '40%',
      background: ' #2F5597',
      border: '1px solid  #2F5597',
      borderRadius: '10px',
      '&:hover': {
        backgroundColor: ' #2F5597'
      }
    },
    disabledPrimaryButton: {
      margin: '1em',
      fontSize: '20px',
      width: '40%',
      // height: '80px',
      border: '1px solid  #2F5597',
      borderRadius: '10px'
    },
    textField: {
      fontSize: '2em'
    },
    otpButtonContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: '10px',
      marginLeft: '4%',
      marginRight: '4%'
    },
    clearButton: {
      justifyContent: 'left',
      textAlign: 'left'
    },
    resentOtpButton: {
      justifyContent: 'right',
      textAlign: 'right'
    },
    pickupCodeInput: {
      width: '12%',
      fontSize: '50px',
      margin: '0px 10px',
      border: '3px solid #305598',
      borderRadius: '8px',
      textAlign: 'center',
      outline: 'none',
      textTransform: 'uppercase'
    }
  };
};

export default styles;
