const styles = (theme) => {
  return {
    container: {
      display: 'grid',
      width: '100vw',
      height: '100vh',
      gridGap: '1rem',
      gridTemplateColumns: 'auto',
      gridTemplateRows: '12% auto 3em',
      backgroundImage: 'linear-gradient(135deg, white, #dedede)'
    }
  };
};

export default styles;
