import React, { memo } from 'react';

const MainLogo = memo(({ box, height = '65px' }) => {
  const brand = JSON.parse(localStorage.getItem('brands'));
  const logo =
    (brand && brand.length > 0 && brand[0].brand_logo_url) ||
    (box && box.account && box.account.brand && box.account.brand.logo_url);
  return logo ? (
    <img alt="logo" height={height} src={logo} />
  ) : (
    <div style={{ height }} />
  );
});

export default MainLogo;
