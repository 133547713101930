const styles = (theme) => {
  return {
    container: {
      width: '100vw',
      height: '100vh',
      gridGap: '1rem',
      // backgroundImage: 'linear-gradient(135deg, white, #dedede)',
      justifyContent: 'center',
      backgroundColor: 'white'
    }
  };
};

export default styles;
