import React, { useRef, useEffect } from 'react';
import Webcam from 'react-webcam';
import { REDUCE_IMAGE_SIZE } from '../../helpers';
import { FormattedMessage } from 'react-intl';
// import { store } from '../../helpers/redux';
import { writeKioskLog, writePickupLogsToFile } from '../Common/SyncAPIs';

const AdminImgCapture = () => {
  // const box = store.getState()._root.entries[3][1].box;
  const videoConstraints = {
    width: 1280,
    height: 720,
    facingMode: 'user'
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      capture();
    }, 3000);
    return () => clearTimeout(timer);
  }, []);

  const webcamRef = useRef(null);
  const capture = async () => {
    const imageSrc = await REDUCE_IMAGE_SIZE(webcamRef.current.getScreenshot());
    const base64Picture = imageSrc.replace(/^data:image\/\w+;base64,/, '');
    const boxId = localStorage.getItem('boxId');
    if (boxId) {
      fetch(process.env.REACT_APP_BASEURL + `getboxid/${boxId}`, {
        method: 'GET',
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
          Authorization:
            'Bearer ' +
            JSON.parse(sessionStorage.getItem('user')).signInUserSession.idToken
              .jwtToken
        }
      })
        .then((res) => res.json())
        .then((data) => {
          const parent_account_id = localStorage.getItem('parent_account_id');
          setTimeout(async () => {
            const audit_obj = {
              action_name: 'Login',
              picture_url: base64Picture,
              user_id: JSON.parse(sessionStorage.getItem('user')).username,
              description: `User logged in Kiosk.`,
              parent_account_id: parent_account_id,
              box_id: data.box_id.id
            };

            const audit_log = await writeKioskLog(audit_obj);
            const pharmacist = JSON.parse(sessionStorage.getItem('user'))
              .signInUserSession.idToken.payload.email;
            if (audit_log && audit_log.picture_url) {
              localStorage.setItem('AdminImg', audit_log.picture_url);
              writePickupLogsToFile({
                message: `Kiosk Admin Panel Screen : Pharmacist ${pharmacist}'s image is captured.`
              });
            } else {
              writePickupLogsToFile({
                message: `Kiosk Admin Panel Screen : Pharmacist ${pharmacist}'s image is not captured.`
              });
            }
          }, 1000);
        })
        .catch((e) => console.error(e));
    }
  };

  return (
    <>
      <span style={{ color: ' #2F5597', fontSize: '2.5em' }}>
        <FormattedMessage
          id="Verify.SmileTitle"
          defaultMessage="Smile, your picture is being taken!"
        />
      </span>
      <Webcam
        id="vid"
        audio={false}
        ref={webcamRef}
        screenshotFormat="image/png"
        width={550}
        videoConstraints={videoConstraints}
      />
    </>
  );
};

export default AdminImgCapture;
