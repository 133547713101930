const criticalErrors = require('./criticalErrors').default;

// A queue for managing ongoing operations
let operationQueue = Promise.resolve();
let queueLength = 0; // Track the length of the queue

export function customFetch(
  url,
  options,
  funcName,
  operation,
  kiosk_box_id,
  isPreventive,
  allowConcurrent = false // New flag for concurrent operations
) {
  return new Promise((resolve, reject) => {
    console.log('Fetching:', { url, options, funcName, kiosk_box_id });
    console.log('Fetch Parameters:', { isPreventive, allowConcurrent });

    const isSafeMode = localStorage.getItem('safemode');
    const isUserLogin = localStorage.getItem('user-login');

    console.log('Safe Mode:', isSafeMode, 'User Login:', isUserLogin);

    // Operation executor, wraps fetch in a promise queue
    const operationExecutor = () => {
      return new Promise((resolveInner, rejectInner) => {
        if (
          [false, 'false'].includes(isUserLogin) &&
          [true, 'true'].includes(isSafeMode)
        ) {
          console.log(`Operation ${funcName} blocked due to safe mode.`);
          let objRej = {
            safeModeResponse: {
              Command: funcName,
              Status: 'Error',
              StatusMessage: 'Command blocked due to safe mode.',
              NextCommand: 'None'
            }
          };
          const wrappedResponse = {
            json: async () => objRej
          };
          rejectInner(wrappedResponse);
        }
        if (
          [false, 'false'].includes(isSafeMode) ||
          [true, 'true'].includes(isUserLogin)
        ) {
          console.log('Fetching from URL:', url, options);
          fetch(url, options)
            .then((response) => {
              try {
                return response.json();
              } catch (error) {
                return null;
              }
            })
            .then(async (response) => {
              let theResponse = null;
              if (['reset-festo', 'load-bin'].includes(funcName)) {
                theResponse = response.data;
              } else {
                theResponse = response.data[Object.keys(response.data)[0]];
              }
              console.log(
                `Response from ${funcName} was ${JSON.stringify(
                  response,
                  null,
                  2
                )}`
              );
              if (theResponse.hasOwnProperty('Status')) {
                if (
                  ['Ok', 'ok', 'Busy', 'busy'].includes(theResponse.Status) ||
                  isPreventive
                ) {
                  if (
                    ['Ok', 'ok', 'Busy', 'busy'].includes(theResponse.Status) &&
                    isPreventive
                  ) {
                    const payload = {
                      kiosk_box_id,
                      error_message: `Preventive operation success was unexpected.`,
                      operation: operation,
                      isCritical: false
                    };
                    await reportError(payload);
                  }

                  const wrappedResponse = {
                    json: async () => response
                  };
                  console.log(`${funcName} operation completed successfully.`);
                  resolveInner(wrappedResponse);
                } else {
                  const _isCritical = isCritical(theResponse.StatusMessage);
                  if (_isCritical) {
                    // checking report only flag explicitly the flag.
                    const isReportOnly = localStorage.getItem('report-only');
                    if (
                      [
                        undefined,
                        'undefined',
                        null,
                        'null',
                        '',
                        false,
                        'false'
                      ].includes(isReportOnly)
                    ) {
                      // Put under maintenance.
                      if ([false, 'false'].includes(isUserLogin)) {
                        localStorage.setItem('safemode', true);
                        window.location.href = '/safe-mode';
                      } else if ([false, 'false'].includes(isSafeMode)) {
                        localStorage.setItem('safemode', true);
                        window.location.href = '/safe-mode';
                      }
                    }
                    // Report error.
                    const payload = {
                      kiosk_box_id,
                      error_message: theResponse.StatusMessage,
                      operation: operation,
                      isCritical: _isCritical
                    };
                    await reportError(payload);
                    const wrappedResponse = {
                      json: async () => response
                    };
                    rejectInner(wrappedResponse);
                  } else {
                    // Report error on each API call.
                    const payload = {
                      kiosk_box_id,
                      error_message: theResponse.StatusMessage,
                      operation: operation,
                      isCritical: false
                    };
                    await reportError(payload);
                    const wrappedResponse = {
                      json: async () => response
                    };
                    console.log(
                      `${funcName} operation completed successfully.`
                    );
                    resolveInner(wrappedResponse);
                  }
                }
              } else {
                const payload = {
                  kiosk_box_id,
                  error_message: `Unknown error while sending command.`,
                  operation: operation,
                  isCritical: false
                };
                await reportError(payload);
                const wrappedResponse = {
                  json: async () => response
                };
                console.warn(
                  `${funcName} operation failed:`,
                  theResponse.StatusMessage
                );
                rejectInner(wrappedResponse);
              }
            })
            .catch(async (error) => {
              const payload = {
                kiosk_box_id,
                error_message: error.message,
                operation: operation,
                isCritical: false
              };
              await reportError(payload);
              const wrappedResponse = {
                json: async () => error
              };
              console.warn(`${funcName} operation failed:`, error.message);
              rejectInner(wrappedResponse);
            });
        }
      });
    };

    if (allowConcurrent) {
      console.log(
        `Concurrent execution for ${funcName} allowed:`,
        allowConcurrent
      );
      try {
        operationExecutor().then(resolve).catch(reject);
      } catch (error) {
        console.error(error);
        reject(error);
      }
    } else {
      if (queueLength > 0) {
        console.log(
          `Adding ${funcName} to the operation queue for sequential execution. Queue length: ${queueLength}`
        );
      } else {
        console.log(`Queue is empty, ${funcName} will be executed directly.`);
      }

      // Increment queue length
      queueLength++;
      console.log(`Current queue length: ${queueLength}`);

      // Chain the operation to ensure it waits for previous ones to finish
      operationQueue = operationQueue
        .then(() => {
          return operationExecutor()
            .then((result) => {
              // Decrement queue length when operation completes
              queueLength--;
              console.log(
                `Operation ${funcName} completed. Current queue length: ${queueLength}`
              );
              resolve(result);
            })
            .catch((error) => {
              // Decrement queue length when operation fails
              queueLength--;
              console.log(
                `Operation ${funcName} failed. Current queue length: ${queueLength}`
              );
              reject(error);
            });
        })
        .catch((error) => {
          // In case of errors in chaining, decrement queue length
          queueLength--;
          console.log(
            `Error in chained operation ${funcName}. Current queue length: ${queueLength}`
          );
          reject(error);
        });
    }
  });
}

const isCritical = (message) => {
  return criticalErrors.map((x) => message === x.message).length > 0
    ? true
    : false;
};

const reportError = async (payload) => {
  const token = localStorage.getItem('authTokens');
  const actualToken = atob(token);
  fetch(process.env.REACT_APP_BASEURL + 'fastlane/report', {
    method: 'POST',
    withCredentials: true,
    headers: {
      Authorization: 'Bearer ' + actualToken
    },
    body: JSON.stringify(payload)
  })
    .then((response) => {
      try {
        return response.text();
      } catch (error) {
        return null;
      }
    })
    .then((uData) => {
      console.log(`Error reported successfully!`);
    })
    .catch((error) => {
      console.log('Error while sending report ', JSON.stringify(error));
    });
};
