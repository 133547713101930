import React, { useRef } from 'react';
import Webcam from 'react-webcam';

const AdminVideo = () => {
  const webcamRef = useRef(null);
  const videoConstraints = {
    width: 150,
    height: 100,
    facingMode: 'user'
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-end',
        zIndex: 1,
        position: 'absolute',
        width: '100%',
        padding: '12px',
        borderRadius: '10px'
      }}
    >
      <Webcam
        id="vid"
        audio={false}
        ref={webcamRef}
        screenshotFormat="image/png"
        videoConstraints={videoConstraints}
      />
    </div>
  );
};

export default AdminVideo;
