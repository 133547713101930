const DiffSecondsToMinutes = (startDate, endDate) => {
  let diffInMs = Math.abs(endDate - startDate);
  diffInMs = diffInMs / 1000;
  if (!Number.isInteger(diffInMs)) {
    diffInMs = parseFloat(diffInMs).toFixed(0);
  } else {
    diffInMs = parseInt(diffInMs);
  }
  return diffInMs;
};

export { DiffSecondsToMinutes };
