// import { Map } from 'immutable';
import {
  REQUEST_PICKUPCODE,
  REQUEST_PICKUPCODE_SUCCESS,
  REQUEST_PICKUPCODE_ERROR,
  REQUEST_PICKUPCODE_LOADING
} from '../components/KioskPickup/KioskPickupActions';
import { initialSettings } from './data';

const kiosk = (state = initialSettings, action) => {
  switch (action.type) {
    case 'UPDATE_DATA':
      return {
        ...state,
        box: action.box.box
      };
    case 'INITIAL_DATA':
      return {
        box: action.box.box,
        requestPayload: []
      };
    // case 'UPDATE_POLLING_STATS':
    //   return Map({
    //     ...state,
    //     pollingStats: action.stats
    //   });
    case 'ADD_URL':
      return {
        ...state,
        requestPayload: [action.payload]
      };
    case REQUEST_PICKUPCODE:
      return {};
    case REQUEST_PICKUPCODE_SUCCESS:
      return {};
    case REQUEST_PICKUPCODE_ERROR:
      return {};
    case REQUEST_PICKUPCODE_LOADING:
      return {};
    case 'KIOSK/ENROLL_NEW_PATIENT':
      return {
        state
        // patients: [action.payload]
      };
    case 'KIOSK/CONFIRM_USER':
      return {
        ...state
        // state: {
        //   ...state
        // }
      };
    case 'KIOSK_CONFIRM_USER':
      let old = [];
      old.push(action.payload);
      return Object.assign({}, state, {
        requestPayload: old
      });
    case 'KIOSK_PATIENT_DETAILS':
      let obj = [];
      obj = action.payload;
      return Object.assign({}, state, {
        localPatientObj: obj
      });
    case 'KIOSK_DOOR_COLLECT_SEND_EMAIL':
      let old1 = [];
      if (state && state.requestPayload) {
        old1.push(...state.requestPayload);
      }
      old1.push(action.payload);
      return Object.assign({}, state, {
        requestPayload: old1
      });
    case 'KIOSK_DOOR_COLLECT_UpdateStatusOfRx':
      let old2 = [];
      if (state && state.requestPayload) {
        old2.push(...state.requestPayload);
      }
      old2.push(action.payload);
      return Object.assign({}, state, {
        requestPayload: old2
      });
    case 'KIOSK_DOOR_COLLECT_EVENT':
      let old3 = [];
      if (state && state.requestPayload) {
        old3.push(...state.requestPayload);
      }
      old3.push(action.payload);
      return Object.assign({}, state, {
        requestPayload: old3
      });
    case 'KIOSK_MULTIPLE_PICKUP':
      return {
        ...state,
        multiplePickup: [action.payload]
      };
    case 'MULTIPLE_PICKUP':
      return {
        ...state,
        multipleOrders: action.payload.data
      };
    case 'KIOSK_PAYMENT_STARTED':
      obj = action.payload;
      return Object.assign({}, state, {
        paymentConfig: obj
      });
    default:
      return state;
  }
};

export default kiosk;
