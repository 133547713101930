const styles = (theme) => {
  return {
    container: {
      display: 'grid',
      width: '100vw',
      height: '100vh',
      gridGap: '1rem',
      gridTemplateColumns: 'auto',
      gridTemplateRows: '100% auto 3em',
      // backgroundImage: 'linear-gradient(135deg, white, #dedede)',
      justifyContent: 'center',
      //alignItems: 'center',
      alignItems: 'normal',
      paddingTop: '105px',
      backgroundColor: 'white'
    },
    '@media screen and (min-width: 1440px) and (orientation:landscape)': {
      container: {
        display: 'grid',
        width: '100vw',
        height: '128vh',
        gridGap: '1rem',
        gridTemplateColumns: 'auto',
        gridTemplateRows: '19% auto 3em',
        backgroundImage: 'linear-gradient(135deg, white, #dedede)',
        justifyContent: 'center',
        //alignItems: 'center',
        alignItems: 'normal',
        paddingTop: '105px'
      }
    }
  };
};

export default styles;
