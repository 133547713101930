export default {
  ASSIGNED: 'ASSIGNED',
  STOCKED: 'STOCKED',
  PICKED_UP: 'PICKED_UP',
  REMOVE_RX: 'REMOVE_RX',
  VERFIY_RX: 'VERFIY_RX',
  RX_HOLD: 'RX_HOLD',
  OPEN_DOOR: 'OPEN DOOR',
  LOG_IN: 'LOG_IN',
  LOG_OUT: 'LOG_OUT'
};
