export default {
  A1: 'E1',
  A2: 'E2',
  A3: 'E3',
  A4: 'E4',
  A5: 'E5',
  A6: 'E6',
  A7: 'E7',
  A8: 'E8',
  A9: 'E9',
  A10: 'E10',
  A11: 'E11',
  A12: 'E12',
  B8: 'D8',
  B9: 'D9',
  B10: 'D10',
  B11: 'D11',
  B12: 'D12',
  C8: 'C8',
  C9: 'C9',
  C10: 'C10',
  C11: 'C11',
  C12: 'C12',
  D1: 'B1',
  D2: 'B2',
  D3: 'B3',
  D4: 'B4',
  D5: 'B5',
  D6: 'B6',
  D7: 'B7',
  D8: 'B8',
  D9: 'B9',
  D10: 'B10',
  D11: 'B11',
  D12: 'B12',
  E1: 'A1',
  E2: 'A2',
  E3: 'A3',
  E4: 'A4',
  E5: 'A5',
  E6: 'A6',
  E7: 'A7',
  E8: 'A8',
  E9: 'A9',
  E10: 'A10',
  E11: 'A11',
  E12: 'A12'
};
