import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router';
import styles from './App.styles';
import { IntlProvider } from 'react-intl';
import axios from 'axios';
import translations from './i18n/locales';
import KioskLayout from './components/Kiosk/KioskLayout';
import KioskPickupLayout from './components/KioskPickup/KioskPickupLayout';
import KioskVerifyMeLayout from './components/KioskVerifyMe/KioskVerifyMeLayout';
import KioskConfimPickupLayout from './components/KioskConfimPickup/KioskConfimPickupLayout';
import KioskSignatureMain from './components/KioskSignature/KioskSignatureMain';
import KioskConsultLayout from './components/KioskConsult/KioskConsultLayout';
import KioskFeedbackLayout from './components/KioskFeedback/KioskFeedbackLayout';
import KioskDoorLayout from './components/KioskDoor/KioskDoorLayout';
import KioskCollectIDLayout from './components/KioskCollenctID/KioskCollectIDLayout';
import KioskEnrollmentLayout from './components/KioskEnrollment/KioskEnrollmentLayout';
import AdminLoginLayout from './components/AdminLogin/AdminLoginLayout';
import SecureBadgeLoginForm from './components/SecureBadgeLogin/SecureBadgeLoginForm';
import OktaLogin from './components/OktaLogin/OktaLoginForm';
import PatientLoginLayout from './components/PatientLogin/PatientLoginLayout';
import AdminPanelLayout from './components/AdminPanel/AdminPanelLayout';
import AdminPanelStockLayout from './components/AdminPanelStock/AdminPanelStockLayout';
import AdminKioskVerifyLayout from './components/KioskVerify/AdminKioskVerifyLayout';
import AdminMaintenanceLayout from './components/AdminMaintenance/AdminMaintenanceLayout';
import AdminSettingLayout from './components/AdminSetting/AdminSettingLayout';
import LoginLayout from './components/Login/LoginLayout';
import KioskOptHrsMain from './components/KioskOptHrs/KioskOptHrsMain';
import { initialSettings } from './reducers/data';
import KioskWelcomeScreenLayout from './components/KioskWelcomeScreen/KioskWelcomeScreenLayout';
import SimpleStartKioskWelcomeScreenLayout from './components/KioskSimpleStartWelcomeScreen/SimpleStartKioskWelcomeScreenLayout';

import KioskCheckinLayout from './components/KioskCheckin/KioskCheckinLayout';
import KioskNewPatientEnrollmentLayout from './components/KioskNewPateint/KioskNewPatientEnrollmentLayout';
import KioskNewPatientSearchResultLayout from './components/KioskNewPatientSearchResult/KioskNewPatientSearchResultLayout';
import KioskCurrentPatientSearchLayout from './components/KioskCurrentPatient/KioskCurrentPatientSearchLayout';
import KioskCurrentPatientSearchResultLayout from './components/KioskCurrentPatientSearchResult/KioskCurrentPatientSearchResultLayout';
import KioskPatientLoginLayout from './components/KioskPatientLogin/KioskPatientLoginLayout';
import KioskPatientViewRxLayout from './components/KioskPatientViewRx/KioskPatientViewRxLayout';
import KioskOrderItemLayout from './components/KioskOrderItem/KioskOrderItemLayout';
import KioskHealthandinformationLayout from './components/KioskHealthandinformation/KioskHealthandinformationLayout';
import KioskRefillRxLayout from './components/KioskRefillRx/KioskRefillRxLayout';
import KioskShopNowLayout from './components/KioskShopNow/KioskShopNowLayout';
import KioskProductListLayout from './components/KioskProductList/KioskProductListLayout';
import KioskGetKitLayout from './components/KioskGetKit/KioskGetKitLayout';
import KioskQuizPassLayout from './components/KioskQuizPass/KioskQuizPassLayout';
import KioskQuizTestLayout from './components/KioskQuizTest//KioskQuizTestLayout';
import KioskQuizEnrollmentLayout from './components/KioskQuizEnrollment/KioskQuizEnrollmentLayout';
import KioskCheckConfirmuserLayout from './components/KioskCheckConfirmuser/KioskCheckConfirmuserLayout';
import KioskInformationMain from './components/KioskInformation/KioskInformationMain';
import ContactlessPickupLayout from './components/ContactlessCapturePickup/ContactlessPickupLayout';
import KioskeSeek from './components/KioskeSeek/KioskeSeek';
import {
  // startPolling,
  // stopPolling,
  // updatePollingStats,
  updateData,
  initialData
} from './api/common/actions';
import AdminRemoveLayout from './components/AdminRemove/AdminRemoveLayout';
import KioskForgotPickUPCode from './components/KioskForgotPickUPCode/KioskForgotPUCodeLayout';

//In wall Rear Stock

import RearStockAdminLoginLayout from './components/InWallRearStock/AdminLogin/AdminLoginLayout';
import RearStockAdminPanelLayout from './components/InWallRearStock/AdminPanel/AdminPanelLayout';
import RearStockAdminPanelStockLayout from './components/InWallRearStock/AdminPanelStock/AdminPanelStockLayout';
import RearStockAdminMaintenanceLayout from './components/InWallRearStock/AdminMaintenance/AdminMaintenanceLayout';
import RearStockAdminSettingLayout from './components/InWallRearStock/AdminSetting/AdminSettingLayout';
import RearStockAdminRemoveLayout from './components/InWallRearStock/AdminRemove/AdminRemoveLayout';
import RearStockAdminKioskVerifyLayout from './components/InWallRearStock/KioskVerify/AdminKioskVerifyLayout';
import {
  writeInitSettings,
  readSettings,
  openBin,
  WriteInitPickupInfo,
  openMiniBinsForceFully,
  openBinCommand
} from './helpers/board';
import ErrorBoundarys from './components/ErrorBoundry/ErrorBoundary';
import { ConfigureAmplify } from './helpers/amplify';
import { io } from 'socket.io-client';
import ExpressUI from './ExpressUI';
import RetailUI from './RetailUI';
import { syncMapping } from './components/Common/mini22_bins_mapping';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import safemode from './components/ErrorBoundry/safemode';
const Cryptr = require('cryptr');
const _cipher = process.env.REACT_APP_SESSION_CIPHER;
const _cryptr = new Cryptr(_cipher);
const _cipherQueue = process.env.REACT_APP_QUEUE_CIPHER_KEY;
const _cryptrQueue = new Cryptr(_cipherQueue);
const {
  writePickupLogsToFile,
  sendAlarmMessageForOutOfSession
} = require('./components/Common/SyncAPIs');
const BOX_TYPE = require('./components/Common/box_type_enum').default;
const { customFetch } = require('./helpers/customFetch');

let FileObje = {
  boxId: '',
  dynamicDataSource: false,
  rearStock: false,
  IsActive: true,
  pickupType: '1',
  steps: [
    {
      step: 0,
      name: 'Touch to Start',
      value: true,
      prev: '',
      next: '',
      btnClick: 0,
      stepTitle: 0
    },
    {
      step: 1,
      name: 'Pick up Code Enter',
      value: true,
      prev: '/',
      next: '/KioskVerify',
      btnClick: 0,
      stepTitle: 1
    },
    {
      step: 2,
      name: 'DOB verification',
      value: true,
      prev: '/KioskPickupCode',
      next: '/KioskConfirmPickup',
      btnClick: 0,
      stepTitle: 2
    },
    {
      step: 3,
      name: 'Confirm Pick up items',
      value: true,
      prev: '/KioskVerify',
      next: '/KioskConsult',
      btnClick: 0,
      stepTitle: 3
    },
    {
      step: 4,
      name: 'Consult',
      value: true,
      prev: '/KioskConfirmPickup',
      next: '/KioskSignature',
      btnClick: 0,
      stepTitle: 4
    },
    {
      step: 5,
      name: 'Signature',
      value: true,
      prev: '/KioskConsult',
      next: '/KioskCheckConfirmuser', //Modified By Belani Jaimin
      btnClick: 0,
      stepTitle: 5
    },
    {
      step: 6,
      name: 'DL Capture',
      value: true,
      prev: '/KioskSignature',
      next: '/KioskCollectMedicines',
      btnClick: 0,
      stepTitle: 6
    },
    {
      step: 7,
      name: 'Collect your items',
      value: true,
      prev: '',
      next: '/KioskFeedback',
      btnClick: 0,
      stepTitle: 7
    },
    {
      step: 8,
      name: 'Survey',
      value: true,
      prev: '',
      next: '/',
      btnClick: 0,
      stepTitle: 8
    },
    {
      step: 9,
      name: 'Confirm Pickup Payment',
      value: true,
      prev: '/KioskVerify',
      next: '/KioskConsult',
      btnClick: 0,
      stepTitle: 9
    },
    {
      step: 10,
      name: 'View Current Rx',
      value: true,
      prev: '/KioskPatientViewRx',
      next: '/KioskConsult',
      btnClick: 0,
      stepTitle: 0
    }
  ],
  posWaitDuration: 3000,
  IsNDCVideoAllow: false,
  /*
    Added By : Belani Jaimin
    Description: Send payment request to ingenico device
    Note: The default URL is base url of kiosk-wrapper code configuration.
  */
  paymentBaseUrl: '',
  isHWCommuniocationEnabled: true,
  EnableLifeboat: false
};

let initialPickupObject = {
  message: 'Initialize a file for logs',
  timestamp: new Date()
};

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      doesFLPTransactionCancelled: false,
      statusText: '',
      livePorts: []
    };
    this.socket = window.consultSocket;
    !this.socket && this.initSocket();
    this.box = null;
  }

  componentWillMount() {
    if (window.electron) {
      writeInitSettings(FileObje);
      setTimeout(() => {
        WriteInitPickupInfo(initialPickupObject);
      });
    }
    if (localStorage.getItem('steps') === null) {
      localStorage.setItem('steps', JSON.stringify(FileObje.steps));
    }
    if (localStorage.getItem('paymentBaseUrl') === null) {
      localStorage.setItem('paymentBaseUrl', FileObje.paymentBaseUrl);
    }
    let sourceEnabled;
    if (window.electron) {
      const fileData = JSON.parse(readSettings());
      sourceEnabled = fileData ? fileData.dynamicDataSource : false;
    } else {
      sourceEnabled = JSON.parse(localStorage.getItem('dynamicDataSource'));
    }
    setImmediate(async () => {
      if ([true, 'true'].includes(sourceEnabled)) {
        await this.getBoxesData();
        if (window.getBoxDataInterval) {
          clearInterval(window.getBoxDataInterval);
        }
        window.getBoxDataInterval = setInterval(async () => {
          await this.getBoxesData();
        }, process.env.REACT_APP_KIOSK_HEALTH_INTERVAL);
      } else {
        const isIntervalSet = window.getBoxDataInterval;
        if (
          ![undefined, 'undefined', null, 'null', ''].includes(isIntervalSet)
        ) {
          Object.entries(window.getBoxDataInterval).forEach((x) =>
            clearInterval(x[1])
          );
        }
      }
    });

    // this.startPolling();
    let currentUrl = window.location.href.split('/');
    if (!currentUrl.includes('retail')) {
      this.checkKioskHealth();
    }
    if (window.electron) {
      window.getBoardData = (args) => {
        if (args && args.data && args.data.length > 0) {
          const _checkIfSessionIsActiveOrNot =
            localStorage.getItem('ActiveSession');
          const decryptedSessionObject = _cryptr.decrypt(
            _checkIfSessionIsActiveOrNot
          );
          const parseddecryptedSessionObject = JSON.parse(
            decryptedSessionObject
          );
          if (
            (parseddecryptedSessionObject &&
              parseddecryptedSessionObject._session_active === false) ||
            (parseddecryptedSessionObject &&
              parseddecryptedSessionObject._session_active === 'false')
          ) {
            sendAlarmMessageForOutOfSession(args);
          }
        }
      };
    }
  }

  componentDidMount() {
    ConfigureAmplify();
    const boxType = localStorage.getItem('boxType');
    if (![undefined, 'undefined', null, 'null', ''].includes(boxType)) {
      if (boxType === BOX_TYPE.FASTLANE && window && window.electron) {
        this.initializeFastlane();
        localStorage.setItem('should-call-pbc', true);
        localStorage.setItem('report-only', true);
      }
    }
  }

  initSocket = () => {
    const _token = localStorage.getItem('authTokens');
    const decodedToken = _token && atob(_token);
    if (decodedToken && decodedToken.length > 21) {
      /**
       ** Socket Initialization **
       */

      this.socket = io(process.env.REACT_APP_VIDEO_SOCKET_BASEURL, {
        transports: ['websocket'],
        auth: {
          token: `Bearer ${decodedToken}`
        },
        secure: true
      });
      window.consultSocket = this.socket;
      console.log(
        '@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@     Socket init from App.js      @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@'
      );
      this.socket &&
        localStorage.getItem('currentcode') &&
        this.socket.emit('KioskSubscribe', {
          pucode: localStorage.getItem('currentcode').toString(),
          message: 'update Status For Connected Twilio'
        });

      this.socket &&
        localStorage.getItem('boxId') &&
        this.socket.emit('ConfigureBoxId', {
          box_id: localStorage.getItem('boxId')
        });

      /**
       ** Internal Socket Event **
       */

      this.socket &&
        this.socket.on('connect', () => {
          console.log('Socket connection established from KIOSK!!!!');
          localStorage.getItem('dummy_kiosk_box_id') &&
            this.socket.emit('ConfigureBoxId', {
              box_id: localStorage.getItem('dummy_kiosk_box_id')
            });
          console.log(
            '$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$',
            localStorage.getItem('dummy_kiosk_box_id')
          );
        });
      this.socket &&
        this.socket.on('connect_error', (error) => {
          console.log('Connect Error: ', error);
          if (
            error.message === 'TokenExpiredError' ||
            error.message === 'JsonWebTokenError' ||
            error.message === 'NoTokenFound'
          ) {
            console.warn('Token is Not Valid! Please setup Box again.');
          }
        });
      this.socket &&
        this.socket.on('connect_failed', (error) => {
          console.log('Connect Failed: ', error);
        });
      this.socket &&
        this.socket.on('error', (error) => {
          console.log('Error: ', error);
        });
      this.socket &&
        this.socket.on('disconnect', (reason) => {
          console.log(
            `Socket Disconnected From Kiosk and Reason is: ${reason}`
          );
        });

      /**
       ** Socket Room Event Starts **
       */

      // this.socket &&
      //   this.socket.on('subscribed', payload => {
      //     console.log(payload);
      //   });

      // this.socket &&
      //   this.socket.on('unsubscribed', payload => {
      //     console.log(payload);
      //   });

      // this.socket &&
      //   this.socket.on('isOrderReleasedFromDeliveryPrescription', payload => {
      //     console.log(payload);
      //     this.getBoxesData();
      //   });

      this.socket &&
        this.socket.on('KioskOpenBin', async (payload) => {
          console.log('$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$', payload);
          const boxDetails = await this.getBoxByKioskId();
          if (boxDetails && boxDetails._box) {
            if (
              boxDetails &&
              boxDetails._box &&
              boxDetails._box.type_id === BOX_TYPE.M_22
            ) {
              const bin_id = syncMapping(payload.bin);
              if (window && window.electron) {
                await this.callOpenBinApi(bin_id);
              } else {
                openMiniBinsForceFully(bin_id);
              }
            } else {
              if (window && window.electron) {
                await this.callOpenBinApi(payload.bin);
              } else {
                openBin(payload.bin, payload.box_id, 'remote');
              }
            }
          } else {
            if (window && window.electron) {
              await this.callOpenBinApi(payload.bin);
            } else {
              openBin(payload.bin, payload.box_id, 'remote');
            }
          }
        });
      this.socket &&
        this.socket.on('BoxIdConfigured', (payload) => {
          console.log('$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$', payload);
        });
    } else {
      console.log(
        '***initSocket*** Not a valid token for initializing socket connection!'
      );
      new Error(
        '***initSocket*** Not a valid token for initializing socket connection!'
      );
    }
  };

  timeoutPromise = (ms, promise) => {
    try {
      return new Promise((resolve, reject) => {
        const timeoutId = setTimeout(() => {
          if ([true, 'true'].includes(localStorage.getItem('isKioskOnline'))) {
            window.dispatchEvent(new Event('offline'));
          }
          return;
        }, ms);
        promise.then(
          (res) => {
            clearTimeout(timeoutId);
            resolve(res);
          },
          (err) => {
            clearTimeout(timeoutId);
            reject(err);
          }
        );
      });
    } catch (error) {
      console.log(error);
    }
  };

  checkKioskHealth = () => {
    setInterval(() => {
      const _securityMode = localStorage.getItem('securityMode');
      const consultIsOn = localStorage.getItem('consultIsOn');
      if (
        [undefined, 'undefined', null, 'null', '', false, 'false'].includes(
          consultIsOn
        )
      ) {
        this.timeoutPromise(
          process.env.REACT_APP_KIOSK_HEALTH_TIMEOUT,
          window && window.electron
            ? fetch(
                `${
                  [0, '0', undefined].includes(_securityMode)
                    ? process.env.REACT_APP_BASE_APP_URL
                    : process.env.REACT_APP_BASE_APP_URL_HTTPS
                }kiosk/check-connection`,
                {
                  method: 'get'
                }
              )
                .then((response) => {
                  try {
                    return response.json();
                  } catch (error) {
                    return null;
                  }
                })
                .then((response) => {
                  if (response && response.res && response.res === true) {
                    window.dispatchEvent(new Event('online'));
                    const isQueueAvailable = localStorage.getItem('queue');
                    if (!isQueueAvailable) {
                      localStorage.setItem('queue', []);
                    } else {
                      this.loopTheQueueAndSync();
                    }
                  } else {
                    window.dispatchEvent(new Event('offline'));
                  }
                })
                .catch((error) => {
                  window.dispatchEvent(new Event('offline'));
                  const isQueueAvailable = localStorage.getItem('queue');
                  if (!isQueueAvailable) {
                    localStorage.setItem('queue', []);
                  }
                  const securityMode = localStorage.getItem('securityMode');
                  if (
                    [undefined, 'undefined', null, 'null', ''].includes(
                      securityMode
                    )
                  ) {
                    localStorage.setItem('securityMode', '0');
                  }
                })
            : fetch(process.env.REACT_APP_BASEURL + 'health/healthz', {
                method: 'get'
              })
                .then((response) => {
                  window.dispatchEvent(new Event('online'));
                  const isQueueAvailable = localStorage.getItem('queue');
                  if (!isQueueAvailable) {
                    localStorage.setItem('queue', []);
                  } else {
                    this.loopTheQueueAndSync();
                  }
                })
                .catch((error) => {
                  window.dispatchEvent(new Event('offline'));
                  const isQueueAvailable = localStorage.getItem('queue');
                  if (!isQueueAvailable) {
                    localStorage.setItem('queue', []);
                  }
                })
        );
      }
    }, process.env.REACT_APP_KIOSK_HEALTH_INTERVAL);
  };

  loopTheQueueAndSync = async () => {
    let sourceEnabled;
    if (window.electron) {
      const fileData = JSON.parse(readSettings());
      sourceEnabled = fileData ? fileData.dynamicDataSource : false;
    } else {
      sourceEnabled = JSON.parse(localStorage.getItem('dynamicDataSource'));
    }
    const encryptedQueue = localStorage.getItem('queue') || '[]';
    let parsedQueue;
    if (encryptedQueue !== '[]') {
      const decryptedQueue = _cryptrQueue.decrypt(encryptedQueue);
      parsedQueue = JSON.parse(decryptedQueue);
    } else {
      parsedQueue = JSON.parse(encryptedQueue);
    }
    if (parsedQueue && parsedQueue.length > 0) {
      for (let i = 0; i < parsedQueue.length; i++) {
        if (sourceEnabled === true) {
          if (parsedQueue[i].payload.name === 'KioskVerifyMe_SessionHistory') {
            await this.SaveSessionHistory(parsedQueue[i].payload, i);
          } else if (parsedQueue[i].payload.name === 'saveKioskCustomer') {
            await this.saveConfirmUser(parsedQueue[i].payload, i);
          } else if (parsedQueue[i].payload.name === 'updateStatusOfRx') {
            await this.UpdateStatusOfRx(parsedQueue[i].payload, i);
          } else if (parsedQueue[i].payload.name === 'saveCustomerRatting') {
            await this.SavePatientRating(parsedQueue[i].payload, i);
          } else if (
            [
              'synclifeboat_kiosk_Confirm_Pikup',
              'synclifeboat_kiosk_Express_Confirm_Pikup'
            ].includes(parsedQueue[i].payload.name)
          ) {
            await this.syncPatientLifBoatWithCouldOffline(
              parsedQueue[i].payload,
              i
            );
          } else if (
            parsedQueue[i].payload.name === 'mScriptUpdateOrderStatus'
          ) {
            await this.updateMScriptOrderStatus(parsedQueue[i].payload, i);
          } else if (parsedQueue[i].payload.name === 'consult-declined') {
            await this.handleConsultDeclinedEntry(parsedQueue[i].payload, i);
          } else if (parsedQueue[i].payload.name === 'drxPrescriptionUpdate') {
            await this.updateDrxPrescriptions(parsedQueue[i].payload, i);
          }
        } else {
          if (parsedQueue[i].payload.name === 'sendEmail') {
            await this.SendAnEmailWhenDataPollingIsOff(
              parsedQueue[i].payload,
              i
            );
          }
        }
      }
    }
  };

  getBoxesData = async () => {
    let boxId, dataSource;
    if (window.electron) {
      const fileData = JSON.parse(readSettings());
      boxId = fileData ? fileData.boxId : '';
      dataSource = fileData ? fileData.dynamicDataSource : false;
      FileObje.steps =
        fileData && fileData.steps ? fileData.steps : FileObje.steps;
    } else {
      boxId = localStorage.getItem('boxId');
      dataSource = JSON.parse(localStorage.getItem('dynamicDataSource'));
      FileObje.steps =
        localStorage.getItem('steps') !== null &&
        localStorage.getItem('steps') !== undefined
          ? JSON.parse(localStorage.getItem('steps'))
          : FileObje.steps;
    }
    if (dataSource === false || dataSource === null) {
      this.props.initialData(initialSettings);
      return true;
    } else {
      const token = localStorage.getItem('authTokens');
      const actualToken = atob(token);
      // if ([true, 'true'].includes(localStorage.getItem('isKioskOnline'))) {
      if (![undefined, 'undefined', null, null, ''].includes(boxId)) {
        return await axios
          .get(`${process.env.REACT_APP_BASEURL}getprescriptions/${boxId}`, {
            headers: {
              Authorization: 'Bearer ' + actualToken,
              'Content-Type': 'application/json'
            },
            timeout: process.env.REACT_APP_REQUEST_TIMEOUT_INTERVAL
          })
          .then(async (response) => {
            if (
              response &&
              response.data &&
              response.data.box &&
              response.data.box.id
            ) {
              this.props.updateData(response.data);
              localStorage.setItem('boxType', response.data.box.type_id);
              localStorage.setItem('openpath', response.data.box.openpath);
              localStorage.setItem(
                'isNumeric',
                response.data.box.account.is_numeric_code
              );
              localStorage.setItem(
                'is_install_mode',
                response.data.box.is_install_mode
              );
            }
            this.box = response.data.box;
            await this.callTemperatureAPIs(this.box);

            return response.data;
          })
          .catch((error) => {
            console.log(error);
          });
      }
      // }
    }
  };

  getUpdatedBoxes = (response) => {
    if (
      response &&
      response.data &&
      response.data.box &&
      response.data.box.id
    ) {
      this.props.updateData(response.data);
      return true;
    }
    return false;
  };

  // handleStatsChange = (stats) => {
  //   this.props.updatePollingStats(stats);
  // };

  // startPolling = () => {
  //   this.props.startPolling({
  //     asyncFetch: this.getBoxesData,
  //     callback: this.getUpdatedBoxes,
  //     onStatsChange: this.handleStatsChange,
  //     delay: 10, // 10 Seconds delay between successive polls
  //     retryOnFailure: true, // Retry if API call fails
  //     retryAfter: 5 // Retry after 5 seconds
  //     // stopAfterRetries: 50               // Give up after 50 failure retries
  //   });
  // };

  // Save Captured details of DL for pickup
  saveConfirmUser = async (payload, index) => {
    // console.log(`Index : ${index} and payload : ${JSON.stringify(payload)}`);
    // let log = {
    //   message: `Kiosk Queue : Save kiosk customer collected details API called from queue with payload : ${JSON.stringify(
    //     payload
    //   )}`
    // };
    // writePickupLogsToFile(log);
    const token = localStorage.getItem('authTokens');
    const actualToken = atob(token);
    let pickupType = '1';
    pickupType =
      localStorage.getItem('pickupType') !== null
        ? localStorage.getItem('pickupType')
        : '1';
    let patientInfo = JSON.parse(localStorage.getItem('patient'));
    let order_id =
      payload &&
      payload.body &&
      payload.body.formData &&
      payload.body.formData.OrderID;
    let orderIds = [];
    if (pickupType === '1') {
      orderIds.push({
        id:
          payload &&
          payload.body &&
          payload.body.formData &&
          payload.body.formData.OrderID
      });
    } else if (pickupType === '2') {
      patientInfo[0].orders.map((order) => {
        orderIds.push({ id: order.id });
        return true;
      });
    }
    let customer_id =
      payload &&
      payload.body &&
      payload.body.formData &&
      payload.body.formData.CustomerID;
    let account_id =
      payload &&
      payload.body &&
      payload.body.formData &&
      payload.body.formData.AccountID;
    let box_id =
      payload &&
      payload.body &&
      payload.body.formData &&
      payload.body.formData.BoxID;
    let base64Picture = null;
    let base64Signature = null;
    if (
      payload &&
      payload.body &&
      payload.body.formData &&
      payload.body.formData.picture_url !== null &&
      payload &&
      payload.body &&
      payload.body.formData &&
      payload.body.formData.picture_url !== '' &&
      payload &&
      payload.body &&
      payload.body.formData &&
      payload.body.formData.picture_url !== undefined
    ) {
      base64Picture =
        payload &&
        payload.body &&
        payload.body.formData &&
        payload.body.formData.picture_url.replace(
          /^data:image\/\w+;base64,/,
          ''
        );
    }
    if (
      payload &&
      payload.body &&
      payload.body.formData &&
      payload.body.formData.signature_url !== null &&
      payload &&
      payload.body &&
      payload.body.formData &&
      payload.body.formData.signature_url !== '' &&
      payload &&
      payload.body &&
      payload.body.formData &&
      payload.body.formData.signature_url !== undefined
    ) {
      base64Signature =
        payload &&
        payload.body &&
        payload.body.formData &&
        payload.body.formData.signature_url.replace(
          /^data:image\/\w+;base64,/,
          ''
        );
    }

    let isConsultRequested =
      payload &&
      payload.body &&
      payload.body.formData &&
      payload.body.formData.isConsultRequested;
    if (
      isConsultRequested === undefined ||
      isConsultRequested === 'undefined' ||
      isConsultRequested === null ||
      isConsultRequested === 'null' ||
      isConsultRequested === ''
    ) {
      isConsultRequested = false;
    } else {
      isConsultRequested =
        payload &&
        payload.body &&
        payload.body.formData &&
        payload.body.formData.isConsultRequested;
    }

    let data = new FormData();
    data.append('customer_id', customer_id);
    data.append('order_id', order_id);
    data.append('picture_url', base64Picture);
    data.append('signature_url', base64Signature);
    data.append('middle_name', null);
    data.append('box_id', box_id);
    data.append('account_id', account_id);
    data.append('orderIds', JSON.stringify(orderIds));
    data.append('dlrawdata', payload.body.formData.dlrawdata);
    data.append(
      'kioskBoxAccountConnection',
      payload.body.formData.kioskBoxAccountConnection
    );
    data.append('is_consult_requested', isConsultRequested);
    data.append('call_third_party_API', true);
    try {
      fetch(payload.url, {
        method: payload.type,
        withCredentials: true,
        headers: {
          Authorization: 'Bearer ' + actualToken
        },
        body: data
      })
        .then((response) => {
          try {
            return response.text();
          } catch (error) {
            return null;
          }
        })
        .then((uData) => {
          let log = {
            message: `Kiosk Queue : Getting response from Save kiosk customer collected details called from queue: ${JSON.stringify(
              uData
            )}`
          };
          writePickupLogsToFile(log);
          this.dequeueItem(index);
        });
    } catch (Exception) {
      let log = {
        message: `Kiosk Queue : Getting error from Save kiosk customer collected details from queue: ${JSON.stringify(
          Exception
        )}`
      };
      writePickupLogsToFile(log);
    }
  };

  //Update Status of Rx in cloud after pickup with email send functionality
  UpdateStatusOfRx = async (payload, index) => {
    let log = {
      message: `Kiosk Queue : Update Status of Rx API called from queue with payload : ${JSON.stringify(
        payload
      )}`
    };
    const token = localStorage.getItem('authTokens');
    const actualToken = atob(token);
    writePickupLogsToFile(log);
    try {
      fetch(payload.url, {
        method: payload.type,
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + actualToken
        },
        body: JSON.stringify(payload.body.updateStatusOfRxObj)
      })
        .then((response) => {
          try {
            return response.text();
          } catch (error) {
            return null;
          }
        })
        .then((RxObj) => {
          let log = {
            message: `Kiosk Queue : Getting response from update status of Rx : ${JSON.stringify(
              RxObj
            )}`
          };
          writePickupLogsToFile(log);
          this.dequeueItem(index);
        });
    } catch (Exception) {
      let log = {
        message: `Kiosk Queue : Getting error from update status of Rx from queue: ${JSON.stringify(
          Exception
        )}`
      };
      writePickupLogsToFile(log);
    }
  };

  // Save rating given by the patient
  SavePatientRating = async (rattingObj, index) => {
    let log = {
      message: `Kiosk Queue : feedback API called from queue with payload: ${JSON.stringify(
        rattingObj
      )}`
    };
    writePickupLogsToFile(log);
    const token = localStorage.getItem('authTokens');
    const actualToken = atob(token);
    try {
      fetch(process.env.REACT_APP_BASEURL + 'saveCustomerRatting', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + actualToken
        },
        body: JSON.stringify(rattingObj)
      })
        .then((response) => {
          try {
            return response.text();
          } catch (error) {
            return null;
          }
        })
        .then((uData) => {
          let log = {
            message: `Kiosk Queue : Getting feedback API response from queue : ${JSON.stringify(
              uData
            )}`
          };
          writePickupLogsToFile(log);
          this.dequeueItem(index);
        });
    } catch (Exception) {
      let log = {
        message: `Kiosk Queue : Getting error from feedback API from queue: ${JSON.stringify(
          Exception
        )}`
      };
      writePickupLogsToFile(log);
    }
  };

  SendAnEmailWhenDataPollingIsOff = async (payload, index) => {
    let pickupConfig = payload.body.pickupConfig;
    let tempConfigObj = { pickupConfig };
    const token = localStorage.getItem('authTokens');
    const actualToken = atob(token);
    let log = {
      message: `Kiosk Queue : Send an Email when data polling is off API called from queue with payload: ${JSON.stringify(
        payload
      )}`
    };
    writePickupLogsToFile(log);
    try {
      fetch(payload.url, {
        method: payload.type,
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + actualToken
        },
        body: JSON.stringify(tempConfigObj)
      })
        .then((response) => {
          try {
            return response.text();
          } catch (error) {
            return null;
          }
        })
        .then((res) => {
          let log = {
            message: `Kiosk Queue : Getting response from Send an Email when data polling is off API from queue with payload: ${JSON.stringify(
              res
            )}`
          };
          writePickupLogsToFile(log);
          this.dequeueItem(index);
        });
    } catch (Exception) {
      let log = {
        message: `Kiosk Queue : Getting error from Send an Email when data polling is off API from queue with payload: ${JSON.stringify(
          Exception
        )}`
      };
      writePickupLogsToFile(log);
    }
  };

  // stopPolling = () => {
  //   this.props.updatePollingStats({});
  //   this.props.stopPolling();
  // };

  dequeueItem = (index) => {
    const encryptedQueue = localStorage.getItem('queue') || '[]';
    let parsedQueue;
    if (encryptedQueue !== '[]') {
      const decryptedQueue = _cryptrQueue.decrypt(encryptedQueue);
      parsedQueue = JSON.parse(decryptedQueue);
    } else {
      parsedQueue = JSON.parse(encryptedQueue);
    }

    if (parsedQueue && parsedQueue.length > 0) {
      // delete parsedQueue[index];
      parsedQueue.splice(index, 1);
      const encryptedQueueObject = _cryptrQueue.encrypt(
        JSON.stringify(parsedQueue)
      );
      localStorage.setItem('queue', encryptedQueueObject);
    }
  };

  updateMScriptOrderStatus = async (orderObject, index) => {
    const token = localStorage.getItem('authTokens');
    const actualToken = atob(token);
    fetch(process.env.REACT_APP_BASEURL + 'update/order-status', {
      method: 'put',
      withCredentials: true,
      headers: {
        Authorization: 'Bearer ' + actualToken,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(orderObject)
    })
      .then((response) => {
        try {
          return response.json();
        } catch (error) {
          return null;
        }
      })
      .then((uData) => {
        this.dequeueItem(index);
      });
  };

  syncPatientLifBoatWithCouldOffline = async (localPatientObject, index) => {
    try {
      const token = localStorage.getItem('authTokens');
      const actualToken = atob(token);
      let orderIds = [];
      let _localPatientObj = localPatientObject.body.formData;
      orderIds.push({
        id: _localPatientObj.OrderID
      });

      let data = new FormData();
      data.append('customer_id', _localPatientObj.CustomerID);
      data.append('order_id', _localPatientObj.OrderID);
      data.append('picture_url', _localPatientObj.picture_url);
      data.append('signature_url', _localPatientObj.signature_url);
      data.append('first_name', _localPatientObj.FirstName);
      data.append('middle_name', null);
      data.append('last_name', _localPatientObj.LastName);
      data.append(
        'dob',
        _localPatientObj.dateOfBirth || _localPatientObj.DateOfBirth
      );
      data.append('box_id', _localPatientObj.BoxID);
      data.append('account_id', _localPatientObj.AccountID);
      data.append('orderIds', JSON.stringify(orderIds));
      data.append('is_consult_requested', _localPatientObj.isConsultRequested);
      fetch(process.env.REACT_APP_BASEURL + 'lifeboat/save/patient/details', {
        method: 'POST',
        withCredentials: true,
        headers: {
          Authorization: 'Bearer ' + actualToken
        },
        body: data
      })
        .then((response) => {
          try {
            return response.text();
          } catch (error) {
            return null;
          }
        })
        .then((uData) => {
          this.dequeueItem(index);
        })
        .catch((error) => {
          console.log('syncPatientLifBoatWithCould failed: ', { error });
        });
    } catch (Exception) {
      console.log(
        `Error while saving DL Capture events : ${JSON.stringify(Exception)}`
      );
    }
  };

  initializeFastlane = async () => {
    let log1 = {
      message: `FASTLANE: Kiosk Init : Fastlane Initialization has been started for fastlane.`
    };
    writePickupLogsToFile(log1);
    const token = localStorage.getItem('authTokens');
    if (![undefined, 'undefined', null, 'null', ''].includes(token)) {
      const actualToken = atob(token);
      if (![undefined, 'undefined', null, 'null', ''].includes(actualToken)) {
        let boxId;
        if (window.electron) {
          const _readFileData = readSettings();
          if (
            ![undefined, 'undefined', null, 'null', ''].includes(_readFileData)
          ) {
            const fileData = JSON.parse(readSettings());
            boxId = fileData ? fileData.boxId : '';
          } else {
            boxId = '';
          }
        } else {
          boxId = localStorage.getItem('boxId');
        }
        if (![undefined, 'undefined', null, null, ''].includes(boxId)) {
          if (window.electron) {
            this.setState({ statusText: 'Gathering required information...' });
            const token = localStorage.getItem('authTokens');
            const actualToken = atob(token);
            await axios
              .get(
                `${process.env.REACT_APP_BASEURL}getprescriptions/${boxId}`,
                {
                  headers: {
                    Authorization: 'Bearer ' + actualToken,
                    'Content-Type': 'application/json'
                  }
                }
              )
              .then((response) => {
                let box = response && response.data && response.data.box;
                if (box && box.type_id && box.type_id === BOX_TYPE.FASTLANE) {
                  this.setState({ doesFLPTransactionCancelled: true });
                  const _securityMode = localStorage.getItem('securityMode');
                  let _wrapperURL = `${
                    [0, '0', undefined].includes(_securityMode)
                      ? process.env.REACT_APP_BASE_APP_URL
                      : process.env.REACT_APP_BASE_APP_URL_HTTPS
                  }`;
                  let log1 = {
                    message: `FASTLANE: Kiosk Init : Calling init and home commmand.`
                  };
                  writePickupLogsToFile(log1);
                  this.setState({ statusText: 'Initializing the system...' });
                  customFetch(
                    `${_wrapperURL}fastlane/init`,
                    {
                      method: 'GET',
                      withCredentials: true,
                      headers: {
                        Authorization: 'Bearer ' + actualToken
                      }
                    },
                    'init',
                    'APP:pickup',
                    localStorage.getItem('dummy_kiosk_box_id'),
                    false
                  )
                    .then((response) => {
                      try {
                        return response.json();
                      } catch (error) {
                        return null;
                      }
                    })
                    .then(async (initResponse) => {
                      if (initResponse && initResponse.statusCode === 200) {
                        let log1 = {
                          message: `FASTLANE: Kiosk Init : Init/Home command executed successfully with response ${JSON.stringify(
                            initResponse,
                            null,
                            2
                          )}.`
                        };
                        writePickupLogsToFile(log1);
                        await this.handleResetFesto();
                      } else {
                        let log1 = {
                          message: `FASTLANE: Kiosk Init : Init/Home command execution failed with response ${JSON.stringify(
                            initResponse,
                            null,
                            2
                          )}.`
                        };
                        writePickupLogsToFile(log1);
                        this.setState({ doesFLPTransactionCancelled: false });
                      }
                    })
                    .catch((initErrorObject) => {
                      let log1 = {
                        message: `FASTLANE: Kiosk Init : Init/Home command execution failed with response ${JSON.stringify(
                          initErrorObject,
                          null,
                          2
                        )}.`
                      };
                      writePickupLogsToFile(log1);
                      this.setState({ doesFLPTransactionCancelled: false });
                    });
                }
              })
              .catch((error) => {
                console.log(error);
              });
          }
        }
      }
    }
  };

  handleConsultDeclinedEntry = async (payload, index) => {
    const token = localStorage.getItem('authTokens');
    const actualToken = atob(token);
    fetch(process.env.REACT_APP_BASEURL + 'consult-declined', {
      method: 'POST',
      withCredentials: true,
      headers: {
        Authorization: 'Bearer ' + actualToken
      },
      body: JSON.stringify(payload)
    })
      .then((response) => {
        try {
          return response.text();
        } catch (error) {
          return null;
        }
      })
      .then((uData) => {
        this.dequeueItem(index);
      })
      .catch((error) => {
        console.log('handleConsultDeclinedEntry failed: ', { error });
      });
  };

  handlePoolSensorStatus = async () => {
    let log1 = {
      message: `FASTLANE: Kiosk Init : Pool sensor status command execution initiated.`
    };
    writePickupLogsToFile(log1);
    let _wrapperURL = process.env.REACT_APP_BASE_APP_URL;
    const token = localStorage.getItem('authTokens');
    const actualToken = atob(token);
    if (![undefined, 'undefined', null, 'null', ''].includes(actualToken)) {
      let log1 = {
        message: `FASTLANE: Kiosk Init : Calling Pool sensor status command.`
      };
      writePickupLogsToFile(log1);
      this.setState({ statusText: 'Activating pool sensor status...' });
      customFetch(
        `${_wrapperURL}fastlane/pool-sensor-status?startStopPoolSensorStatus=true`,
        {
          method: 'GET',
          withCredentials: true,
          headers: {
            Authorization: 'Bearer ' + actualToken
          }
        },
        'pool-sensor-status',
        'APP:pickup',
        localStorage.getItem('dummy_kiosk_box_id'),
        false
      )
        .then((response) => {
          try {
            return response.json();
          } catch (error) {
            return null;
          }
        })
        .then((pssror) => {
          let log1 = {
            message: `FASTLANE: Kiosk Init : Pool sensor status command executed successfully with response ${JSON.stringify(
              pssror,
              null,
              2
            )}.`
          };
          writePickupLogsToFile(log1);
          this.setState({ doesFLPTransactionCancelled: false });
        })
        .catch((initErrorObject) => {
          let log1 = {
            message: `FASTLANE: Kiosk Init : Error while execution of Pool sensor status command with response ${JSON.stringify(
              initErrorObject,
              null,
              2
            )}.`
          };
          writePickupLogsToFile(log1);
          this.setState({ doesFLPTransactionCancelled: false });
        });
    }
  };

  handleResetFesto = async () => {
    let log1 = {
      message: `FASTLANE: Kiosk Init : Reset command execution initiated.`
    };
    writePickupLogsToFile(log1);
    const token = localStorage.getItem('authTokens');
    const actualToken = atob(token);
    if (![undefined, 'undefined', null, 'null', ''].includes(actualToken)) {
      let _wrapperURL = process.env.REACT_APP_BASE_APP_URL;
      let log1 = {
        message: `FASTLANE: Kiosk Init : Calling Reset command.`
      };
      writePickupLogsToFile(log1);
      this.setState({ statusText: 'Reset is in progress...' });
      customFetch(
        `${_wrapperURL}fastlane/reset-festo`,
        {
          method: 'GET',
          withCredentials: true,
          headers: {
            Authorization: 'Bearer ' + actualToken
          }
        },
        'reset-festo',
        'APP:pickup',
        localStorage.getItem('dummy_kiosk_box_id'),
        false
      )
        .then((response) => {
          try {
            return response.json();
          } catch (error) {
            return null;
          }
        })
        .then(async (resetResponse) => {
          if (resetResponse && resetResponse.statusCode === 200) {
            let log1 = {
              message: `FASTLANE: Kiosk Init : Reset command executed successfully with response ${JSON.stringify(
                resetResponse,
                null,
                2
              )}.`
            };
            writePickupLogsToFile(log1);
            await this.handleHomeEvent();
          } else {
            let log1 = {
              message: `FASTLANE: Kiosk Init : Error while execution of Reset command with response ${JSON.stringify(
                resetResponse,
                null,
                2
              )}.`
            };
            writePickupLogsToFile(log1);
            this.setState({ doesFLPTransactionCancelled: false });
          }
        })
        .catch((initErrorObject) => {
          let log1 = {
            message: `FASTLANE: Kiosk Init : Error while execution of Reset command with response ${JSON.stringify(
              initErrorObject,
              null,
              2
            )}.`
          };
          writePickupLogsToFile(log1);
          this.setState({ doesFLPTransactionCancelled: false });
        });
    }
  };

  handleHomeEvent = async () => {
    let log1 = {
      message: `FASTLANE: Kiosk Init : Home command execution initiated.`
    };
    writePickupLogsToFile(log1);
    const token = localStorage.getItem('authTokens');
    const actualToken = atob(token);
    if (![undefined, 'undefined', null, 'null', ''].includes(actualToken)) {
      let _wrapperURL = process.env.REACT_APP_BASE_APP_URL;
      let log1 = {
        message: `FASTLANE: Kiosk Init : Calling Home command.`
      };
      writePickupLogsToFile(log1);
      this.setState({ statusText: 'Homing hatch tool in progress...' });
      customFetch(
        `${_wrapperURL}fastlane/home`,
        {
          method: 'GET',
          withCredentials: true,
          headers: {
            Authorization: 'Bearer ' + actualToken
          }
        },
        'home',
        'APP:pickup',
        localStorage.getItem('dummy_kiosk_box_id'),
        false
      )
        .then((response) => {
          try {
            return response.json();
          } catch (error) {
            return null;
          }
        })
        .then(async (homeResponse) => {
          if (homeResponse && homeResponse.statusCode === 200) {
            let log1 = {
              message: `FASTLANE: Kiosk Init : Home command executed successfully with response ${JSON.stringify(
                homeResponse,
                null,
                2
              )}.`
            };
            writePickupLogsToFile(log1);
            await this.handlePoolSensorStatus();
          } else {
            let log1 = {
              message: `FASTLANE: Kiosk Init : Error while execution of Home command with response ${JSON.stringify(
                homeResponse,
                null,
                2
              )}.`
            };
            writePickupLogsToFile(log1);
            this.setState({ doesFLPTransactionCancelled: false });
          }
        })
        .catch((initErrorObject) => {
          let log1 = {
            message: `FASTLANE: Kiosk Init : Error while execution of Home command with response ${JSON.stringify(
              initErrorObject,
              null,
              2
            )}.`
          };
          writePickupLogsToFile(log1);
          this.setState({ doesFLPTransactionCancelled: false });
        });
    }
  };

  getBoxByKioskId = async () => {
    let response = {};
    const boxId = localStorage.getItem('dummy_kiosk_box_id');
    if (![undefined, 'undefined', null, 'null', ''].includes(boxId)) {
      const token = localStorage.getItem('authTokens');
      const actualToken = atob(token);
      response = fetch(
        process.env.REACT_APP_BASEURL + 'box/box-by-kbox-id?id=' + boxId,
        {
          method: 'GET',
          withCredentials: true,
          headers: {
            Authorization: 'Bearer ' + actualToken
          }
        }
      )
        .then((response) => {
          try {
            return response.json();
          } catch (error) {
            return null;
          }
        })
        .then((bData) => {
          return bData;
        })
        .catch((error) => {
          return error;
        });
    }
    return response;
  };

  SaveSessionHistory = async (payload, index) => {
    let log = {
      message: `Kiosk Queue : Save Session History API called from queue with payload: ${JSON.stringify(
        payload
      )}`
    };
    writePickupLogsToFile(log);
    const token = localStorage.getItem('authTokens');
    const actualToken = atob(token);
    fetch(payload.url, {
      method: payload.type,
      withCredentials: true,
      headers: {
        Authorization: 'Bearer ' + actualToken,
        'Content-Type': 'application/json'
      },
      body: payload.body
    })
      .then((response) => {
        try {
          return response.json();
        } catch (error) {
          return null;
        }
      })
      .then(() => {
        this.dequeueItem(index);
      })
      .catch(async (Exception) => {
        let log = {
          message: `Kiosk Queue : Getting error from Save Session History API from queue: ${JSON.stringify(
            Exception
          )}`
        };
        writePickupLogsToFile(log);
        await this.SaveSessionHistory(payload, index);
      });
  };

  fetchWithTimeout(url, options, timeout) {
    const fetchPromise = fetch(url, options);
    const timeoutPromise = new Promise((resolve, reject) => {
      setTimeout(() => {
        reject(new Error('Request timed out'));
      }, timeout);
    });
    return Promise.race([fetchPromise, timeoutPromise]);
  }

  fetchCoolExpansionData = async (coolExpansionItem, index, actualToken) => {
    const paymentBaseURL =
      localStorage.getItem('securityMode') === '0'
        ? process.env.REACT_APP_BASE_APP_URL
        : process.env.REACT_APP_BASE_APP_URL_HTTPS;

    try {
      const targetTempResponse = await this.fetchWithTimeout(
        `${paymentBaseURL}read/target-temperature-value`,
        {
          method: 'POST',
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + actualToken
          },
          body: JSON.stringify({
            port: coolExpansionItem.com_port
          })
        },
        5000 // Timeout duration in milliseconds
      );

      const targetTempObj = await targetTempResponse.text();
      if (![undefined, 'undefined', null, 'null', ''].includes(targetTempObj)) {
        const parsedJson = JSON.parse(targetTempObj);
        console.log(
          `Target temperature value for port ${
            parsedJson && parsedJson.port
          }: ${parsedJson && parsedJson.data}`
        );
        window.targetTempValue = parsedJson && parsedJson.data;
      }

      // Add a timeout of 3 seconds before fetching current temperature
      await new Promise((resolve) => setTimeout(resolve, (index + 1) * 5000));

      const currentTempResponse = await this.fetchWithTimeout(
        `${paymentBaseURL}read/current-temperature`,
        {
          method: 'POST',
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + actualToken
          },
          body: JSON.stringify({
            port: coolExpansionItem.com_port
          })
        },
        5000 // Timeout duration in milliseconds
      );

      const currentTempObj = await currentTempResponse.text();
      if (
        ![undefined, 'undefined', null, 'null', ''].includes(currentTempObj)
      ) {
        const parsedJson = JSON.parse(currentTempObj);
        if (parsedJson.data !== null) {
          await axios.post(
            `${process.env.REACT_APP_BASEURL}temperature/history`,
            {
              data: [
                {
                  expansion_id: coolExpansionItem.id,
                  temperature: parsedJson.data,
                  timestamp: parsedJson.datetime,
                  port: parsedJson.port
                }
              ]
            },
            {
              headers: {
                Authorization: 'Bearer ' + actualToken,
                'Content-Type': 'application/json'
              }
            }
          );
        } else {
          console.log('Temperature data is null, skipping post request.');
        }
      }

      // check for captureEvaporatorTemperature flag in localstorage, if true then read evaporator temperature
      // and post it to backend as well
      const captureEvaporatorTemperature = localStorage.getItem(
        'captureEvaporatorTemperature'
      );
      if (
        captureEvaporatorTemperature &&
        captureEvaporatorTemperature === 'true'
      ) {
        try {
          // Read evaporator temperature request
          const evaporatorTempResponse = await this.fetchWithTimeout(
            `${paymentBaseURL}read/input-register`,
            {
              method: 'POST',
              withCredentials: true,
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${actualToken}`
              },
              body: JSON.stringify({
                port: coolExpansionItem.com_port,
                register: 1,
                length: 1
              })
            },
            5000 // Timeout duration in milliseconds
          );

          const evaporatorTempObj = await evaporatorTempResponse.json();
          if (
            evaporatorTempObj &&
            evaporatorTempObj.data !== undefined &&
            evaporatorTempObj.data !== null &&
            evaporatorTempObj.data !== '' &&
            Array.isArray(evaporatorTempObj.data) &&
            evaporatorTempObj.data.length > 0
          ) {
            // get the url to post to from localstorage
            const captureEvaporatorTemperatureURL = localStorage.getItem(
              'captureEvaporatorTemperatureURL'
            );
            if (!captureEvaporatorTemperatureURL) {
              console.error(
                'captureEvaporatorTemperatureURL not found in local storage, skipping post request.'
              );
              return;
            }
            // Post temperature history
            await axios.post(
              captureEvaporatorTemperatureURL,
              {
                data: [
                  {
                    expansion_id: coolExpansionItem.id,
                    temperature: evaporatorTempObj.data[0],
                    timestamp: evaporatorTempObj.datetime,
                    port: evaporatorTempObj.port
                  }
                ]
              },
              {
                headers: {
                  Authorization: `Bearer ${actualToken}`,
                  'Content-Type': 'application/json'
                }
              }
            );
          } else {
            console.log(
              'Evaporator temperature data is null, skipping post request.'
            );
          }
        } catch (error) {
          console.error('Error capturing evaporator temperature:', error);
        }
      }
    } catch (error) {
      console.log('Error:', error);
    }
  };

  callTemperatureAPIs = async (box) => {
    const token = localStorage.getItem('authTokens');
    const actualToken = atob(token);
    if (window.electron) {
      const coolExpansion =
        box &&
        box.box_expansions &&
        box.box_expansions.filter(
          (x) => x.boxes_type.name === 'Cool (Temp controlled with 8 bins)'
        );
      if (coolExpansion && coolExpansion.length > 0) {
        for (let i = 0; i < coolExpansion.length; i++) {
          console.log(
            `coolExpansion.target_temp: ${coolExpansion[i].target_temp}`
          );
          if (!this.state.livePorts.includes(coolExpansion[i].com_port)) {
            this.setState({
              livePorts: [...this.state.livePorts, coolExpansion[i].com_port]
            });
            window.openCool(coolExpansion[i].com_port);
          }
          await this.fetchCoolExpansionData(coolExpansion[i], i, actualToken);
          // Timeout between sequential requests
          await new Promise((resolve) => setTimeout(resolve, (i + 1) * 3000));
        }
      }
    }
  };

  updateDrxPrescriptions = async (orderObject, i) => {
    const token = localStorage.getItem('authTokens');
    const actualToken = atob(token);
    fetch(process.env.REACT_APP_BASEURL + 'drx/prescription/update', {
      method: 'post',
      withCredentials: true,
      headers: {
        Authorization: 'Bearer ' + actualToken,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(orderObject)
    })
      .then((response) => {
        try {
          return response.json();
        } catch (error) {
          return null;
        }
      })
      .then(() => {
        this.dequeueItem(i);
      });
  };

  callOpenBinApi = async (binId) => {
    console.log(
      `Trying to open the bin ${binId} with Hex command ${openBinCommand(
        binId
      )}`
    );
    const token = localStorage.getItem('authTokens');
    let binObj;
    if (![undefined, 'undefined', null, 'null', ''].includes(token)) {
      const actualToken = atob(token);
      if (![undefined, 'undefined', null, 'null', ''].includes(actualToken)) {
        const _securityMode = localStorage.getItem('securityMode');
        const paymentBaseURL = `${
          [0, '0', undefined].includes(_securityMode)
            ? process.env.REACT_APP_BASE_APP_URL
            : process.env.REACT_APP_BASE_APP_URL_HTTPS
        }`;
        await fetch(`${paymentBaseURL}bins/open-bin`, {
          method: 'POST',
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + actualToken
          },
          body: JSON.stringify({ binId: binId })
        })
          .then((response) => {
            try {
              return response.text();
            } catch (error) {
              return null;
            }
          })
          .then((binsObject) => {
            let parsedResponse = JSON.parse(binsObject);
            let response =
              parsedResponse && parsedResponse.res && parsedResponse.res.status;
            if (response === 200) {
              binObj = parsedResponse.res.data;
            } else {
              binObj = false;
            }
          });
      }
    }
    return binObj;
  };

  render() {
    const { locale } = this.props;
    const { doesFLPTransactionCancelled, statusText } = this.state;
    const messages = translations[locale];

    return (
      <ErrorBoundarys>
        <IntlProvider locale={locale} key={locale} messages={messages}>
          <div>
            <Switch>
              <Route path="/" exact component={KioskLayout} />
              <Route
                path="/SimpleStartKioskWelcomeScreen"
                component={SimpleStartKioskWelcomeScreenLayout}
              />
              <Route
                path="/ContactlessCapturePickup"
                component={ContactlessPickupLayout}
              />
              <Route path="/" exact component={KioskLayout} />
              <Route path="/KioskPickupCode" component={KioskPickupLayout} />
              <Route path="/KioskVerify" component={KioskVerifyMeLayout} />
              <Route
                path="/KioskConfirmPickup"
                component={KioskConfimPickupLayout}
              />
              <Route path="/KioskSignature" component={KioskSignatureMain} />
              <Route path="/KioskConsult" component={KioskConsultLayout} />
              <Route path="/KioskFeedback" component={KioskFeedbackLayout} />
              <Route
                path="/KioskCollectMedicines"
                component={KioskDoorLayout}
              />
              <Route
                path="/KioskConfirmUser"
                component={KioskCollectIDLayout}
              />

              <Route
                path="/KioskEnrollment"
                component={KioskEnrollmentLayout}
              />
              <Route path="/KioskOptHrs" component={KioskOptHrsMain} />
              <Route
                path="/KioskWelcomeScreen"
                component={KioskWelcomeScreenLayout}
              />
              <Route path="/KioskCheckin" component={KioskCheckinLayout} />
              <Route
                path="/KioskNewPatientEnrollment"
                component={KioskNewPatientEnrollmentLayout}
              />
              <Route
                path="/KioskCurrentPatientSearch"
                component={KioskCurrentPatientSearchLayout}
              />
              <Route
                path="/KioskCurrentPatientSearchResult"
                component={KioskCurrentPatientSearchResultLayout}
              />
              <Route
                path="/KioskNewPatientSearchResult"
                component={KioskNewPatientSearchResultLayout}
              />
              <Route
                path="/KioskPatientLogin"
                component={KioskPatientLoginLayout}
              />
              <Route
                path="/KioskPatientViewRx"
                component={KioskPatientViewRxLayout}
              />
              <Route
                path="/KioskHealthandinformation"
                component={KioskHealthandinformationLayout}
              />
              <Route path="/KioskGetKit" component={KioskGetKitLayout} />
              <Route path="/KioskRefillRx" component={KioskRefillRxLayout} />
              <Route path="/KioskShopNow" component={KioskShopNowLayout} />
              <Route
                path="/KioskProductList"
                component={KioskProductListLayout}
              />
              <Route path="/KioskQuizTest" component={KioskQuizTestLayout} />
              <Route path="/KioskQuizPass" component={KioskQuizPassLayout} />
              <Route
                path="/KioskQuizEnrollment"
                component={KioskQuizEnrollmentLayout}
              />
              <Route path="/KioskOrderItem" component={KioskOrderItemLayout} />
              <Route path="/AdminLogin" component={AdminLoginLayout} />
              <Route
                path="/SecureBadgeLogin"
                component={SecureBadgeLoginForm}
              />
              <Route path="/implicit" component={OktaLogin} />
              <Route path="/PatientLogin" component={PatientLoginLayout} />
              <Route path="/AdminPanel" component={AdminPanelLayout} />
              <Route path="/login" component={LoginLayout} />
              <Route path="/AdminStock" component={AdminPanelStockLayout} />
              <Route
                path="/AdminKioskVerify"
                component={AdminKioskVerifyLayout}
              />
              <Route
                path="/AdminMaintenance"
                component={AdminMaintenanceLayout}
              />
              <Route path="/AdminSettings" component={AdminSettingLayout} />
              <Route path="/AdminRemove" component={AdminRemoveLayout} />

              <Route
                path="/RearStock/AdminLogin"
                component={RearStockAdminLoginLayout}
              />
              <Route
                path="/RearStock/AdminStock"
                component={RearStockAdminPanelStockLayout}
              />
              <Route
                path="/RearStock/AdminPanel"
                component={RearStockAdminPanelLayout}
              />
              <Route
                path="/RearStock/AdminMaintenance"
                component={RearStockAdminMaintenanceLayout}
              />
              <Route
                path="/RearStock/AdminSettings"
                component={RearStockAdminSettingLayout}
              />
              <Route
                path="/RearStock/AdminRemove"
                component={RearStockAdminRemoveLayout}
              />
              <Route
                path="/RearStock/AdminKioskVerify"
                component={RearStockAdminKioskVerifyLayout}
              />
              <Route
                path="/KioskCheckConfirmuser"
                component={KioskCheckConfirmuserLayout}
              />
              <Route
                path="/KioskInformation"
                component={KioskInformationMain}
              />
              <Route
                path="/KioskForgotPickUPCode"
                component={KioskForgotPickUPCode}
              />
              <Route path="/ExpressUI" component={ExpressUI} />
              <Route path="/retail" component={RetailUI} />
              <Route path="/safe-mode" component={safemode} />
              <Route path="/eSeek" component={KioskeSeek} />
            </Switch>
            <Dialog
              maxWidth={'lg'}
              fullWidth={true}
              open={doesFLPTransactionCancelled}
              keepMounted
              aria-labelledby="alert-dialog-slide-title"
              aria-describedby="alert-dialog-slide-description"
              PaperProps={{
                style: {
                  border: '2px solid #0C7CBA',
                  borderRadius: '5px',
                  height: '150px'
                }
              }}
            >
              <DialogTitle style={{ textAlign: 'left', fontSize: '1.5rem' }}>
                Processing Initial Setup
              </DialogTitle>
              <DialogContent
                style={{ textAlign: 'center', fontSize: '1.5rem' }}
              >
                {statusText}
              </DialogContent>
            </Dialog>
          </div>
        </IntlProvider>
      </ErrorBoundarys>
    );
  }
}

const mapStateToProps = ({ box, pollingStats }) => ({
  box,
  ...pollingStats
});

const mapDispatchToProps = (dispatch) => ({
  // startPolling: (params) => dispatch(startPolling(params)),
  // stopPolling: () => dispatch(stopPolling()),
  // updatePollingStats: (stats) => dispatch(updatePollingStats(stats)),
  updateData: (box) => dispatch(updateData(box)),
  initialData: (box) => dispatch(initialData(box))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(App));
