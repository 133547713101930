const styles = (theme) => {
  return {
    container: {
      maxWidth: '56vh',
      minHeight: '75vh',
      display: 'grid',
      position: 'relative',
      margin: 'auto',
      justifyContent: 'center',
      alignItems: 'center'
    },
    '@media (max-width: 640px)': {
      container: {
        gridTemplateColumns: '320px'
      }
    },
    '@media screen and (max-width: 1080px)': {
      container: {
        maxWidth: '50vh',
        minHeight: '74vh',
        display: 'grid',
        position: 'relative',
        margin: 'auto',
        justifyContent: 'center',
        alignItems: 'center'
      }
    },
    '@media screen and (max-device-width: 1280px) and (max-device-height: 800px)':
      {
        container: {
          maxWidth: '950px',
          minHeight: '69vh'
        }
      }
  };
};

export default styles;
