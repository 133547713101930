const styles = (theme) => {
  return {
    container: {
      display: 'grid',
      minHeight: '75vh',
      gridAutoFlow: 'dense',
      justifyContent: 'center',
      minWidth: '50vh',
      alignItems: 'center'
    },
    appLogoContainer: {
      display: 'grid',
      gridTemplateColumns: 'auto',
      height: '80px',
      // alignItems: 'flex-end',
      justifyContent: 'center'
    },
    appLogo: {
      width: '290px'
    },
    appName: {
      fontSize: '3em',
      fontWeight: '400',
      color: '#005d67'
    },
    title: {
      fontSize: '1.5em',
      fontWeight: '100',
      color: '#005d67'
    },
    form: {
      width: '50vh',
      alignItems: 'center',
      justifyContent: 'center'
    },
    linkContainer: {
      marginTop: '0.75em',
      fontSize: '0.75em'
    },
    buttonLink: {
      marginTop: '5px',
      background: 'none!important',
      color: 'inherit',
      border: 'none',
      padding: '0!important',
      font: 'inherit',
      /*border is optional*/
      borderBottom: '1px solid #444',
      cursor: 'pointer'
    },
    '@media screen and  (min-width: 1440px) and (orientation:landscape)': {
      appLogoContainer: {
        display: 'grid',
        gridTemplateColumns: 'auto',
        height: '80px',
        alignItems: 'center',
        justifyContent: 'center'
      },
      appLogo: {
        width: '200px'
      },
      container: {
        display: 'grid',
        minHeight: '500px',
        textAlign: 'center',
        gridAutoFlow: 'dense'
        // justifyContent:'center'
      }
    },
    '@media screen and (max-device-width: 1280px) and (max-device-height: 800px)':
      {
        container: {
          display: 'grid',
          minHeight: 'initial',
          // overflowY: 'auto',
          // height: '312px',
          width: '800px'
        },
        form: {
          width: '700px'
        }
      }
  };
};

export default styles;
