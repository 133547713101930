export default [
  {
    code: 130,
    message: 'Drives Not Ready',
    solution:
      'Reset the Drives. Make sure the drives are homed. Verify the drives are powered on. Verify Safety Interlocks- Door Switches, E-Stop.'
  },
  {
    code: 150,
    message: 'Safety Interlocks Active',
    solution: 'Check Door Switches. Check E-Stop'
  },
  {
    code: 250,
    message: 'Drives Not Referenced',
    solution: 'Home the drives- SDGCmd:= "Home"'
  },
  {
    code: 281,
    message: 'No Bin To Place',
    solution:
      'Bin In Tool Sensor is not reading. Verify there is a Bin in the Tool. Check functionality of Bin In Tool Sensor.'
  },
  {
    code: 280,
    message: 'Location is Occupied',
    solution:
      'There is a bin or other obstruction in the supplied place location Verify Bin In Front or Bin In Back Sensor Status'
  },
  {
    code: 285,
    message: 'Bin In Tool Already',
    solution:
      'There is a bin in the Tool and the GetBin command was supplied. Check for Bin in the Tool. Check Bin In Tool Sensor.'
  },
  {
    code: 286,
    message: 'No Bin In Slot',
    solution:
      'Check that there is a bin in the slot you are getting from Check BinDetectFrontSensor or BinDetectBackSenso'
  }
];
