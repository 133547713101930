const styles = (theme) => {
  return {
    root: {
      display: 'flex',
      gridTemplateColumns: '50% 50%',
      // backgroundColor: theme.footerBackgroundColor,
      color: theme.footerTextColor,
      fontSize: theme.footerFontSize,
      padding: '10px',
      position: 'absolute',
      width: '100%',
      bottom: '0%'
    },
    copyright: {
      display: 'flex',
      justifyContent: 'left',
      alignItems: 'flex-end'
    },
    Admin: {
      justifyContent: 'left',
      alignItems: 'flex-end',
      color: '#aaa',
      display: 'grid',
      padding: '30px 0px',
      fontSize: '0.90rem',
      float: 'left',
      width: '33.3333%',
      textAlign: 'left'
    },
    space: {
      color: '#aaa',
      display: 'grid',
      fontSize: '0.90rem',
      float: 'left'
    },
    poweredBy: {
      color: '#aaa',
      fontSize: '0.7em',
      float: 'left',
      textAlign: 'right',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start'
    },
    labelClass: {
      padding: '0px 35px'
    },
    spanLink: {
      cursor: 'pointer'
    },
    center: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-end'
    },
    backIRS: {
      display: 'none'
    },
    version: {
      //marginRight: '10%'
      marginBottom: '-58px',
      marginLeft: '-88px'
    },
    NeedHelp: {
      color: '#aaa',
      display: 'grid',
      fontSize: '0.90rem',
      textAlign: 'center',
      alignItems: 'center'
    },
    dialogBackground: {
      //background: 'linear-gradient(155deg, #ce907b 0%,#ffcfa6 90%)'
      background: 'transperent'
    },
    dialogContentColor: {
      //color: '#387371',
      color: '#77818D',
      textAlign: 'center',
      fontSize: '1.9em',
      fontWeight: '500'
    },
    alignButton: {
      justifyContent: 'center',
      alignItems: 'center'
    },
    buttonColor: {
      //color: '#387371',
      color: 'black',
      fontSize: '1.9em'
    },
    '@media screen and (min-width: 380px) and (max-width: 1440px) and (orientation:landscape)':
      {
        root: {
          display: 'grid',
          gridTemplateColumns: '50% 50%',
          color: theme.footerTextColor,
          fontSize: theme.footerFontSize,
          padding: '10px',
          position: 'absolute',
          width: '100%',
          bottom: '-55%'
        }
      },
    '@media only screen and (min-width: 380px) and (max-width: 1080px) and (orientation:portrait)':
      {
        root: {
          // display: 'grid',
          gridTemplateColumns: '50% 50%',
          // backgroundColor: theme.footerBackgroundColor,
          color: theme.footerTextColor,
          fontSize: theme.footerFontSize,
          padding: '10px',
          position: 'absolute',
          width: '100%',
          bottom: '28.5%'
        }
      },
    '@media screen and  (min-width: 1440px) and (orientation:landscape)': {
      poweredBy: {
        float: 'right',
        width: '100%'
      },
      backKiosk: {
        display: 'none'
      },
      backIRS: {
        display: 'block'
      }
    },
    '@media screen and (max-device-width: 1280px) and (max-device-height: 800px)':
      {
        poweredBy: {
          float: 'right',
          width: '100%',
          marginLeft: '78%',
          marginTop: '-5%'
        },
        backKiosk: {
          display: 'none'
        },
        backIRS: {
          display: 'block'
        },
        version: {
          marginLeft: '-19%',
          marginBottom: '-13%'
        }
      }
  };
};

export default styles;
