import { connect } from 'react-redux';
import AdminPanelForm from './AdminPanelForm';

import * as AdminLoginActions from './AdminPanelActionCreator';

const mapStateToProps = (state) => {
  const auth =
    state.get('auth') && state.get('auth').toJS && state.get('auth').toJS();
  return {
    ...auth
  };
};

const mapDispatchToProps = {
  ...AdminLoginActions
};

const AdminPanelFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminPanelForm);

export default AdminPanelFormContainer;
