import { withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './AdminLoginFormControls.styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { injectIntl, defineMessages } from 'react-intl';
import 'react-simple-keyboard/build/css/index.css';
import Keyboard from 'react-simple-keyboard';

const messages = defineMessages({
  email: {
    id: 'Login.Email',
    defaultMessage: 'Email'
  },
  password: {
    id: 'Login.Password',
    defaultMessage: 'Password'
  },
  signin: {
    id: 'Login.SignIn',
    defaultMessage: 'Sign In'
  },
  forgotpassword: {
    id: 'Login.ForgotPassword',
    defaultMessage: 'Forgot Password?'
  },
  contactsupport: {
    id: 'Login.ContactSupport',
    defaultMessage: 'Contact Support'
  },
  badgeId: {
    id: 'Login.badgeId',
    defaultMessage: 'BadgeID'
  }
});

export class AdminLoginFormControlsBadgeLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      maxlength: 100,
      SelectedInput: 'badgeId',
      layoutName: 'default',
      username: '',
      password: '',
      badgeId: '',
      toggle: false
    };
  }
  static propTypes = {
    test: PropTypes.string,
    error: PropTypes.object
  };
  onChange = (input) => {
    if (this.state.SelectedInput) {
      document.querySelector('#' + this.state.SelectedInput).value = input;
      var event = new Event('change', { bubbles: true });
      event.simulated = true;
      document
        .querySelector('#' + this.state.SelectedInput)
        .dispatchEvent(event);
      this.change(this.state.SelectedInput, event);
    }
  };
  onKeyPress = (button) => {
    if (['{capslock}', '{shiftleft}', '{shiftright}'].includes(button))
      this.handleShiftButton();
  };
  handleShiftButton = () => {
    const {
      state: { layoutName }
    } = this;
    const shiftToggle = layoutName === 'default' ? 'shift' : 'default';
    this.setState({ layoutName: shiftToggle });
  };
  change = (name, e) => {
    let input = e.target.value;
    this.setState({ [e.target.name]: e.target.value }, () => {
      this.keyboard.setInput(input);
    });
    this.props.handleChange(e);
    this.props.setFieldTouched(name, true, false);
  };
  Focus = (obj) => {
    let ref = obj.target.name;
    if (this.keyboard) {
      this.setState({ SelectedInput: ref });
      this.setState({ maxlength: 100 });
      this.keyboard.setInput(document.querySelector('#' + ref).value);
    }
  };
  Blur = () => {
    //this.setState({ SelectedInput: '' });
  };

  forgotPassword = (e) => {
    this.props.onForgotPasswordRequest(this.props.values);
    e.preventDefault();
  };

  contactSupport = (e) => {
    this.props.onContactSupportRequest(this.props.values);
    e.preventDefault();
  };

  toogleDiv = () => {
    this.props.action();
  };
  render() {
    const { classes, intl } = this.props;
    const {
      // values: { username, password },
      errors,
      touched,
      handleSubmit,
      isValid
    } = this.props;

    return (
      <div>
        <form onSubmit={handleSubmit} className={classNames(classes.container)}>
          <TextField
            id="badgeId"
            name="badgeId"
            InputProps={{
              classes: {
                input: classes.textField
              }
            }}
            label={intl.formatMessage(messages.badgeId)}
            autoComplete="off"
            autoCapitalize="false"
            autoFocus={this.state.SelectedInput === 'badgeId' ? true : false}
            fullWidth
            //defaultValue={username}
            helperText={touched.badgeId ? errors.badgeId : ''}
            error={touched.badgeId && Boolean(errors.badgeId)}
            onChange={this.change.bind(null, 'badgeId')}
            onFocus={this.Focus.bind('badgeId')}
            onBlur={this.Blur.bind('badgeId')}
            value={this.state.badgeId}
          />
          <div className={classNames(classes.formFooter)}>
            <p
              onClick={this.toogleDiv}
              className={classNames(classes.togglerP)}
            >
              Login with with your UserName/Password
            </p>
            <Button
              type="submit"
              fullWidth
              color="primary"
              variant="contained"
              className={
                !isValid
                  ? classNames(classes.disabledPrimaryButton)
                  : classNames(classes.primaryButton)
              }
              disabled={!isValid}
            >
              {intl.formatMessage(messages.signin)}
            </Button>
          </div>
          <div className={classNames(classes.linkContainer)}>
            {/* <button
          className={classNames(classes.buttonLink)}
          onClick={this.forgotPassword.bind()}
        >
          {intl.formatMessage(messages.forgotpassword)}
        </button> */}
          </div>
        </form>

        <div className={classNames(classes.simpleKeyboard)}>
          <Keyboard
            keyboardRef={(r) => (this.keyboard = r)}
            layoutName={this.state.layoutName}
            maxLength={{ default: this.state.maxlength }}
            layout={{
              default: [
                '` 1 2 3 4 5 6 7 8 9 0 - = {backspace}',
                'q w e r t y u i o p [ ] \\',
                "{capslock} a s d f g h j k l ; '",
                'z x c v b n m , . /',
                '.com @ {space}'
              ],
              shift: [
                '~ ! @ # $ % ^ & * ( ) _ + {backspace}',
                'Q W E R T Y U I O P { } |',
                '{capslock} A S D F G H J K L : "',
                'Z X C V B N M < > ?',
                '.com @ {space}'
              ]
            }}
            onChange={(input) => this.onChange(input)}
            onKeyPress={(button) => this.onKeyPress(button)}
          />
        </div>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  injectIntl(AdminLoginFormControlsBadgeLogin)
);
