import CssBaseline from '@material-ui/core/CssBaseline';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import React from 'react';
import { addLocaleData } from 'react-intl';
import AppContainer from './AppContainer';
import en from 'react-intl/locale-data/en';
import es from 'react-intl/locale-data/es';
import fr from 'react-intl/locale-data/fr';

addLocaleData([...en, ...es, ...fr]);

const theme = createTheme({
  typography: {
    useNextVariants: true
  },
  footerBackgroundColor: '#394242',
  footerTextColor: '#aaa',
  footerFontSize: '0.90rem'
});

class AppWrapper extends React.Component {
  render() {
    return (
      <React.Fragment>
        <CssBaseline />
        <MuiThemeProvider theme={theme}>
          <AppContainer store={this.props.store} history={this.props.history} />
        </MuiThemeProvider>
      </React.Fragment>
    );
  }
}

export default AppWrapper;
