import { withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import classNames from 'classnames';
import styles from './SecureBadgeLoginForm.styles';
import { injectIntl, defineMessages, FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router';
import Button from '@material-ui/core/Button';
import { KioskFooter, KioskHeader, KioskIdelScreen } from '..';
import scan from './images/QRCodeScan.gif';
import { Auth } from 'aws-amplify';
import TextField from '@material-ui/core/TextField';
import uuidGenerator from '../Common/uuidGenerator';
import spinner from './images/spinner.gif';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { readSettings } from '../../helpers/board';
import Cryptr from 'cryptr';
import Keyboard from 'react-simple-keyboard';
const cryptoJS = require('crypto-js');

const cryptr = new Cryptr('iLocalBox');
const _cipher = process.env.REACT_APP_SESSION_CIPHER;
const _cryptr = new Cryptr(_cipher);

const messages = defineMessages({
  Back: {
    id: 'Welcome.Back',
    defaultMessage: 'Back'
  },
  ManualPickup: {
    id: 'Welcome.ManualPickup',
    defaultMessage: 'Username/Password'
  },
  WaitingForScan: {
    id: 'PickingUp.WaitingForScan',
    defaultMessage: 'Waiting to scan your ID.'
  },
  ScanMessage: {
    id: 'Welcome.ScanMessage',
    defaultMessage:
      'If you created a secure login badge, please scan it here to access this location. If you do not have a secure badge, please touch the button below "Username / Password" to login with your credentials.'
  },
  UserNotFoundException: {
    id: 'Login.UserNotFoundException',
    defaultMessage: 'User does not exist.'
  },
  NotAuthorizedException: {
    id: 'Login.NotAuthorizedException',
    defaultMessage: 'Invalid QR code.'
  },
  InvalidQRCode: {
    id: 'PickingUp.InvalidQRCode',
    defaultMessage: 'Invalid QR Code.'
  },
  Email: {
    id: 'Admin.Login.Email',
    defaultMessage: 'Email'
  },
  Password: {
    id: 'Admin.Login.Password',
    defaultMessage: 'Password'
  },
  OTP: {
    id: 'Admin.Login.OTP',
    defaultMessage: 'OTP'
  },
  SignIn: {
    id: 'Admin.Login.SignIn',
    defaultMessage: 'Sign In'
  },
  SignInWithOTP: {
    id: 'Admin.Login.SignIn',
    defaultMessage: 'With OTP'
  },
  SignInWithSecureBadge: {
    id: 'Admin.Login.SignIn',
    defaultMessage: 'With Secure Badge'
  },
  Continue: {
    id: 'Admin.Login.Continue',
    defaultMessage: 'Continue'
  },
  resendOTP: {
    id: 'Admin.Login.resendOTP',
    defaultMessage: 'Resend OTP'
  },
  EnterEmail: {
    id: 'Admin.Login.EnterEmail',
    defaultMessage: 'Enter your email address'
  },
  EnterValidEmail: {
    id: 'Admin.Login.EnterValidEmail',
    defaultMessage: 'Enter a valid email address'
  },
  EmailRequired: {
    id: 'Admin.Login.EmailRequired',
    defaultMessage: 'Email address is required'
  },
  OktaLogin: {
    id: 'Welcome.OktaLogin',
    defaultMessage: 'Sign in with Okta'
  },
  EnterOTP: {
    id: 'Admin.Login.EnterOTP',
    defaultMessage: 'Enter your One Time Password'
  },
  OTPChar: {
    id: 'Admin.Login.OTPChar',
    defaultMessage: 'OTP must contain 6 digits'
  },
  OTPRequired: {
    id: 'Admin.Login.OTPRequired',
    defaultMessage: 'OTP is required'
  },
  Clear: {
    id: 'Welcome.Clear',
    defaultMessage: 'Clear'
  }
});

export class SecureBadgeLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      invalid:
        this.props && this.props.intl.formatMessage(messages.InvalidQRCode),
      msgForScan:
        this.props && this.props.intl.formatMessage(messages.WaitingForScan),
      ScanMes:
        this.props && this.props.intl.formatMessage(messages.ScanMessage),
      secureBadgeEncryptedCode: '',
      showProgress: false,
      isScanningProcess: false,
      typingTimeout: 0,
      maxlength: 100,
      layoutName: 'default',
      username: '',
      password: '',
      otpSent: false,
      otpError: '',
      otpSuccess: '',
      pickupCodeLength: 6,
      pickupCodeMaxLength: 1,
      errorMessage: 'Hmmm, this code was not found. Please try again.',
      isSubmitDisabled: false,
      isEnabled: true,
      timeoutSet: false
    };
    this.OtpRef = null;
  }

  afterSignInHandler = async (user) => {
    let session = {
      _id: uuidGenerator(),
      _name: 'Kiosk Admin Login',
      _session_active: true,
      _start_date: new Date(),
      _end_date: null
    };
    if ([true, 'true'].includes(localStorage.getItem('isKioskOnline'))) {
      const encryptedSessionObject = _cryptr.encrypt(JSON.stringify(session));
      localStorage.setItem('ActiveSession', encryptedSessionObject);
      sessionStorage.setItem(
        'jwtToken',
        JSON.stringify(user.signInUserSession.idToken.jwtToken)
      );
      //Get Permissions for Login user
      sessionStorage.setItem('user', JSON.stringify(user));
      localStorage.setItem('startDate', session._start_date);
      fetch(process.env.REACT_APP_BASEURL + 'sessionHistory', {
        method: 'POST',
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
          Authorization:
            'Bearer ' +
            JSON.parse(sessionStorage.getItem('user')).signInUserSession.idToken
              .jwtToken
        },
        body: JSON.stringify(session)
      })
        .then((data) => {
          console.log(`Data from session history ${JSON.stringify(data)}`);
        })
        .catch((error) => {
          console.log(
            `Error while updating sessionHistory ${JSON.stringify(error)}`
          );
        });
    } else {
      const encryptedSessionObject = _cryptr.encrypt(JSON.stringify(session));
      localStorage.setItem('ActiveSession', encryptedSessionObject);
    }
    let token = '';
    const sessionToken = ![undefined, 'undefined', null, ''].includes(
      sessionStorage.getItem('user')
    )
      ? JSON.parse(sessionStorage.getItem('user'))
      : null;
    if (![undefined, 'undefined', null, 'null', ''].includes(session)) {
      if (Object.keys(sessionToken.signInUserSession.idToken).length > 0) {
        token = sessionToken.signInUserSession.idToken.jwtToken;
      } else {
        token = atob(localStorage.getItem('authTokens'));
      }
    } else {
      token = atob(localStorage.getItem('authTokens'));
    }

    fetch(process.env.REACT_APP_BASEURL + 'getRolesPermissions', {
      method: 'GET',
      withCredentials: true,
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json'
      }
    })
      .then((response) => response.json())
      .then((Permissons) => {
        if (Permissons.permission) {
          const encryptedString = cryptr.encrypt(
            JSON.stringify(Permissons.permission)
          );
          localStorage.setItem('permissions', encryptedString);
          this.setState({
            showProgress: false,
            isScanningProcess: false
          });
        }
        if (Permissons.superAdmin) {
          localStorage.setItem('permissionUser', Permissons.superAdmin);
        }
        this.props.history.push('/AdminPanel');
        this.props.history.push('/AdminPanel');
      })
      .catch((error) => {
        console.log(`Error from roles and permission ${JSON.stringify(error)}`);
      });
  };

  handleSecureBadgeScan = (formData = {}) => {
    if (
      this.props &&
      this.props.box &&
      this.props.box.account &&
      this.props.box.account.is_otp_login &&
      formData &&
      formData.username &&
      formData.password
    ) {
      let box_id = '';
      if (window.electron) {
        const fileData = JSON.parse(readSettings());
        box_id = fileData ? fileData.boxId : false;
      } else {
        box_id = localStorage.getItem('boxId');
      }

      let objSignInUser = {
        username: formData.username,
        box_id: box_id
      };

      fetch(process.env.REACT_APP_BASEURL + 'authUser', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(objSignInUser)
      })
        .then((response) => {
          try {
            return response.text();
          } catch (error) {
            return null;
          }
        })
        .then((objUser) => {
          let objUserDetais = JSON.parse(objUser);
          if (objUserDetais && objUserDetais.isValidUser === true) {
            this.setState({ otpError: '', otpSuccess: '', showProgress: true });
            fetch(process.env.REACT_APP_BASEURL + 'saml/otp/verify/login', {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({
                email: formData.username,
                otp: formData.password
              })
            })
              .then((response) => {
                try {
                  return response.json();
                } catch (error) {
                  return null;
                }
              })
              .then(async (res) => {
                if (res.success === false) {
                  this.setState({
                    otpError: res.message,
                    otpSuccess: '',
                    showProgress: false
                  });
                  return;
                }
                await this.afterSignInHandler({
                  signInUserSession: {
                    idToken: { jwtToken: res.jwtToken, payload: res.claims }
                  }
                });
              })
              .catch((error) => {
                this.setState({
                  otpError: error.message,
                  otpSuccess: '',
                  showProgress: false
                });
              });
          }
        })
        .catch((error) => {
          console.log(
            `Error from auth user API ${JSON.stringify(error, null, 2)}`
          );
        });
    } else if (this.state.secureBadgeEncryptedCode) {
      const { secureBadgeEncryptedCode } = this.state;
      try {
        const secureBadgeData = cryptoJS.AES.decrypt(
          secureBadgeEncryptedCode.trim(),
          process.env.REACT_APP_CIPHER
        )
          .toString(cryptoJS.enc.Utf8)
          .split('|');
        if (secureBadgeData && secureBadgeData.length > 1) {
          if (secureBadgeData[2] <= Date.now()) {
            this.setState({
              isScanningProcess: true,
              invalid: `Your Password has been expired! Please update your password and get a new Secure Badge !!!`,
              showProgress: false
            });
            return false;
          } else {
            Auth.signIn(secureBadgeData[0].trim(), secureBadgeData[1].trim())
              .then(async (user) => {
                this.setState({
                  isScanningProcess: false,
                  msgForScan: 'Welcome'
                });
                await this.afterSignInHandler(user);
              })
              .catch((err) => {
                switch (err.code) {
                  case 'UserNotFoundException':
                    this.setState({
                      invalid: err.message || 'User Not Found!',
                      showProgress: false,
                      isScanningProcess: true,
                      secureBadgeEncryptedCode: ''
                    });
                    break;
                  case 'NotAuthorizedException':
                    this.setState({
                      invalid: err.message || 'Incorrect username or password!',
                      showProgress: false,
                      isScanningProcess: true,
                      secureBadgeEncryptedCode: ''
                    });
                    break;
                  default:
                    this.setState({
                      invalid:
                        this.props &&
                        this.props.intl.formatMessage(messages.InvalidQRCode),
                      showProgress: false,
                      isScanningProcess: true,
                      secureBadgeEncryptedCode: ''
                    });
                }
              });
          }
        } else {
          this.setState({
            isScanningProcess: true,
            showProgress: false,
            secureBadgeEncryptedCode: '',
            invalid:
              this.props &&
              this.props.intl.formatMessage(messages.InvalidQRCode)
          });
        }
      } catch (error) {
        this.setState({
          isScanningProcess: true,
          showProgress: false,
          secureBadgeEncryptedCode: '',
          invalid:
            this.props && this.props.intl.formatMessage(messages.InvalidQRCode)
        });
      }
    }
  };

  handleSendOTP = (email) => {
    const otpSuccess = this.state.otpSent ? 'New OTP has been sent!' : '';
    this.setState({ otpError: '', otpSuccess: '', showProgress: true });
    fetch(process.env.REACT_APP_BASEURL + 'saml/otp/login', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email })
    })
      .then((response) => {
        try {
          return response.json();
        } catch (error) {
          return null;
        }
      })
      .then((res) => {
        if (res.success) {
          this.setState({ otpSent: true, otpSuccess, showProgress: false });
        } else {
          this.setState({
            otpSent: false,
            otpError: res.message,
            otpSuccess: '',
            showProgress: false
          });
        }
      })
      .catch((error) => {
        this.setState({
          otpSent: false,
          otpError: error.message,
          otpSuccess: '',
          showProgress: false
        });
      });
  };

  change = (name, e, props) => {
    let input = e.target.value;
    this.setState({ [name]: e.target.value }, () => {
      this.keyboard.setInput(input);
    });
    props.handleChange && props.handleChange(e);
    props.setFieldTouched && props.setFieldTouched(name, true, false);
  };

  onChangeInputEventEmitterHandler = (SelectedInput, input) => {
    let event = '';
    document.querySelector('#' + SelectedInput).value = input.slice(-1);
    event = new Event('change', { bubbles: true });
    event.simulated = true;
    document.querySelector('#' + SelectedInput).dispatchEvent(event);
    this.setState({}, () => {
      this.keyboard.setInput(input);
    });
    return event;
  };

  onChange = (input) => {
    if (document.querySelector('#username') && !this.state.otpSent) {
      const el = document.querySelector('#username');
      el.value = input;
      const len = input.length;
      el.setSelectionRange(len, len);
      el.focus();
      this.setState({ username: input }, () => {
        this.keyboard.setInput(input);
      });
      this.formikRef.setFieldValue &&
        this.formikRef.setFieldValue('username', input);
      this.formikRef.setFieldTouched &&
        this.formikRef.setFieldTouched('username', true, false);
      // this.change('username', input);
    } else if (document.querySelector('#pickupcode0').value.length === 0) {
      let event = this.onChangeInputEventEmitterHandler('pickupcode0', input);
      this.onChangeInputHandlerZero('pickupcode0', event);
    } else if (document.querySelector('#pickupcode1').value.length === 0) {
      let event = this.onChangeInputEventEmitterHandler('pickupcode1', input);
      this.onChangeInputHandlerOne('pickupcode1', event);
    } else if (document.querySelector('#pickupcode2').value.length === 0) {
      let event = this.onChangeInputEventEmitterHandler('pickupcode2', input);
      this.onChangeInputHandlerTwo('pickupcode2', event);
    } else if (document.querySelector('#pickupcode3').value.length === 0) {
      let event = this.onChangeInputEventEmitterHandler('pickupcode3', input);
      this.onChangeInputHandlerThree('pickupcode3', event);
    } else if (document.querySelector('#pickupcode4').value.length === 0) {
      let event = this.onChangeInputEventEmitterHandler('pickupcode4', input);
      this.onChangeInputHandlerFour('pickupcode4', event);
    } else if (document.querySelector('#pickupcode5').value.length === 0) {
      let event = this.onChangeInputEventEmitterHandler('pickupcode5', input);
      this.onChangeInputHandlerFive('pickupcode5', event);
    }
  };

  handleShiftButton = () => {
    const {
      state: { layoutName }
    } = this;
    const shiftToggle = layoutName === 'default' ? 'shift' : 'default';
    this.setState({ layoutName: shiftToggle });
  };

  onKeyPress = (button) => {
    if (['{backspace}'].includes(button)) {
      const o1 = document.querySelector('#pickupcode0'),
        o2 = document.querySelector('#pickupcode1'),
        o3 = document.querySelector('#pickupcode2'),
        o4 = document.querySelector('#pickupcode3'),
        o5 = document.querySelector('#pickupcode4'),
        o6 = document.querySelector('#pickupcode5'),
        username = document.querySelector('#username');
      if (o6 && o6.value !== '') {
        o6.value = '';
      } else if (o5 && o5.value !== '') {
        o5.value = '';
      } else if (o4 && o4.value !== '') {
        o4.value = '';
      } else if (o3 && o3.value !== '') {
        o3.value = '';
      } else if (o2 && o2.value !== '') {
        o2.value = '';
      } else if (o1 && o1.value !== '') {
        o1.value = '';
        setTimeout(() => {
          this.onFocusEventHandler('pickupcode0');
        }, 100);
      } else if (username && username.value !== '') {
        username.value = username.value.slice(0, -1);
        const len = username.value;
        username.setSelectionRange(len, len);
        username.focus();
        return;
      } else {
        setTimeout(() => {
          this.state.otpSent && this.onFocusEventHandler('pickupcode0');
        }, 100);
      }
      this.state.otpSent && this.onChangePickupCodeHandler();
    } else if (['{capslock}', '{shiftleft}', '{shiftright}'].includes(button)) {
      this.handleShiftButton();
    }
  };

  onChangeInputHandlerZero = (name, event, props) => {
    let input = event.target.value;
    let element = document.querySelector('#pickupcode0');
    this.keyboard.setInput(input);
    if (input.length === element.maxLength) {
      this.onFocusEventHandler('pickupcode1');
    } else {
      this.onFocusEventHandler('pickupcode0');
    }
    this.onChangePickupCodeHandler(props);
  };

  onChangeInputHandlerOne = (name, event, props) => {
    let input = event.target.value;
    let element = document.querySelector('#pickupcode1');
    this.keyboard.setInput(input);
    if (input.length === element.maxLength) {
      this.onFocusEventHandler('pickupcode2');
    } else {
      this.onFocusEventHandler('pickupcode0');
    }
    this.onChangePickupCodeHandler(props);
  };

  onChangeInputHandlerTwo = (name, event, props) => {
    let input = event.target.value;
    let element = document.querySelector('#pickupcode2');
    this.keyboard.setInput(input);
    if (input.length === element.maxLength) {
      this.onFocusEventHandler('pickupcode3');
    } else {
      this.onFocusEventHandler('pickupcode1');
    }
    this.onChangePickupCodeHandler(props);
  };

  onChangeInputHandlerThree = (name, event, props) => {
    let input = event.target.value;
    let element = document.querySelector('#pickupcode3');
    this.keyboard.setInput(input);
    if (input.length === element.maxLength) {
      this.onFocusEventHandler('pickupcode4');
    } else {
      this.onFocusEventHandler('pickupcode2');
    }
    this.onChangePickupCodeHandler(props);
  };

  onChangeInputHandlerFour = (name, event, props) => {
    let input = event.target.value;
    let element = document.querySelector('#pickupcode4');
    this.keyboard.setInput(input);
    if (input.length === element.maxLength) {
      this.onFocusEventHandler('pickupcode5');
    } else {
      this.onFocusEventHandler('pickupcode3');
    }
    this.onChangePickupCodeHandler(props);
  };

  onChangeInputHandlerFive = (name, event, props) => {
    let input = event.target.value;
    let element = document.querySelector('#pickupcode5');
    this.keyboard.setInput(input);
    if (input.length === element.maxLength) {
    } else {
      this.onFocusEventHandler('pickupcode4');
    }
    this.onChangePickupCodeHandler(props);
  };

  onFocusEventHandler = (event) => {
    document.querySelector('#' + event).focus();
  };

  onChangePickupCodeHandler = (props) => {
    let input = '';
    for (let i = 0; i <= 5; i++) {
      input += document.querySelector('#pickupcode' + i).value;
    }
    if (input.length === this.state.pickupCodeLength) {
      this.setState({ isSubmitDisabled: true, password: input });
    } else {
      this.setState({ isSubmitDisabled: false, password: '' });
    }
    document.querySelector('#pickupcode').value = input;
    let event = new Event('onChange', { bubbles: true });
    event.simulated = true;
    document.querySelector('#pickupcode').dispatchEvent(event);
  };

  onResetPickupCodeInputs = () => {
    for (let i = 0; i <= 5; i++) {
      document.querySelector('#pickupcode' + i).value = '';
    }
    this.setState({ isSubmitDisabled: false, password: '' }, () => {
      this.keyboard.setInput();
      this.onFocusEventHandler('pickupcode0');
    });
  };

  render() {
    const {
      classes,
      intl,
      box: { account: { is_otp_login = false } } = {
        account: { is_otp_login: false }
      },
      history
    } = this.props;
    const {
      showProgress,
      ScanMes,
      msgForScan,
      invalid,
      isScanningProcess,
      secureBadgeEncryptedCode,
      otpSent,
      otpError,
      otpSuccess,
      layoutName,
      maxlength,
      pickupCodeMaxLength,
      selectedLanguage,
      username,
      password
    } = this.state;
    var customFooterBottom;
    if (window.screen.width === 1080) {
      customFooterBottom = '0%';
    } else if (window.screen.width === 1440 || window.screen.width === 1920) {
      customFooterBottom = '0%';
    } else {
      customFooterBottom = '0%';
    }

    const validationSchema = Yup.object({
      username: Yup.string(intl.formatMessage(messages.EnterEmail))
        .email(intl.formatMessage(messages.EnterValidEmail))
        .required(intl.formatMessage(messages.EmailRequired))
    });

    return (
      <div className={classNames(classes.mainContainer)}>
        <div className={classNames(classes.appLogoContainer)}>
          <img
            className={classNames(classes.appLogo)}
            alt="app-logo"
            src="/images/brands/Steps/Welcome-Line.png"
          />
        </div>
        <div
          style={{ display: 'flex', flexDirection: 'column', height: '200px' }}
        >
          <span style={{ color: ' #2F5597', fontSize: '3em' }}>
            <FormattedMessage id="Login.SignIn" defaultMessage="Sign In" />
          </span>
          <span style={{ color: ' #2F5597', fontSize: '1.5em' }}>
            <FormattedMessage
              id={
                is_otp_login
                  ? 'Login.SignInWithOTP'
                  : 'Login.SignInWithSecureBadge'
              }
              defaultMessage={`With ${is_otp_login ? 'OTP' : 'Secure Badge'}`}
            />
          </span>
        </div>
        {showProgress && (
          <div className={classes.spinner}>
            <img src={spinner} alt="spinner" className={classes.spinnerImage} />
          </div>
        )}
        {otpError && (
          <p style={{ margin: '-15px 0 0 0', color: 'red' }}>{otpError}</p>
        )}
        {otpSuccess && (
          <p style={{ margin: '-15px 0 0 0', color: 'limegreen' }}>
            {otpSuccess}
          </p>
        )}
        <div>
          {is_otp_login ? (
            <Formik
              ref={(r) => (this.formikRef = r)}
              render={({
                handleSubmit,
                touched,
                errors,
                isValid,
                handleChange,
                setFieldTouched
              }) => (
                <div className={classNames(classes.otpContainer)}>
                  <form onSubmit={handleSubmit} className={'formContainer'}>
                    <TextField
                      id="username"
                      name="username"
                      InputProps={{
                        classes: {
                          input: classes.textField
                        }
                      }}
                      label={intl.formatMessage(messages.Email)}
                      autoComplete="off"
                      autoCapitalize="false"
                      autoFocus
                      fullWidth
                      helperText={touched.username ? errors.username : ''}
                      error={touched.username && Boolean(errors.username)}
                      onChange={(e) =>
                        this.change('username', e, {
                          handleChange,
                          setFieldTouched
                        })
                      }
                      value={username}
                    />
                    {touched.username &&
                    !Boolean(errors.username) &&
                    otpSent ? (
                      <>
                        <div className="container">
                          <input
                            id="pickupcode0"
                            name="pickupcode"
                            ref="pickupcode0"
                            autoComplete="off"
                            autoFocus
                            maxLength={pickupCodeMaxLength}
                            onChange={(e) =>
                              this.onChangeInputHandlerZero('pickupcode0', e, {
                                handleChange,
                                setFieldTouched
                              })
                            }
                            className={classNames(classes.pickupCodeInput)}
                          />
                          <input
                            id="pickupcode1"
                            name="pickupcode"
                            ref="pickupcode1"
                            autoComplete="off"
                            maxLength={pickupCodeMaxLength}
                            onChange={(e) =>
                              this.onChangeInputHandlerOne('pickupcode1', e, {
                                handleChange,
                                setFieldTouched
                              })
                            }
                            className={classNames(classes.pickupCodeInput)}
                          />
                          <input
                            id="pickupcode2"
                            name="pickupcode"
                            ref="pickupcode2"
                            autoComplete="off"
                            maxLength={pickupCodeMaxLength}
                            onChange={(e) =>
                              this.onChangeInputHandlerTwo('pickupcode2', e, {
                                handleChange,
                                setFieldTouched
                              })
                            }
                            className={classNames(classes.pickupCodeInput)}
                          />
                          <input
                            id="pickupcode3"
                            name="pickupcode"
                            ref="pickupcode3"
                            autoComplete="off"
                            maxLength={pickupCodeMaxLength}
                            onChange={(e) =>
                              this.onChangeInputHandlerThree('pickupcode3', e, {
                                handleChange,
                                setFieldTouched
                              })
                            }
                            className={classNames(classes.pickupCodeInput)}
                          />
                          <input
                            id="pickupcode4"
                            name="pickupcode"
                            ref="pickupcode4"
                            autoComplete="off"
                            maxLength={pickupCodeMaxLength}
                            onChange={(e) =>
                              this.onChangeInputHandlerFour('pickupcode4', e, {
                                handleChange,
                                setFieldTouched
                              })
                            }
                            className={classNames(classes.pickupCodeInput)}
                          />
                          <input
                            id="pickupcode5"
                            name="pickupcode"
                            ref="pickupcode5"
                            autoComplete="off"
                            maxLength={pickupCodeMaxLength}
                            onChange={(e) =>
                              this.onChangeInputHandlerFive('pickupcode5', e, {
                                handleChange,
                                setFieldTouched
                              })
                            }
                            className={classNames(classes.pickupCodeInput)}
                          />
                          <div
                            className={classNames(classes.otpButtonContainer)}
                          >
                            <span
                              className={classNames(classes.clearButton)}
                              onClick={this.onResetPickupCodeInputs}
                            >
                              {intl.formatMessage(messages.Clear)}
                            </span>
                            <span
                              className={classNames(classes.resentOtpButton)}
                              onClick={() => {
                                this.handleSendOTP(username);
                                this.onResetPickupCodeInputs();
                              }}
                            >
                              {intl.formatMessage(messages.resendOTP)}
                            </span>
                            <input id="pickupcode" hidden />
                          </div>
                          <div
                            className={classNames(
                              selectedLanguage === 'en'
                                ? classes.errorMessageContainer
                                : classes.errorMessageContainerSpanish
                            )}
                          >
                            <div>
                              {touched.pickupcode ? errors.pickupcode : ''}
                            </div>
                            <div>
                              {touched.pickupcode && Boolean(errors.pickupcode)}
                            </div>
                          </div>
                        </div>
                        <div style={{ textAlign: 'center' }}>
                          <Button
                            type="submit"
                            color="primary"
                            variant="contained"
                            className={
                              !isValid
                                ? classNames(classes.disabledPrimaryButton)
                                : classNames(classes.primaryButton)
                            }
                            disabled={!password || !isValid}
                          >
                            {intl.formatMessage(messages.SignIn)}
                          </Button>
                        </div>
                      </>
                    ) : (
                      <div style={{ textAlign: 'center' }}>
                        <Button
                          color="primary"
                          variant="contained"
                          style={{
                            width: '30%',
                            margin: '10px',
                            marginTop: '2em'
                          }}
                          onClick={() => {
                            this.handleSendOTP(username);
                          }}
                          disabled={
                            !touched.username && !Boolean(errors.username)
                          }
                        >
                          {intl.formatMessage(messages.Continue)}
                        </Button>
                      </div>
                    )}
                  </form>
                </div>
              )}
              validationSchema={validationSchema}
              initialValues={{ username: '' }}
              onSubmit={(formData, formActions) => {
                this.handleSecureBadgeScan({
                  ...formData,
                  password: password
                });
              }}
            />
          ) : (
            <>
              <div>
                <img
                  src={scan}
                  alt="scan"
                  style={{ width: '200px', marginTop: '-4.5em' }}
                />
                <TextField
                  fullWidth
                  autoFocus
                  value={secureBadgeEncryptedCode}
                  onKeyDown={() => {
                    if (!this.state.timeoutSet) {
                      setTimeout(() => {
                        this.handleSecureBadgeScan();
                        this.setState({ timeoutSet: false });
                      }, 2000);
                      this.setState({ timeoutSet: true });
                    }
                  }}
                  onChange={({ target }) =>
                    this.setState({
                      secureBadgeEncryptedCode: target.value,
                      isScanningProcess: false,
                      showProgress: true
                    })
                  }
                  onBlur={({ target }) => target.focus()}
                  style={{
                    width: '0',
                    height: '0',
                    position: 'absolute',
                    zIndex: 1
                  }}
                  InputProps={{
                    disableUnderline: true,
                    style: { color: '#fff' },
                    spellCheck: 'false',
                    autoComplete: 'off'
                  }}
                />
              </div>
              <div className={classNames(classes.ScantitleMessages)}>
                <label
                  id="scanTitle"
                  className={
                    isScanningProcess
                      ? classNames(classes.scanTitleError)
                      : classNames(classes.Scantitle)
                  }
                >
                  {/* Waiting for scan QR ... */}
                  {isScanningProcess ? invalid : msgForScan}
                </label>
              </div>

              <div>
                <p
                  style={{
                    textAlign: 'justify',
                    marginLeft: '112px',
                    marginRight: '112px',
                    marginTop: '40px',
                    color: 'rgb(47, 85, 151)'
                  }}
                >
                  {ScanMes}
                </p>
              </div>
              <div className={classes.oktaLoginContainer}>
                <Button
                  type="button"
                  color="primary"
                  variant="contained"
                  className={classNames(classes.oktaLoginButton)}
                  style={{ width: '300px' }}
                  onClick={() => history.push('/implicit')}
                >
                  <span className={classes.oktaCircle}></span>
                  {intl.formatMessage(messages.OktaLogin)}
                </Button>
              </div>
            </>
          )}
          <div className={classNames(classes.buttonContainer)}>
            {is_otp_login && (
              <div
                className={classNames(classes.simpleKeyboard)}
                style={{
                  width: otpSent ? '300px' : '50vh',
                  top: otpSent ? '-25rem' : '-30rem'
                }}
              >
                <Keyboard
                  keyboardRef={(r) => (this.keyboard = r)}
                  layoutName={layoutName}
                  maxLength={{ default: maxlength }}
                  onChange={(input) => this.onChange(input)}
                  onKeyPress={this.onKeyPress}
                  layout={
                    otpSent
                      ? {
                          default: ['1 2 3', '4 5 6', '7 8 9', '0 {backspace}']
                        }
                      : {
                          default: [
                            '` 1 2 3 4 5 6 7 8 9 0 - = {backspace}',
                            'q w e r t y u i o p [ ] \\',
                            "{capslock} a s d f g h j k l ; '",
                            'z x c v b n m , . /',
                            '.com @ {space}'
                          ],
                          shift: [
                            '~ ! @ # $ % ^ & * ( ) _ + {backspace}',
                            'Q W E R T Y U I O P { } |',
                            '{capslock} A S D F G H J K L : "',
                            'Z X C V B N M < > ?',
                            '.com @ {space}'
                          ]
                        }
                  }
                />
              </div>
            )}
            <Button
              type="button"
              color="primary"
              className={classNames(classes.backButton)}
              onClick={() => history.push('/')}
            >
              {intl.formatMessage(messages.Back)}
            </Button>
            {!is_otp_login && (
              <Button
                type="button"
                color="primary"
                variant="contained"
                className={classNames(classes.backButton)}
                onClick={() => history.push('/AdminLogin')}
              >
                {intl.formatMessage(messages.ManualPickup)}
              </Button>
            )}
          </div>
        </div>
        <KioskHeader />
        <KioskFooter
          customFooterBottom={customFooterBottom}
          isLanguageBarAllowd={true}
        />
        {showProgress || <KioskIdelScreen />}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const kiosk = state.get('kiosk');
  return {
    ...kiosk
  };
};

const SecureBadgeLoginContainer = connect(
  mapStateToProps,
  null
)(SecureBadgeLogin);

export default withStyles(styles, { withTheme: true })(
  injectIntl(withRouter(SecureBadgeLoginContainer))
);
