import React, { memo, useState } from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import co from './images/co.png';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid
} from '@material-ui/core';

const messages = defineMessages({
  Okay: {
    id: 'Common.Okay',
    defineMessages: 'Okay'
  },
  VisitStaff: {
    id: 'RetailUI.Header.VisitStaff',
    defineMessages: 'Please visit this optical staff'
  }
});

const Header = memo(({ intl, box, height = '65px' }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const brand = JSON.parse(localStorage.getItem('brands'));
  const logo =
    (brand && brand.length > 0 && brand[0].brand_logo_url) ||
    (box && box.account && box.account.brand && box.account.brand.logo_url);

  return (
    <>
      <Grid
        container
        style={{
          height: '10%',
          textAlign: 'center',
          alignItems: 'center'
        }}
      >
        <Grid
          item
          xs={2}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <HelpOutlineIcon
            style={{ width: '2em', height: '2em', color: '#aaa' }}
            onClick={(e) => setOpenDialog(true)}
          />
        </Grid>
        <Grid
          item
          xs={8}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          {logo ? (
            <img alt="logo" height={height} src={logo} />
          ) : (
            <img alt="logo" height={height} src={co} />
          )}
        </Grid>
        <Grid
          item
          xs={2}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          &nbsp;
        </Grid>
      </Grid>
      <Dialog
        fullWidth={true}
        open={openDialog}
        keepMounted
        disableEscapeKeyDown
        onClose={() => setOpenDialog(false)}
        aria-labelledby="alert-dialog-consult-tutorial-title"
        aria-describedby="alert-dialog-consult-tutorial-description"
        PaperProps={{
          style: { border: '2px solid #0C7CBA', borderRadius: '15px' }
        }}
      >
        <DialogContent
          style={{
            textAlign: 'center',
            padding: '0 24px 0'
          }}
        >
          <p
            style={{
              fontSize: '22px',
              fontWeight: 400
            }}
          >
            {intl.formatMessage(messages.VisitStaff)}
          </p>
        </DialogContent>
        <DialogActions
          style={{
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Button
            onClick={() => setOpenDialog(false)}
            style={{
              width: '30%',
              padding: '10px 10px',
              background: ' #2F5597',
              color: '#fff',
              border: '1px solid  #2F5597',
              borderRadius: '10px',
              '&:hover': {
                backgroundColor: ' #2F5597',
                color: '#fff'
              },
              fontSize: '20px'
            }}
          >
            {intl.formatMessage(messages.Okay)}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
});

const mapStateToProps = (state) => {
  const kiosk = state.get('kiosk');
  return { ...kiosk };
};

export default connect(mapStateToProps)(injectIntl(Header));
